import { useFormik } from 'formik';
import { useState, useEffect, createContext } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { getRecord, saveRecord, deleteRecord } from '../crud-helper';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import constants from '../../utils/constants';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FormLayout from './field-mapper';
import { useSnackbar } from '@durlabh/dframework-ui';
import DialogComponent from '../Dialog'
import { useSelector, useDispatch } from 'react-redux';
import PageTitle from '../PageTitle';
import actions from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import utils from '../../utils';
import { useLocation } from 'react-router';
import enums from '../../utils/constants'
const { wrongAction } = enums;

export const ActiveStepContext = createContext(1);

const defaultFieldConfigs = {};

const Form = ({
    model,
    permissions = model.modelPermissions || constants.defaultPermissions,
    Layout = FormLayout,
}) => {
    const { id: idWithOptions } = useParams();
    const id = idWithOptions.split('-')[0];
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [lookups, setLookups] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const history = useHistory();
    const snackbar = useSnackbar()
    const combos = useSelector(state => state.appReducer.comboData) || {};
    const dispatch = useDispatch();
    const [validationSchema, setValidationSchema] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const fieldConfigs = model?.applyFieldConfig ? model?.applyFieldConfig({ data, lookups }) : defaultFieldConfigs;
    const location = useLocation();
    const mode = location?.state?.mode || '';
    const urlId = mode === 'copy' ? idWithOptions.split('-')[1] : id;
    const isValidUrl = utils.isValidIdUrl(urlId);

    useEffect(() => {
        const options = idWithOptions.split('-');
        if (isValidUrl) {
            setValidationSchema(model.getValidationSchema({ id, t, tOpts }));
            try {
                getRecord({
                    id: options.length > 1 ? options[1] : options[0],
                    modelConfig: model,
                    setIsLoading,
                    setError: errorOnLoad,
                    setActiveRecord
                });
            } catch (error) {
                snackbar.showError('An error occurred, please try again later.');
                history.push(model.backURL || './');
            }
        } else {
            setIsLoading(false);
        }
        return () => {
            utils.removeBackButton(dispatch);
        }
    }, [id, idWithOptions, model]);

    useEffect(() => {
        if (model.overrideBackRouteAndSearch) {
            dispatch({
                type: actions.SET_PAGE_BACK_BUTTON,
                pageBackButton: { status: true, backRoute: model.backURL },
            });
            dispatch({
                type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: { hidden: { search: true, operation: false, export: false, print: false, filter: true } }
            });
        }
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...model.initialValues, ...data },
        validationSchema: validationSchema,
        validateOnBlur: false,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            if (model.saveOnlyModifiedValues) {
                const formColumns = model.columns.filter(ele => ele.showOnForm !== false)?.map(item => item.field);
                if (!formColumns.includes('ClientId')) {
                    formColumns.push("ClientId");
                }
                values = formColumns.reduce((acc, key) => {
                    if (key in values) acc[key] = values[key];
                    return acc;
                }, {});
            }
            saveRecord({
                id,
                api: model?.api,
                values,
                setIsLoading,
                setError: snackbar.showError
            })
                .then(success => {
                    if (success) {
                        snackbar.showMessage(t('Record Updated Successfully', tOpts));
                        history.push(model.backURL || './');
                    }
                })
                .catch((err) => {
                    snackbar.showError('An error occured, please try after some time.');
                })
                .finally(() => setIsLoading(false));
        }
    });

    const { dirty } = formik;

    const handleDiscardChanges = () => {
        formik.resetForm();
        setIsDiscardDialogOpen(false);
        history.push(model.backURL || '.');
    };

    const warnUnsavedChanges = () => {
        if (dirty) {
            setIsDiscardDialogOpen(true);
        }
    }

    const errorOnLoad = function (title, error) {
        snackbar.showError(title, error);
        history.push(model.backURL || './');
    }

    const setActiveRecord = function ({ id, title, record, lookups }) {
        const isCopy = idWithOptions.indexOf("-") > -1;
        const isNew = !id || id === "0";
        const localTitle = isNew ? "Create" : (isCopy ? "Copy" : "Edit");
        const localValue = isNew ? "" : record[model.linkColumn];
        const breadcrumbs = [{ text: model?.breadCrumbs }, { text: localTitle }];
        let tempRecord = { ...record };

        if (isCopy) {
            tempRecord[model.linkColumn] += " (Copy)";
        }
        if (model.calculatedColumns) {
            tempRecord = Object.fromEntries(
                Object.entries(tempRecord).filter(([key]) => !model.calculatedColumns.includes(key))
            );
        }

        setData(tempRecord);
        setLookups(lookups);

        if (localValue !== "") {
            breadcrumbs.push({ text: localValue });
        }
        dispatch({
            type: actions.SET_PAGE_TITLE_DETAILS,
            pageTitleDetails: (
                <PageTitle showBreadcrumbs={true} breadcrumbs={breadcrumbs} />
            ),
        });

    }

    const handleFormCancel = function (event) {
        if (dirty) {
            warnUnsavedChanges();
            event.preventDefault();
        } else {
            history.push(model.backURL || '.');
        }
    }

    const handleDelete = async function () {
        setIsDeleting(true);
        try {
            const response = await deleteRecord({
                id,
                api: model?.api,
                setIsLoading,
                setError: snackbar.showError,
                setErrorMessage
            })
            if (response === true) {
                snackbar.showMessage('Record Deleted Successfully.');
                history.push(model.backURL || './');
            }
        } catch (error) {
            setDeleteError('An error occurred, please try after some time.');
        } finally {
            setIsDeleting(false);
        }
    }

    const clearError = () => {
        setErrorMessage(null);
        setIsDeleting(false);
    };

    if (isLoading) {
        return <Box sx={{ display: 'flex', pt: '20%', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    }

    const handleChange = function (e) {
        const { name, value } = e.target;
        const gridData = { ...data };
        gridData[name] = value;
        setData(gridData);
    }

    const handleSubmit = function (e) {
        if (e) e.preventDefault();
        const { errors } = formik;
        if (model.calculatedColumns) {
            formik.values = Object.fromEntries(
                Object.entries(formik.values).filter(([key]) => !model.calculatedColumns.includes(key))
            );
        }
        formik.handleSubmit();
        const fieldName = Object.keys(errors)[0];
        const errorMessage = errors[fieldName];
        if (errorMessage) {
            snackbar.showError(errorMessage, null, "error");
        }
        const fieldConfig = model.columns.find(column => column.field === fieldName);
        if (fieldConfig && fieldConfig.tab) {
            const tabKeys = Object.keys(model.tabs);
            setActiveStep(tabKeys.indexOf(fieldConfig.tab));
        }
    }

    return isValidUrl ? (
        <ActiveStepContext.Provider value={{ activeStep, setActiveStep }}>
            <Paper sx={{ padding: 2 }}>
                <form>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" mb={1}>
                        {permissions.edit && <Button variant="contained" type="submit" color="success" onClick={handleSubmit}>{`${t("Save", tOpts)}`}</Button>}
                        <Button variant="contained" type="cancel" color="error" onClick={handleFormCancel}>{`${t("Cancel", tOpts)}`}</Button>
                        {permissions.delete && <Button variant="contained" color="error" onClick={() => setIsDeleting(true)}>{`${t("Delete", tOpts)}`}</Button>}
                    </Stack>
                    <Layout model={model} formik={formik} data={data} fieldConfigs={fieldConfigs} combos={combos} onChange={handleChange} lookups={lookups} id={id} handleSubmit={handleSubmit} mode={mode} />
                </form>
                {errorMessage && (<DialogComponent open={!!errorMessage} onConfirm={clearError} onCancel={clearError} title={t("Info", tOpts)} hideCancelButton={true} > {errorMessage}</DialogComponent>)}
                <DialogComponent
                    open={isDiscardDialogOpen}
                    onConfirm={handleDiscardChanges}
                    onCancel={() => setIsDiscardDialogOpen(false)}
                    title={t("Changes not saved", tOpts)}
                    okText={t("Discard", tOpts)}
                    cancelText={t("Continue", tOpts)}
                >
                 {t("Would you like to continue to edit or discard changes?", tOpts)}
                </DialogComponent>
                <DialogComponent open={isDeleting} onConfirm={handleDelete} onCancel={() => { setIsDeleting(false); setDeleteError(null); }} title={deleteError ? "Error Deleting Record" : "Confirm Delete"}>{`${t('Are you sure you want to delete', tOpts)} ${data?.GroupName || data?.SurveyName}?`}</DialogComponent>
            </Paper>
        </ActiveStepContext.Provider >
    ) : <div>{t(wrongAction, tOpts)}</div>
}

export default Form;