import React, { memo, useCallback } from 'react';
import { FormControl, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux';
import { LOGIN_CONST } from '../../constants';
import actions from '../../redux/actions';
import { withTranslation } from 'react-i18next';

import utils from '../../utils';
const t = utils.t;
const LoginOTP = memo(function ({ t: translate, i18n }) {
    const tOpts = { t: translate, i18n }
    const dispatch = useDispatch();

    //Set user select client in redux
    const memoizedSetLoginOtp = (e) => {
        const { value } = e?.target;
        if (!/^\d*$/.test(value)) {
            const input = e.target.value.replace(/\D/g, "");
            e.target.value = input;
            dispatch({ type: actions.SET_USER_LOGINOTP, loginOtp: input });
            return; // Only allow numeric input
        }
        dispatch({ type: actions.SET_USER_LOGINOTP, loginOtp: e.target.value, })
    };
    const loginOtp = useSelector(state => state.appReducer.loginOtp);

    return <FormControl className="w-100  my-2 mt-4" key="user-password-control">
        <TextField
            label={t("6 digit code", tOpts)}
            id="standard-basic"
            variant="standard" type='input' onChange={memoizedSetLoginOtp} value={loginOtp}
            inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 6,
            }} />
    </FormControl>
})

export default withTranslation()(LoginOTP);
