import UiModel from './ui-model';

const outletInstallationsModel = new UiModel({
    title: "Outlets",
    defaultSort: 'CreatedOn DESC',
    titleDescription: 'Insights into your outlets, their performance and more',
    idProperty: 'AttachmentId',
    readOnly: true,
    addModifiedOnColumn: false,
    addModifiedByColumn: false,
    addBackButton: true,
    module: 'UserLogin',
    api: 'OutletInstallationAudit',
    showHiddenColumn: true,
    disableRowSelectionOnClick: false,
    createdOnKeepLocal: true,
    addCreatedByColumn: false,
    hideTopFilters: false,
    updatePageTitle: false,
    preferenceId: 'playbookOutletInstallationAudit',
    columns: [
        { field: "Filename", type: 'string', width: 190, label: "Filename" },
        { field: "AttachmentType", type: 'string', width: 150, label: "Type" },
        { field: "CreatedByUser", type: 'string', width: 250, label: "Created By" },
    ]
});

export default outletInstallationsModel;