import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { CellMeasurer } from 'react-virtualized';
import CoolerView2 from './CoolerView2';
import ImageNotFound from '../../assets/images/vista-images/imagenotfound.jpg';
import shareIcon from '../../assets/images/vista-images/share.svg';
import copyIcon from '../../assets/images/vista-images/copy.png'
import ShowPlanogramInStore from '../Common/ShowPlanogramInStore';
import VistaCardMobile from '../VistaLocationCardList/VistaCardMobile';
import VistaImages from './VistaImages';
import { Tooltip, Box, Card, Divider, Grid, Typography } from '@material-ui/core';
import SocialShare from "../SocialShare";
import utils from '../../utils';
import CopyModal from './CopyModal'
import dateUtil from '../../utils/date';
import { automationModel } from '../../constants'
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import useMobile from '../../utils/useMobile';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import actions from '../../redux/actions';
import { apis, request } from '../../httpUtil';
import { useHistory } from 'react-router-dom';
import DialogModal from '../Common/DialogModel';
import dayjs from 'dayjs';
const t = utils.t;

const { COMPASS } = automationModel;

const VistaDetailCard = React.memo(function VistaDetailCard({
    keyCard, index, style, parent, cache, hideChildInfo, manualData = null, isCardContentOnly = false, isOutletCard = false,
    hideHeaderFilter, hidePrevNext, showImages = true, t: translate, i18n, onClose, showImageOnly = false, showTable = false, replenishmentData = [] }) {
    const isMobile = useMobile();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [showReplenish, setShowReplenish] = useState({ modal: false, data: {} });
    const [automationValues, setAutomationValues] = React.useState({ totalScoreAvg: 0, checked: false, fullCount: 0, lowCount: 0, emptyCount: 0, automationData: {} });
    const dispatch = useDispatch();
    const history = useHistory();
    const inStoreData = utils.parseTagStatus(manualData);

    //Vista Data From redux 
    const vistaData = useSelector(state => state.appReducer.vistaData, shallowEqual);
    const data = manualData ? manualData : (vistaData.records && vistaData.records[index]) || {};
    const isPOSImage = data && data.SceneTypeId == 5;
    const tOpts = { t: translate, i18n };
    const [imageData, setImageData] = React.useState(data);
    const [replenishmentDataTable, setReplenishmentDataTable] = React.useState(replenishmentData);
    const loginData = useSelector(state => state.appReducer.userData);
    const { ReplenishDetail } = loginData && loginData.modules;
    const replenishData = useSelector(state => state.appReducer.replenishData);
    const DateFormat = loginData?.tags?.CustomDateTimeFormat;
    const onShare = useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, [])

    useEffect(() => {
        if (automationValues.checked) {
            setAutomationValues({ totalScoreAvg: 0, checked: false, fullCount: 0, lowCount: 0, emptyCount: 0, automationData: {} });
        }
    }, [data]);


    useEffect(() => {
        setReplenishmentDataTable(replenishmentData);
    }, [replenishmentData]);


    const memorizedShare = useMemo(() => <span className="green" style={{ cursor: 'pointer', flex: '.09' }} onClick={onShare}> <img src={shareIcon} alt={""} /></span>, [onShare])

    const closeShareLink = () => setAnchorEl(null);

    //Manage child AssetData, if available 
    const hasChild = data && data.Children && data.Children.length && !hideChildInfo ? true : false;
    let child_Data, child_imageURL = ImageNotFound, child_AssetSerialNumber, child_AssetId;
    let child_purityDateTime = "";
    let { AvgPlanogramCompliance, AvgStockPercentage, PurityDateTime, ProductInfo } = isOutletCard ? imageData : data;
    if (hasChild) {
        child_Data = data && data.Children && data.Children[0];
        if (child_Data) {
            child_imageURL = (child_Data.StitchedImageURL) || ImageNotFound;
            child_AssetSerialNumber = child_Data.AssetSerialNumber;
            child_purityDateTime = child_Data.PurityDateTime;
            child_AssetId = child_Data.AssetId
        }
    }
    let purityDateTimeLabel = PurityDateTime ? dateUtil.dateMatch(PurityDateTime) : "";
    let childPurityDateTimeLabel = child_purityDateTime ? dateUtil.dateMatch(child_purityDateTime) : "";

    if (Object.keys(data).length <= 0) {
        return hideHeaderFilter ?
            <div></div> : t("No Data", tOpts)
    }

    const LatestDateTimeLabel = ({ dateTime, extraLabel = '' }) => {
        return (<span className="ellipsis-text card-footer-flex-asset mr-1">{extraLabel ? t(extraLabel, tOpts) + ' - ' : ''}{isMobile.mobile && !isMobile.tablet ? <small>{dateTime}</small> : dateTime}</span>);
    }

    const handleOpen = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    let totalFacings = ProductInfo && ProductInfo.reduce(function (sum, rec) {
        return sum + rec.FacingInPlanogram;
    }, 0);

    let coolerProduct = ProductInfo && ProductInfo.filter(e => !(e.IsEmpty || e.IsForeign));
    let totalCoolerProduct = coolerProduct && coolerProduct.reduce(function (sum, rec) {
        return sum + rec.FacingInCooler;
    }, 0);

    let coolerProductPerc = totalFacings === 0 ? totalFacings : (totalCoolerProduct / totalFacings) * 100;

    const { RowNumber, LocationName = '', AssetSerialNumber = '', Street = '', Street2 = '', Street3 = '', City = '', State = '', PostalCode = '', AssetId, Code = '' } = data;
    let assetAddress = (Street ? Street : '') +
        (Street2 ? ', ' + Street2 : '') +
        (Street3 ? ', ' + Street3 : '') +
        (City ? ', ' + City : '') +
        (State ? ', ' + State : '') +
        (PostalCode ? ', ' + PostalCode : '');
    let toolTipTitle = `${LocationName}, ${assetAddress}`;

    const columns = [
        { field: 'productName', headerName: t('Product Name', tOpts), width: 400 },
        { field: 'sku', headerName: t('SKU', tOpts), width: 120 },
        { field: 'planogramFacings', headerName: t('Planogram Facings', tOpts), width: 150, type: 'number' },
        { field: 'recognizedFacings', headerName: t('Recognized Facings', tOpts), width: 150, type: 'number' },
        { field: 'stockLevel', headerName: t('Stock Level (In %)', tOpts), width: 150, type: 'number' },
    ];

    const rows = replenishmentDataTable?.map((e, index) => ({
        id: index,
        productName: e[3],
        sku: e[1],
        planogramFacings: e[5],
        recognizedFacings: e[4],
        stockLevel: e[9] === 0 ? e[9] : e[9].toFixed(2),
    }));

    let createdOn = replenishData.length !== 0 ? replenishData[0]?.CreatedOn : '';
    if (createdOn) {
        createdOn = dayjs(Number(createdOn.replace(/[/Date()/]/g, ""))).format(DateFormat || dateUtil.formats.dateTimeIn);
    }
    const onReplenishDetailClick = async (assetId, imageDate) => {
        if (ReplenishDetail.Module && !isPOSImage) {
            let params = { action: 'getReplenishOrder', otherAction: 'getReplenishOrder', AssetId: assetId }
            const response = await request({ url: apis.AssetPurityList, params, history, dispatch });
            dispatch({ type: actions.SET_REPLENISH_ORDER, replenishData: (response && response.success) ? response.data : [] });
            setShowReplenish({ modal: true, data: { imageDate: imageDate } });
        }
    }

    const cardContentOnly = () => {
        return <>
            <div style={{ ...style }} key={keyCard}
                className={`card-wrapper  ${!hideHeaderFilter ? "vista-container-custom" : ''} ${isMobile.mobile ? "over-flow-auto" : ""} ${isCardContentOnly ? "mb-0" : ""}`}>
                {(!hideHeaderFilter && isMobile.mobile) && <div className="card-row-mobile-wrapper">
                    <VistaCardMobile data={{ ...data }} />
                </div>}
                <div className={isMobile.landscape && isMobile.tablet || !isMobile.mobile ? "card-row-wrapper" : ""} >
                    {isOutletCard &&

                        <Grid container spacing={1} className={isMobile.portrait || !isMobile.tablet && isMobile.mobile && isMobile.landscape ? "card-row-wrapper" : ""} >
                            <>
                                <Grid item xs={12} sm={12} md={showImageOnly ? 12 : 6} style={{ height: '500px' }} className="d-flex">
                                    <Card className="mb-4 Vista-Detail-left-card-container">
                                        <VistaImages data={isOutletCard ? imageData : data} isOutletCard={isOutletCard}
                                            setImageData={setImageData} index={index} hidePrevNext={hidePrevNext}
                                            leftImageDateTime={PurityDateTime} rightImageDateTime={child_purityDateTime} idx={index} isSingleImage={true} setReplenishmentDataTable={setReplenishmentDataTable} />
                                        <div >
                                            <div className={clsx("d-flex")} style={{ padding: '.5rem 1rem' }}>
                                                <Typography className={`ellipsis-text card-footer-flex-asset pt-1 ${ReplenishDetail.Module && 'replenish-link'}`}
                                                    title={AssetSerialNumber} variant="h4" gutterBottom
                                                    onClick={() => onReplenishDetailClick(AssetId, purityDateTimeLabel)}>{AssetSerialNumber}</Typography>
                                                <LatestDateTimeLabel dateTime={purityDateTimeLabel} />
                                                <Tooltip title={t("share this information", tOpts)} placement="right">{memorizedShare}</Tooltip>
                                                <SocialShare data={data} anchorEl={anchorEl} closeShareLink={closeShareLink} />
                                                {!hideChildInfo && <Tooltip title={t("Open Card for Copy", tOpts)} placement="right" >
                                                    <span style={{ cursor: 'pointer', flex: '.09' }} onClick={() => handleOpen()}>
                                                        <img src={copyIcon} height="20" alt={"Copy"} />
                                                    </span>
                                                </Tooltip>
                                                }
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>
                                {(!showImageOnly && !showTable) ?
                                    isMobile.mobile ? "" : <Grid item xs={12} sm={12} md={6} className='d-flex' style={{ height: '500px' }}>
                                        <Card className={clsx("mb-4 w-100", { "hide-component": isMobile.mobile })} >
                                            {!isPOSImage && <ShowPlanogramInStore index={index} label={AvgPlanogramCompliance}
                                                coolerProductPerc={coolerProductPerc} isOutletCard={isOutletCard} />}
                                            <div className="divider" />
                                            <div className="d-flex customScrollbar" style={{ overflow: 'scroll' }} key={Math.random()}>
                                                <CoolerView2 index={index} data={isOutletCard ? imageData : data} showSeparator={true} inStoreData={inStoreData} />
                                                {!isPOSImage && hasChild && <>
                                                    <Divider orientation="vertical" flexItem />
                                                    <CoolerView2 index={index} data={child_Data} showSeparator={true} inStoreData={inStoreData} />
                                                </>}
                                            </div>
                                        </Card>
                                    </Grid>
                                    :
                                    null}

                                {showTable &&
                                    <Grid md={6} sm={6} lg={6} style={{ borderCollapse: 'collapse', height: '465px', marginTop: '10px' }}>
                                        <LatestDateTimeLabel dateTime={purityDateTimeLabel} extraLabel={'Replenish Details'} />
                                        <DataGridPremium
                                            rows={rows}
                                            columns={columns}
                                            pagination={false}
                                            hideFooter={true}
                                            density='compact'
                                            sx={{ height: '450px' }}
                                            disableAggregation={true}
                                            disableRowGrouping={true}
                                            disableColumnFilter={true}
                                            disableColumnMenu={true}
                                            rowSelection={false}
                                            disableSelectionOnClick
                                        />
                                    </Grid>
                                }
                            </>
                        </Grid>

                    }

                    {showModal && <CopyModal
                        idx={keyCard}
                        data={{
                            toolTipTitle: toolTipTitle,
                            LocationName: LocationName,
                            hasChild: hasChild,
                            imageURL: imageData.StitchedImageURL,
                            child_imageURL: child_imageURL,
                            AssetSerialNumber: AssetSerialNumber,
                            child_AssetSerialNumber: child_AssetSerialNumber,
                            purityDateTimeLabel: purityDateTimeLabel,
                            childPurityDateTimeLabel: childPurityDateTimeLabel
                        }}
                        showModal={showModal}
                        handleClose={handleClose} />}
                    {(showReplenish.modal && ReplenishDetail.Module) &&
                        <DialogModal open={showReplenish.modal} onClose={() => setShowReplenish({ modal: false, data: {} })}
                            dialogTitle={`${t("Replenish Detail", tOpts)} ${"- " + createdOn || ""} `}
                            maxWidth="md" className="replenish-table" hideCancel={true}>
                            <Grid container spacing={1}>
                                <Grid md={12} sm={12} lg={12} >
                                    <table>
                                        <tr>
                                            <th style={{ textAlign: 'center' }}>{t('Product Name', tOpts)}</th>
                                            <th style={{ textAlign: 'center' }}>{t('Stock Level (In %)', tOpts)}</th>
                                            <th style={{ textAlign: 'center' }}>{t('Tiers', tOpts)}</th>
                                            <th style={{ textAlign: 'center' }}>{t('Units', tOpts)}</th>
                                            <th style={{ textAlign: 'center' }}>{t('Units Needed', tOpts)}</th>
                                            <th style={{ textAlign: 'center' }}>{t('Units Type', tOpts)}</th>
                                            <th style={{ textAlign: 'center' }}>{t('Units Price', tOpts)}</th>
                                            <th style={{ textAlign: 'center' }}>{t('Total Price', tOpts)}</th>
                                        </tr>
                                        {replenishData.map(e => {
                                            return (
                                                <tr>
                                                    <td style={{ textAlign: 'left' }}>{e.ProductName}</td>
                                                    <td style={{ textAlign: 'right' }}>{e.StockLevel}</td>
                                                    <td  style={{ textAlign: 'right' }}>{e.PlanogramTiers}</td>
                                                    <td  style={{ textAlign: 'right' }}>{e.PlanogramUnits}</td>
                                                    <td  style={{ textAlign: 'right' }}>{e.UnitsNeeded}</td>
                                                    <td>{t('Case of', tOpts)} {e.CaseSize}</td>
                                                    <td  style={{ textAlign: 'right' }}>{e.UnitPrice}</td>
                                                    <td  style={{ textAlign: 'right' }}>{e.Amount}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                    {replenishData.length == 0 && <div className="text-center pt-3">{t("No Data", tOpts)}</div>}
                                </Grid>
                            </Grid>
                        </DialogModal>
                    }
                </div>
            </div>
        </>
    }

    return (<>
        {isCardContentOnly ?
            <>{cardContentOnly()}</> :
            <CellMeasurer key={keyCard} cache={cache} parent={parent} columnIndex={0} rowIndex={index} >
                {cardContentOnly()}
            </CellMeasurer>}
    </>
    );
})

export default withTranslation()(VistaDetailCard);