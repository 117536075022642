import UiModel from './ui-model';
import constants from '../utils/constants';

const alertOverviewModel = new UiModel({
    title: "Alert Overview",
    defaultSort: 'AlertOverviewId ASC',
    linkColumn: 'AlertOverviewId',
    module: "Operations",
    gridTitle: "Alert Overview",
    titleDescription: 'Check and oversee your current open alerts',
    pageTitle: "Alert Overview",
    showAddIcon: false,
    breadCrumbs: "Alert Overview",
    overrideFileName: 'Alert Overview',
    overrideLoaderOnInitialRender: true,
    addCreatedModifiedColumns: false,
    showCopy: true,
    showCreateButton: true,
    backURL: '/Operations/AlertOverview',
    preferenceId: 'playbookAlertOverview',
    idProperty: 'AlertOverviewId',
    saveOnlyModifiedValues: true,
    overrideBackRouteAndSearch: true,
    modelPermissions: { add: true, edit: true, export: true, delete: false, filter: true, resolve: true },
    externalHeaderFilters: [
        { field: 'StatusId', label: 'Status', fieldType: 'multiSelect', type: 'number', width: 150, operator: 'isAnyOf', options: [{ value: 1, label: 'New' }, { value: 255, label: 'Closed' }] },
        { field: 'Owner', label: 'Owner', fieldType: 'string', type: 'string', operator: 'contains' },
        { field: 'AssignedTo', label: 'Assigned To', fieldType: 'string', type: 'string', operator: 'contains' },
        { field: 'AlertType', label: 'Alert Type', fieldType: 'string', type: 'string', operator: 'contains' }
    ],
    initialHeaderFilters: { StatusId: [1], Owner: '', AssignedTo: '', AlertType: '' },
    initialHeaderFilterValues: [{ field: "StatusId", operator: "isAnyOf", value: [1] }],
    columns: [
        { field: "AlertOverviewId", type: 'number', width: 100, label: "ID", showOnForm: false, allowNull: true, link: true },
        { field: "AlertType", width: 180, label: "Alert Type", showOnForm: false, allowNull: true },
        { field: "StatusId", width: 100, label: "Status", filterable: false, showOnForm: false, allowNull: true, lookup: "AlertStatus", type: "radio" },
        { field: "AlertAt", type: 'date', width: 150, label: "Alert At", showOnForm: false, allowNull: true },
        { field: "Age", type: 'number', width: 100, label: "Age (Days)", showOnForm: false, allowNull: true },
        { field: "ScheduleDate", type: 'date', width: 150, label: "Schedule Date" },
        { field: "SLAHours", type: 'number', width: 100, label: "SLA Hours", showOnForm: false, allowNull: true },
        {
            field: "OwnerId", allowNull: true, filterField: "Owner", type: 'singleAutocomplete', label: "Owner", width: 100, lookup: 'AlertUserLookup', selectField: "OwnerId", useFilterField: true, singleSelect: true, comboType: 'User', renderCell: (params) => {
                const { Owner } = params.row;
                return Owner;
            }
        },
        {
            field: "ManagerId", allowNull: true, filterField: "AssignedTo", type: 'singleAutocomplete', label: "Assigned To", width: 100, lookup: 'AlertUserLookup', selectField: "ManagerId", useFilterField: true, comboType: 'User', singleSelect: true, renderCell: (params) => {
                const { AssignedTo } = params.row;
                return AssignedTo;
            }
        },
        { field: "ActionTaken", type: 'string', width: 150, label: "Notes", max: 100 },
        { field: "SerialNumber", width: 150, label: "Asset Serial #", showOnForm: false, allowNull: true },
        { field: "OutletCode", width: 100, label: "Outlet Code", showOnForm: false, allowNull: true },
        { field: "OutletName", width: 100, label: "Outlet Name", showOnForm: false, allowNull: true },
        { field: "Street", width: 100, label: "Street", showOnForm: false, allowNull: true },
        { field: "City", width: 100, label: "City", showOnForm: false, allowNull: true },
        { field: "Country", width: 100, label: "Country", showOnForm: false, allowNull: true },
        { field: "Market", type: "string", width: 100, label: "Market", showOnForm: false, allowNull: true },
        { field: "Channel", type: "string", width: 80, label: "Channel", showOnForm: false, allowNull: true },
        { field: "Classification", type: "string", width: 150, label: "Classification", showOnForm: false, allowNull: true },
        { field: "SalesRep", type: "string", width: 200, label: "Sales Rep", showOnForm: false, allowNull: true }
    ]
});

export default alertOverviewModel;