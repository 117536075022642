import React, { useState } from 'react';
import { Typography, Tooltip, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FadeLoader } from 'react-spinners';
import { toBlob } from 'html-to-image';
import copyIcon from '../../assets/images/vista-images/copy.png'
import downloadIcon from '../../assets/images/vista-images/download.png'
import apis from '../../httpUtil/apis'
import { useTranslation } from 'react-i18next';
import utils from '../../utils';

const useStyles = makeStyles((theme) => ({
  modal: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
  },
  paper: {
    'height': '80%',
    'width': '50%',
  },
}));

const CopyModal = ({ idx, data, showModal, handleClose }) => {

  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const host = process.env.REACT_APP_HOST
  const onlyForDev = host && host.includes('dev')
  const onLoadImg = () => {
    setLoading(false);
  }

  const t = utils.t
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  function generateImage(divId, action) {

    let isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox && action === "clipboard") {
      alert("Firefox does not support Copy , Try using Download");
      return;
    } else if (!isFirefox && action === "clipboard") {
      navigator.clipboard.write([]);
    }

    let nodeId = divId + '-Vista-Detail-copy-modal'

    let elements = [
      document.getElementById(divId + '-copy-image-modal'),
      document.getElementById(divId + '-download-image-modal')
    ]

    for (let i = 0; i < elements.length; i++) {
      if (elements[i]) {
        elements[i].style.display = 'none';
      }
    }

    toBlob(document.getElementById(nodeId), { pixelRatio: 1, cacheBust: true })
      .then(function (blob) {

        if (action === "clipboard") {
          navigator.clipboard.write([
            new window.ClipboardItem({
              'image/png': blob
            })
          ]);
        } else {
          downloadBlob(blob)
        }

        for (let i = 0; i < elements.length; i++) {
          if (elements[i]) {
            elements[i].style.display = 'block';
          }
        }
      });
  }

  const downloadBlob = (blob, name = 'Card.png') => {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = blobUrl;
    link.download = name;

    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    document.body.removeChild(link);
  }

  const LatestDateTimeLabel = ({ dateTime }) => {
    return (<Tooltip title="Timestamp" placement="center">
      <span className="green mr-1">{dateTime}</span>
    </Tooltip>);
  }

  //https://portal.coolrgroup.com/Controllers/CoolerImagePreview.ashx?Guid=E4568557-D12E-4310-85EE-4FA88E1A3B42&ImageType=Stitched
  //https://portal.coolrgroup.com/Controllers/CoolerImagePreview-Stitched-1EF2BE32-0F78-4C19-BB61-060ACD377FD.jpg
  function adjustUrl(URL) {
    URL = URL.split("=")[1]
    URL = URL.split("&")[0]
    let finalURL = apis.imageBaseDirectUrl + URL + ".jpg"
    return finalURL
  }


  return (
    <>
      {showModal &&
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            invisible: true
          }}
          key={idx}
        >
          <Fade in={showModal}>
            <div className={classes.paper} id={idx + '-Vista-Detail-copy-modal'}>
              <Card className="mb-4 Vista-Detail-left-card-container"
                style={{ width: '100%', height: "100%", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                id={idx + '-Card-Detail-copy-modal'} >
                <div className="d-flex" id={idx + '-flex-copy-modal'}>
                  <Tooltip title={data.toolTipTitle} placement="right">
                    <h5 className="location_header_card_1 card-header-text ellipsis-text mb-0">
                      {data.toolTipTitle}
                    </h5>
                  </Tooltip>
                </div>
                {showModal && <>
                  <div className="hero-wrapper bg-composed-wrapper h-100 rounded-right" id={idx + '-her0-wrapper-copy-modal'}>
                    <div className="flex-grow-1 w-100 d-flex align-items-end" id={idx + '-flex-row-img-copy-modal'}>
                      <div className="bg-composed-wrapper--image rounded-right opacity-9 bg-composed-filter-rm" id={idx + '-composed-wrapper-copy-modal'}>
                        <div className="card-image-container" id={idx + '-card-image-container-copy-modal'} style={{ background: '#000', overflow: 'hidden' }}>
                          {loading && <div className="image-loader-wrapper"><FadeLoader size={8} color={'#fff'} loading={true} /> {t("Loading image",tOpts)+' ...'} </div>}
                          <div style={{ width: "100%",  height: "100%", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden"  }} id={idx + '-image-div-copy-modal'}>
                            <img className="location_body_card_1_img" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} onLoad={onLoadImg} alt='' id={idx + '-image-component-copy-modal'} src={onlyForDev ? data.imageURL : adjustUrl(data.imageURL)} />
                          </div>
                          {data.hasChild && <div style={{ width: "fit-content", height: 'inherit' }} id={idx + '-image-div-child-copy-modal'}>
                            <img className="location_body_card_1_img" style={{ height: '100%' }} onLoad={onLoadImg} alt='' id={idx + '-image-child-component-copy-modal'} src={onlyForDev ? data.child_imageURL : adjustUrl(data.child_imageURL)} />
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                }

                {data.hasChild ? <div >
                  <div className="d-flex text-center" style={{ paddingTop: '.5rem' }}>
                    <Typography className="ellipsis-text card-footer-flex-asset" title={data.AssetSerialNumber} variant="h4" gutterBottom>
                      {data.AssetSerialNumber}
                    </Typography>
                    <Typography className="ellipsis-text card-footer-flex-asset" title={data.child_AssetSerialNumber} variant="h4" gutterBottom>
                      {data.child_AssetSerialNumber}
                    </Typography>
                  </div>
                  <div className="d-flex text-center" style={{ paddingTop: '.5rem' }}>
                    <div className="card-footer-flex-date"> <LatestDateTimeLabel dateTime={data.purityDateTimeLabel} /></div>
                    <div className="card-footer-flex-date"> <LatestDateTimeLabel dateTime={data.childPurityDateTimeLabel} /></div>
                  </div>
                  <div className="d-flex justify-content-end" style={{ padding: '.5rem 1rem', height: '40px' }}>
                    <Tooltip title="Copy Image to Clipboard" placement="right" >
                      <span style={{ cursor: 'pointer', flex: '.09' }} onClick={() => generateImage(idx, 'clipboard')} id={idx + '-copy-image-modal'}>
                        <img src={copyIcon} height="20" alt={"Copy"} />
                      </span>
                    </Tooltip>
                    <Tooltip title="Download the Image" placement="right" >
                      <span style={{ cursor: 'pointer', flex: '.09' }} onClick={() => generateImage(idx, 'download')} id={idx + '-download-image-modal'}>
                        <img src={downloadIcon} height="20" alt={"Copy"} />
                      </span>
                    </Tooltip>
                  </div>
                </div> :
                  <div >
                    <div className="d-flex" style={{ padding: '.5rem 1rem', height: '60px' }}>
                      <Typography className="ellipsis-text card-footer-flex-asset" title={data.AssetSerialNumber} variant="h4" gutterBottom>
                        {data.AssetSerialNumber}
                      </Typography>
                      <LatestDateTimeLabel dateTime={data.purityDateTimeLabel} />
                      <Tooltip title="Copy Image to Clipboard" placement="right" >
                        <span style={{ cursor: 'pointer', flex: '.09' }} onClick={() => generateImage(idx, 'clipboard')} id={idx + '-copy-image-modal'}>
                          <img src={copyIcon} height="20" alt={"Copy"} />
                        </span>
                      </Tooltip>
                      <Tooltip title="Download the Image" placement="right" >
                        <span style={{ cursor: 'pointer', flex: '.09' }} onClick={() => generateImage(idx, 'download')} id={idx + '-download-image-modal'}>
                          <img src={downloadIcon} height="20" alt={"Copy"} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                }
              </Card>
            </div>
          </Fade>
        </Modal>
      }
    </>
  )

}

export default CopyModal;