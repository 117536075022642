import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { apis, request } from "../../httpUtil";
import {
    Card, CardContent, Grid, Tab, Tabs, TextField,
    FormControl, InputLabel, Select, MenuItem,
    Checkbox, ListItemText, OutlinedInput, Box, Chip
} from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import GridBase from "../GridBase";
import actions from "../../redux/actions";
import { useTranslation } from 'react-i18next';
import utils from '../../utils'
import OutletCard from "../Outlets/outletCard";
import AppCalendar from "../Calendar";
import MapComponent from "./AlertMap";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import useMobile from "../../utils/useMobile";
import { useSnackbar } from "@durlabh/dframework-ui";
import alertOverviewModel from "../../pages/Alert-Overview";
import alertOverviewLocationModel from "../../pages/Alert-Overview-Location";
import { transport } from "../../httpUtil/httpRequest";
import constants from "../../utils/constants";
import swal from "sweetalert";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { DataGridPremium } from '@mui/x-data-grid-premium';
dayjs.extend(utc)

const { defaultFilter, t } = utils;
const mapContainer = { height: "492px", width: "100%", "border": "1px solid rgba(224, 224, 224, 1)", "marginTop": "8px", "borderRadius": "4px" };
const alertTypes = { GPSDisplacement: 9, PlanogramAssignment: 58 };

const tabList = [
    { icon: "spreadsheet.png", iconSize: '30', iconPosition: "start", label: "Table" },
    { icon: "map.png", iconSize: '30', iconPosition: "start", label: "Map" },
    { icon: "scheduled.png", iconSize: '30', iconPosition: "start", label: "Calendar" }
]
const defaultColumnVisibility = {
    'Street': false,
    'City': false,
    'Country': false,
    'MarketName': false,
    'LocationType.Name': false,
    'ClassificationName': false,
    'SalesRep.Username': false,
}

const kpiCards = [
    {
        icon: "warning.png", label: "Total Alerts", key: 'TotalAlert', textColor: "black", filterCall: ({ setCardFilters, setFilters, getHistoryData, alertStatus, clearFilterModal, setOldFilters }) => {
            setCardFilters('TotalAlert');
            setFilters({ ...defaultFilter, sort: { field: "Age", sort: "desc" } });
            setOldFilters({ ...defaultFilter, where: null });
            clearFilterModal();
            getHistoryData({ alertStatusManual: alertStatus, cardFiltersManual: 'TotalAlert', pagination: defaultFilter.pagination });
        }
    },
    {
        icon: "breached.png", label: "Breached SLA", key: 'BreachedSLA', textColor: "red", filterCall: ({ setCardFilters, setFilters, getHistoryData, clearFilterModal, setOldFilters }) => {
            setCardFilters('BreachedSLA');
            setFilters({ ...defaultFilter, sort: { field: "Age", sort: "desc" } });
            setOldFilters({ ...defaultFilter, where: null });
            clearFilterModal();
            getHistoryData({ breachFilter: true, cardFiltersManual: 'BreachedSLA', pagination: defaultFilter.pagination });
        }
    },
    {
        icon: "calender.png", label: "Scheduled for Today", key: 'ScheduledToday', textColor: "black", filterCall: ({ setFilters, setCardFilters, clearFilterModal, getHistoryData, setOldFilters }) => {
            setCardFilters('ScheduledToday');
            setFilters({ ...defaultFilter, isGridFilter: false, where: { 'ScheduleDate': [{ value: dayjs(new Date()).format('YYYY-MM-DD'), operator: "=" }] }, sort: { field: "Age", sort: "desc" } });
            setOldFilters({ ...defaultFilter, where: null });
            clearFilterModal();
            getHistoryData({ extra: { where: { 'ScheduleDate': [{ value: dayjs(new Date()).format('YYYY-MM-DD'), operator: "=" }] } }, cardFiltersManual: 'ScheduledToday', pagination: defaultFilter.pagination });
        }
    },
    {
        icon: "user.png", label: "Assigned to Me", key: 'AssignedToMe', textColor: "black", filterCall: ({ setFilters, setCardFilters, clearFilterModal, clientUserId = null, getHistoryData, setOldFilters }) => {
            setCardFilters('AssignedToMe');
            setFilters({ ...defaultFilter, isGridFilter: false, where: { 'Alert.OwnerId': [{ value: clientUserId, operator: "=" }] }, sort: { field: "Age", sort: "desc" } });
            setOldFilters({ ...defaultFilter, where: null });
            clearFilterModal();
            getHistoryData({ extra: { where: { 'Alert.OwnerId': [{ value: clientUserId, operator: "=" }] } }, cardFiltersManual: 'AssignedToMe', pagination: defaultFilter.pagination });
        }
    }
]

const gridConfig = ({ tOpts, key, userList, gridRef }) => {
    const columns = {
        historyList: [
            { id: "AlertId", label: "ID", flex: 0, minWidth: 70, type: "number", align: "right", valueGetter: (value) => value.row.AlertId },
            { id: "AlertType", label: "Alert Type", flex: 0, minWidth: 200, valueGetter: (value) => value.row.AlertType },
            { id: "Alert.StatusId", label: "Status", flex: 0, minWidth: 60, filterable: false, valueGetter: (value) => value.row.StatusId },
            {
                id: "AlertAt", label: "Alert At", type: "date", flex: 0, minWidth: 90, valueGetter: (value) => value.row.AlertAt, format: (item) => {
                    return utils.formatDate(item.value, true);
                }
            },
            {
                id: "AlertAge", label: "Age (Days)", filterable: false, type: "number", align: "right", flex: 0, minWidth: 85, valueGetter: (value) => value.row.AlertAge, format: (item) => {
                    let value = 0;
                    if (item.value) {
                        value = Math.floor(item.value / 1440);
                    }
                    return value;
                }
            },
            {
                id: "ScheduleDate", label: "Schedule Date", type: "date", flex: 0, minWidth: 100, editable: true, valueGetter: (value) => value.row.ScheduleDate, format: (item) => {
                    return utils.formatDate(item.value, true);
                }
            },
            { id: "SLAHours", label: "SLA Hours", type: "number", align: "right", flex: 0, minWidth: 80, valueGetter: (value) => value.row.SLAHours },
            { id: "Owner.PrimaryEmail", label: "Owner", flex: 0, minWidth: 200, editable: true, valueGetter: (value) => value.row.OwnerName, renderEditCell: (row) => userLookup({ key: 'OwnerId', row, userList, gridRef }) },
            { id: "Manager.PrimaryEmail", label: "Manager", flex: 0, minWidth: 200, editable: true, valueGetter: (value) => value.row.ManagerName, renderEditCell: (row) => userLookup({ key: 'ManagerId', row, userList, gridRef }) },
            { id: "Asset.SerialNumber", label: "Asset Serial #", flex: 0, minWidth: 120, valueGetter: (value) => value.row.AssetSerialNumber },
            { id: "Location.Code", label: "Outlet Code", flex: 0, minWidth: 120, valueGetter: (value) => value.row.OutletCode },
            { id: "Location.Name", label: "Outlet Name", flex: 0, minWidth: 200, valueGetter: (value) => value.row.Location },
            { id: "Street", label: "Street", flex: 0, minWidth: 120, valueGetter: (value) => value.row.Street },
            { id: "City", label: "City", flex: 0, minWidth: 120, valueGetter: (value) => value.row.City },
            { id: "Country", label: "Country", flex: 0, minWidth: 120, valueGetter: (value) => value.row.Country },
            { id: "MarketName", label: "Market", flex: 0, minWidth: 120, valueGetter: (value) => value.row.MarketName },
            { id: "LocationType.Name", label: "Channel", flex: 0, minWidth: 120, valueGetter: (value) => value.row.Channel },
            { id: "ClassificationName", label: "Classification", flex: 0, minWidth: 120, valueGetter: (value) => value.row.Classification },
            { id: "SalesRep.Username", label: "Sales Rep", flex: 0, minWidth: 120, valueGetter: (value) => value.row.SalesRep },
        ],
        mapList: [
            { id: "AlertId", label: "ID", flex: 0, type: "number", align: "right", minWidth: 70, valueGetter: (value) => value.row.AlertId },
            { id: "AlertType", label: "Alert Type", flex: 0, minWidth: 200, valueGetter: (value) => value.row.AlertType },
            {
                id: "Alert.StatusId", label: "Status", flex: 0, minWidth: 60, filterable: false, valueGetter: (value) => value.row.StatusId, format: (item) => {
                    return item.value === 1 ? 'New' : 'Closed';
                }
            },
            { id: "Location.Code", label: "Outlet Code", flex: 0, minWidth: 120, valueGetter: (value) => value.row.OutletCode },
            {
                id: "ScheduleDate", label: "Schedule Date", type: "date", flex: 0, minWidth: 100, editable: true, valueGetter: (value) => value.row.ScheduleDate, format: (item) => {
                    return utils.formatDate(item.value, true);
                }
            },
            { id: "SLAHours", label: "SLA Hours", type: "number", align: "right", flex: 0, minWidth: 80, valueGetter: (value) => value.row.SLAHours },
            { id: "Owner.PrimaryEmail", label: "Owner", flex: 0, minWidth: 200, editable: true, valueGetter: (value) => value.row.OwnerName, renderEditCell: (row) => userLookup({ key: 'OwnerId', row, userList, gridRef }) },
            { id: "Manager.PrimaryEmail", label: "Manager", flex: 0, minWidth: 200, editable: true, valueGetter: (value) => value.row.ManagerName, renderEditCell: (row) => userLookup({ key: 'ManagerId', row, userList, gridRef }) },
            { id: "MarketName", label: "Market", flex: 0, minWidth: 120, valueGetter: (value) => value.row.MarketName },
            { id: "LocationType.Name", label: "Channel", flex: 0, minWidth: 120, valueGetter: (value) => value.row.Channel },
            { id: "ClassificationName", label: "Classification", flex: 0, minWidth: 120, valueGetter: (value) => value.row.Classification },
            { id: "SalesRep.Username", label: "Sales Rep", flex: 0, minWidth: 120, valueGetter: (value) => value.row.SalesRep },
        ]
    }
    return columns[key]
}

const userLookup = ({ key, row, userList, gridRef }) => {

    const { id, field } = row;

    let defaultValue = userList.filter((item) => row?.row[key] === item.LookupId)
    defaultValue = defaultValue ? defaultValue[0]?.DisplayValue : '';

    const handleChange = (e, value) => {
        let selectedUser = userList.filter((item) => value === item.DisplayValue);
        selectedUser = selectedUser[0].LookupId;
        gridRef.current.setEditCellValue({ id, field, value: selectedUser });
    }

    return <Autocomplete
        size="small"
        fullWidth={true}
        disableClearable
        options={userList.map((item) => item.DisplayValue)}
        defaultValue={defaultValue || ''}
        onChange={handleChange}
        renderInput={(params) => <TextField className={`edit-Product-input`} {...params} />}
        renderOption={(params, option) => <li {...params} className={`cursor_pointer product-font-size editorder-border `} >{option}</li>}
    />
}

const AlertSelector = ({ alertStatus, setAlertStatus }) => {
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const lookUp = [
        { value: 1, displayValue: "New" },
        { value: 255, displayValue: "Closed" }
    ]
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        getContentAnchorEl: null,
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setAlertStatus(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <div className="mt-2" >
            <FormControl className="alert-status-form" size='small' fullWidth>
                <InputLabel id="alert-status-label" className="alert-status-label">{alertStatus.length === 0 ? t('No Alert Selected', tOpts) : t('Alert Status', tOpts)}</InputLabel>
                <Select
                    labelId="alert-status-label"
                    id="alert-status-select"
                    multiple
                    fullWidth
                    value={alertStatus}
                    onChange={handleChange}
                    input={<OutlinedInput label={alertStatus.length === 0 ? t('No Alert Selected', tOpts) : t('Alert Status', tOpts)} />}
                    renderValue={(alertStatus) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {alertStatus.map((value) => {
                                const text = lookUp.filter((item) => item.value === value)[0]
                                return <Chip className="alert-chip" key={"Chip-" + text.values} label={t(text.displayValue, tOpts)} />
                            })}
                        </Box>
                    )}
                    MenuProps={MenuProps} >
                    {lookUp.map((item) => (
                        <MenuItem key={'MenuItem-' + item.value} value={item.value}>
                            <Checkbox checked={alertStatus.indexOf(item.value) > -1} />
                            <ListItemText primary={t(item.displayValue, tOpts)} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
};

const RenderProductFacings = ({ planogramProductFacings }) => {
    return (
        <div style={{ maxWidth: 500, overflowX: "auto", maxHeight: 400 }}>
            {planogramProductFacings?.map((facing, index) => {
                const maxStackSize = facing.products.reduce((max, item) => Math.max(max, item.stackSize), 0);
                return (
                    <div key={index} style={{ display: "flex", borderBottom: "10px solid #e0e0e0", marginBottom: 10, paddingBottom: 10, }} >
                        {facing.products.map((product) => {
                            const elements = [];
                            const { id, stackSize } = product;
                            if (maxStackSize === 0) {
                                elements.push(id);
                            } else {
                                for (let i = 1; i <= maxStackSize; i++) {
                                    if (stackSize === 0 && i === maxStackSize) {
                                        elements.push(id);
                                    } else {
                                        elements.push(stackSize >= i ? id : '');
                                    }
                                }
                            }
                            return (
                                <div key={id}>
                                    {elements.map((element, elementIndex) => (
                                        <div key={elementIndex} style={{ border: element ? "1px solid #8b8b8b" : 'none', borderRadius: 10, padding: 10, margin: 3, width: 72, height: 100, }} >
                                            {element && (
                                                <img width={50} height={80} alt={element} src={`${apis.imgBaseUrl}/controllers/thumbnail.ashx?imageType=Thumbnail&ImagePath=${element}.png&maxWwidth=50`} />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};


function AlertOverview(props) {

    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const userData = useSelector(state => state.appReducer.userData);
    const { isOutletCard = false, setAlertCount = null, location = null, sort: outletCardSort, clientId: outletClientId } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [kpiValues, setKpiValues] = useState({ total: 0, breached: 0, scheduled: 0, my: 0 });
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [oldFilter, setOldFilters] = useState({ ...defaultFilter, linkOperator: "AND" });
    const [filterReady, setFilterReady] = useState(false);
    const [defaultState, setDefaultState] = useState(false);
    const [cardFilters, setCardFilters] = useState('TotalAlert');
    const [filter, setFilters] = useState({ ...defaultFilter, sort: { field: "Age", sort: "desc" } });
    const [outletCard, setOutletCard] = useState({ modal: false, data: "" });
    const [userList, setUserList] = useState([]);
    const [alertStatus, setAlertStatus] = useState([1]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(defaultColumnVisibility);
    const [dataTypesG, setDataTypesG] = useState(['table', 'metrics']);
    const [selectedMarker, setSelectedMarker] = useState({});
    const [onLoadDone, setOnLoadDone] = useState(false);
    const [hideAllColumns, setHideAllColumns] = useState(false)
    const [exportColumns, setExportColumns] = useState([]);
    const filterRef = useRef(filter);
    const activeTab = useRef(0);
    const gridRef = useRef();
    const onFilterModelChange = utils.onFilterModelChange({ setFilters });
    const page = filter?.pagination?.page || 0;
    const pageSize = filter?.pagination.pageSize;
    const { ClientUserId } = userData && userData.tags ? userData.tags : 0;
    const userId = userData && userData.userId;
    const { gridColumn = gridConfig({ tOpts, key: "historyList", userList, gridRef }) } = props;
    const commonGridOptions = {
        filterMode: 'server',
        sortingMode: 'server',
        filterModel: filterModel,
        page: page,
        paginationMode: 'server',
        onPageChange: setPage,
        pageSize: pageSize,
        onPageSizeChange: setPageSize,
        onFilterModelChange: setFilterModel,
        disableSelectionOnClick: true,
        density: "compact",
        hideFooter: false,
    }
    const isMobile = useMobile();
    const [currentAsset, setCurrentAsset] = useState();
    const [currentLatLong, setCurrentLatLong] = useState();
    const [planogramList, setPlanogramList] = useState();
    const [renderKey, setRenderKey] = useState(0);
    const [locationInfo, setLocationInfo] = useState({});
    const [productList, setProductList] = useState(null);
    const [userCurrencySymbol, setUserCurrencySymbol] = useState();
    const [currentPlanogram, setCurrentPlanogram] = useState();
    const [alertType, setAlertType] = useState();
    const [planogramFacingDetails, setPlanogramFacingDetails] = useState();
    const gridDefaultFilters = [];
    const snackbar = useSnackbar();

    const planogramAsset = [
        { field: "Description", headerName: t("Product Name", tOpts), flex: 0, minWidth: 300, filterable: false, sortable: false },
        { field: "sku", headerName: t("SKU", tOpts), flex: 1, minWidth: 150, filterable: false, sortable: false },
        { field: "CaseType", headerName: t("Case Type", tOpts), flex: 1, minWidth: 150, filterable: false, sortable: false },
        {
            field: "price", type: 'number', headerName: t("Pricing", tOpts), flex: 1, minWidth: 150, filterable: false, sortable: false, renderCell: (params) => {
                const price = params.value;
                return price ? `${userCurrencySymbol} ${price}` : '';
            }
        },
        { field: "facings", type: 'number', headerName: t("Facings", tOpts), flex: 1, minWidth: 150, filterable: false, sortable: false },
        { field: "facingsPerCase", type: 'number', headerName: t("Cases per Facings", tOpts), flex: 1, minWidth: 150, filterable: false, sortable: false }
    ]

    if (cardFilters === constants.AlertOverviewCardTitles.AssignedToMe) {
        gridDefaultFilters.push({ field: 'OwnerId', operator: '=', value: userId });
    }
    if (cardFilters === constants.AlertOverviewCardTitles.ScheduledToday) {
        gridDefaultFilters.push({ field: 'ScheduleDate', operator: '=', value: dayjs(new Date()).format('YYYY-MM-DD') });
    }
    if (cardFilters === constants.AlertOverviewCardTitles.BreachedSLA) {
        gridDefaultFilters.push({ field: 'IsBreachedSLA', operator: '=', value: 1 });
    }

    function fetchUpdatedData() {
        const dataTypes = ['table', 'metrics'];
        if (activeTab.current === 1) {
            dataTypes.push('map');
        } else if (activeTab.current === 2) {
            dataTypes.push('calender');
        }
        setCardFilters(cardFilters);
        getHistoryData({ extra: filter, dataTypes, cardFiltersManual: cardFilters ? cardFilters : 'TotalAlert' });
    }

    useEffect(() => {
        setRenderKey(Math.random());
    }, [cardFilters, alertStatus])

    const updateParentFilters = (filters) => {
        setAlertStatus(filters.StatusId);
    }

    useEffect(() => {
        loadUserCombo();
        if (!isOutletCard)
            dispatch({
                type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                    filterButton: null,
                    hidden: { search: true, operation: true, export: true, print: true, filter: true }
                }
            });

        return () => {
            if (!isOutletCard)
                dispatch({
                    type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                        filterButton: null,
                        clear: null,
                        apply: null,
                        hidden: { search: false, operation: false, export: false, print: false, filter: false }
                    }
                });
            if (!isOutletCard) {
                utils.removeBackButton(dispatch);
            }
        }
    }, []);

    useEffect(() => {
        fetchUpdatedData();
    }, [alertStatus])

    useEffect(() => {
        filterRef.current = filter;
        const pagination = JSON.stringify(filter.pagination) === JSON.stringify(oldFilter.pagination),
            sort = JSON.stringify(filter.sort) === JSON.stringify(oldFilter.sort),
            where = JSON.stringify(filter.where) === JSON.stringify(oldFilter.where),
            operator = JSON.stringify(filter.linkOperator) === JSON.stringify(oldFilter.linkOperator);
        if (filter.isGridFilter && (!pagination || !sort || !where || !operator)) {
            getHistoryData({ extra: filter, filter: true });
            setOldFilters(utils.deepCloneObject(filter));
        }
    }, [filter])

    useEffect(() => {
        if (filterReady) {
            onFilterModelChange({ ...filterModel, pagination: { ...(filter?.pagination || {}), page: 0 } })
        }
        if (filterModel?.items.length === 0) {
            setSelectedMarker({});
        }
    }, [filterModel])

    useEffect(() => {
        let columnsCloned = gridConfig({ key: 'historyList' });
        columnsCloned = columnsCloned.map((item) => {
            return item
        })
        const allColumns = [];
        Object.keys(columnsCloned).forEach(key => {
            if (columnsCloned[key] !== false) {
                allColumns.push(columnsCloned[key].id);
            }
        });
        setColumnVisibilityModel(prevState => {
            const updatedObject = { ...prevState };
            allColumns.forEach(item => {
                if (!(item in columnVisibilityModel)) {
                    columnVisibilityModel[item] = true;
                }
            });
            return updatedObject;
        });
        const updatedExportColumns = [];
        allColumns.forEach(item => {
            if (!(item in columnVisibilityModel)) {
                updatedExportColumns.push(item);
            }
        });
        const defaultCol = defaultColumns(updatedExportColumns)
        setExportColumns(defaultCol);
    }, []);

    const defaultColumns = (columnsArr) => {
        columnsArr.map((column, index) => {
            switch (column) {
                case "Owner.PrimaryEmail":
                    columnsArr[index] = "OwnerName"
                    break;
                case "Manager.PrimaryEmail":
                    columnsArr[index] = "ManagerName"
                    break;
                case "Asset.SerialNumber":
                    columnsArr[index] = "AssetSerialNumber"
                    break;
                case "Location.Code":
                    columnsArr[index] = "OutletCode"
                    break;
                case "LocationType.Name":
                    columnsArr[index] = "Channel"
                    break;
                case "Location.Name":
                    columnsArr[index] = "Location"
                    break;
                case "ClassificationName":
                    columnsArr[index] = "Classification"
                    break;
                case "SalesRep.Username":
                    columnsArr[index] = "SalesRep"
                    break;
                case "Alert.StatusId":
                    columnsArr[index] = "StatusId"
                    break;
                default:
                    break;
            }
        })
        return columnsArr
    }

    function setPage(page) {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, page } }));
    }

    function setPageSize(pageSize) {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, pageSize, page: 0 } }));
    }

    async function getHistoryData({ action = "", extra = {}, pagination, breachFilter = false, isGridFilter = false, dataTypes = dataTypesG, alertStatusManual = null, cardFiltersManual = null, fromMap = false }) {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
        const { page, pageSize } = pagination ? pagination : filter?.pagination;
        let cardFilter = cardFiltersManual ? cardFiltersManual : cardFilters;
        let payload = {
            ...extra,
            dataTypes: dataTypes,
            breachFilter: (cardFilter === 'BreachedSLA') || breachFilter,
            AlertStatusId: alertStatusManual ? alertStatusManual : alertStatus,
            action: "AlertOverview",
            cardFilters: cardFilter,
            pagination: {
                page: page + 1,
                pageSize
            },
            clientId: outletClientId
        };
        const additionalParams = {}
        if (action === 'export') {
            payload["exportData"] = true;
            payload["exportColumns"] = exportColumns;
        }
        if (isOutletCard) {
            payload.where = { ...payload.where, 'Alert.LocationId': [{ value: location, operator: "=", sqlType: 'int' }] };
            payload.sort = Object.keys(payload).includes('sort') ? payload.sort : outletCardSort;
            payload.AlertStatusId = [1, 255];
        }

        if (payload.cardFilters === 'ScheduledToday' && !payload.where.ScheduleDate) {
            payload.where.ScheduleDate = [{ value: dayjs(new Date()).format('YYYY-MM-DD'), operator: "=" }]
        }
        if (payload.cardFilters === 'AssignedToMe' && !payload.where['Alert.OwnerId']) {
            payload.where['Alert.OwnerId'] = [{ value: ClientUserId, operator: "=" }];
        }

        const resultD = await request({ url: apis.AlertHistory, params: payload, history, dispatch, jsonPayload: true, additionalParams });
        if (resultD) {
            if (!fromMap)
                setDataTypesG(dataTypes);
            if (isGridFilter) {
                setData(prev => ({ ...prev, ...resultD }));
                return;
            }
            if (resultD.hasOwnProperty('totalCount') && setAlertCount) {
                setAlertCount(resultD.totalCount || 0);
            }
            if (resultD.hasOwnProperty('metrics')) {
                setKpiValues(resultD.metrics);
            }
            const finalData = { ...data }
            if (resultD.hasOwnProperty('totalCount')) {
                finalData.totalCount = resultD.totalCount;
            }
            if (resultD.hasOwnProperty('alertList')) {
                finalData.alertList = resultD.alertList;
            }
            if (resultD.hasOwnProperty('mapList') && JSON.stringify(data?.mapList) !== JSON.stringify(resultD?.mapList)) {
                finalData.mapList = resultD.mapList;
                finalData.mapKey = 'Map-' + Date.now();
                setOnLoadDone(false);
            }
            if (resultD.hasOwnProperty('calendar')) {
                finalData.calendar = resultD.calendar;
            }
            setData(finalData);
        }
        setTimeout(() => {
            setFilterReady(true)
        }, 100);
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
    }

    function clearFilter() {
        setFilterModel({ linkOperator: "AND", items: [] });
        setSelectedMarker({});
        setDefaultState(true)
    }
    const handleChangeTab = (event, newValue) => {
        if (activeTab.current != newValue) {
            setFilterModel({ linkOperator: "AND", items: [] });
            setFilters(defaultFilter);
            setCardFilters('TotalAlert');
            setOldFilters({ ...defaultFilter, linkOperator: "AND" })
            const dataTypes = ['table', 'metrics'];
            if (newValue === 1) {
                dataTypes.push('map');
            } else if (newValue === 2) {
                dataTypes.push('calender');
            }
            setData({});
            setHideAllColumns(false);
            setExportColumns([]);
            setColumnVisibilityModel(defaultColumnVisibility);
            activeTab.current = newValue;
            getHistoryData({ dataTypes, pagination: defaultFilter.pagination, extra: { where: {} }, cardFiltersManual: 'TotalAlert' });
        }
    };

    async function loadUserCombo() {
        const params = { action: "load", comboTypes: [{ "type": "ClientUser", "loaded": true }] };
        if (outletClientId) {
            params.uiClientIds = outletClientId.toString();
        }
        let data = await request({ url: apis.Asset, params, history, dispatch, });
        data = data?.combos?.ClientUser || [];
        setUserList(data);
    }

    const computeMutation = (newRow, oldRow) => {
        if (newRow.ScheduleDate && newRow.ScheduleDate !== oldRow.ScheduleDate) {
            let date = dayjs(newRow.ScheduleDate).format('YYYY-MM-DD');
            date = dayjs(new Date(date)).utc().format();
            return { key: "ScheduleDate", value: date, id: newRow.AlertId, type: 'date' };
        }
        if (newRow["Owner.PrimaryEmail"] && (newRow["Owner.PrimaryEmail"] !== oldRow["Owner.PrimaryEmail"] && newRow["Owner.PrimaryEmail"] !== oldRow["OwnerName"])) {
            return { key: "Owner.PrimaryEmail", value: newRow["Owner.PrimaryEmail"], id: newRow.AlertId };
        }
        if (newRow["Manager.PrimaryEmail"] && (newRow["Manager.PrimaryEmail"] !== oldRow["Manager.PrimaryEmail"] && newRow["Manager.PrimaryEmail"] !== oldRow["ManagerName"])) {
            return { key: "Manager.PrimaryEmail", value: newRow["Manager.PrimaryEmail"], id: newRow.AlertId };
        }
        return null;
    }

    const processRowUpdate = React.useCallback(async (newRow, oldRow) => {
        try {
            if (!newRow) {
                return oldRow;
            }
            const mutation = computeMutation(newRow, oldRow);
            if (mutation) {
                if (mutation.key === 'Owner.PrimaryEmail') {
                    mutation.key = 'OwnerId'
                }
                if (mutation.key === 'Manager.PrimaryEmail') {
                    mutation.key = 'ManagerId'
                }

                const params = {
                    action: "AlertUpdate",
                    updatePayload: mutation
                };
                await request({ url: apis.AlertHistory, params, history, dispatch, jsonPayload: true });
                const dataTypes = ['table', 'metrics'];
                if (activeTab.current === 1) {
                    dataTypes.push('map');
                } else if (activeTab.current === 2) {
                    dataTypes.push('calender');
                }
                getHistoryData({ extra: filterRef.current, dataTypes });
                return (newRow);
            } else {
                return (oldRow);
            }
        } catch (error) {
            return (oldRow);
        }
    }, [filter]);

    const onAlertClick = async ({ event }) => {
        const { OutletName: locationName, OutletCode: code, LocationId: locationId } = event.row;
        let params = {
            action: "CustomList", asArray: 0, limit: 10, start: 0, showAllImages: false, isFetchCoolerData: true,
            sort: 'AvgStockPercentage', dir: 'ASC'
        };
        params = { ...params, ...{ assetOrLocationName: '', isForRank: true, LocationId: locationId } }
        let outletData = await request({ url: apis.assetLatestImagesNew, params, history, dispatch });
        setOutletCard({ modal: true, data: { locationName, code, locationId }, vistaData: outletData.records })
    }

    function clearFilterModal() {
        setFilterReady(false)
        setFilterModel({ items: [] })
    }

    async function onResolveClick({ record }) {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
        setCurrentLatLong();
        const { LatestLatitude = 0, LatestLogitude = 0, AlertOverviewId, SerialNumber, AlertTypeId, LocationId, OutletCode, OutletName, AssetTypeId } = record;
        if (!LocationId) {
            snackbar.showMessage(t('No Location assigned on Alert, please assign a Location first.', tOpts));
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
            return;
        }
        setCurrentAsset({ SerialNumber, AlertOverviewId });
        if (AlertTypeId === alertTypes.GPSDisplacement) {
            setAlertType(alertTypes.GPSDisplacement);
            setCurrentLatLong({ LatestLatitude, LatestLogitude });
        } else if (AlertTypeId === alertTypes.PlanogramAssignment) {
            setAlertType(alertTypes.PlanogramAssignment);
            setLocationInfo({ locationName: OutletName, code: OutletCode, locationId: LocationId });
            const headers = {};
            let params = {
                url: `${apis.GetPlanograms}`,
                method: 'POST',
                data: { AssetTypeId },
                headers: {
                    "Content-Type": "application/json",
                    ...headers
                },
                credentials: 'include'
            };
            const response = await transport(params);
            if (response?.data?.success) {
                setPlanogramList(response.data.planograms);
            }
        }
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
    }

    async function onAssignmentClick({ record }) {
        const { Name } = record;
        swal({
            title: t("Are you sure?", tOpts),
            text: `${t("You have selected", tOpts)} ${Name} ${t("to assign to", tOpts)} ${currentAsset.SerialNumber}. ${t("Do you want to continue?", tOpts)}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (isConfirm) => {
                if (isConfirm) {
                    dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
                    const { LocationId } = record;
                    const headers = {};
                    let params = {
                        url: `${apis.LocationAssignment}`,
                        method: 'POST',
                        data: { LocationId, AlertId: currentAsset.AlertOverviewId, SerialNumber: currentAsset.SerialNumber },
                        headers: {
                            "Content-Type": "application/json",
                            ...headers
                        },
                        credentials: 'include'
                    };
                    const response = await transport(params);
                    if (response.data.success) {
                        setCurrentAsset(null);
                        setRenderKey(Math.random());
                        fetchUpdatedData();
                        snackbar.showMessage(t('Location Assigned Successfully.', tOpts));
                    }
                    dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
                }
            });
    }

    async function getPlanogramDetails({ planogramId, planogramName }) {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
        setCurrentPlanogram({ planogramId, planogramName });
        const { locationName, code, locationId } = locationInfo;
        const pricingResponse = await request({ url: apis.OutletCardView, params: { locationName, code, dataTypes: ['info'], locationId }, history, dispatch, jsonPayload: true });
        const { pricing, userCurrencySymbol } = pricingResponse?.OutletInfo;
        setUserCurrencySymbol(userCurrencySymbol);
        let params = { action: "load", id: planogramId };
        const response = await request({ url: apis.AlertPlanogram, params, history, dispatch });
        if (response?.data) {
            const replenishDetails = response.data?.ReplenishDetails;
            const facingDetails = response.data?.FacingDetails;
            if (facingDetails !== '') {
                setPlanogramFacingDetails(JSON.parse(facingDetails));
            }
            if (replenishDetails !== "") {
                const parsedReplenishDetails = JSON.parse(replenishDetails);
                let parsedFacingDetails = [];
                if (facingDetails) {
                    parsedFacingDetails = JSON.parse(facingDetails).flatMap(facing => facing.products);
                }

                const facingsCount = parsedFacingDetails.reduce((acc, { id }) => {
                    acc[id] = (acc[id] || 0) + 1;
                    return acc;
                }, {});

                const updatedProductData = parsedReplenishDetails?.map(product => {
                    const pricingData = pricing.find(p => {
                        const sizeInProduct = parseInt(product.CaseType?.match(/\d+/)?.[0] || "0");
                        return p.ProductId === product.ProductId && p.Size === sizeInProduct;
                    });
                    return {
                        ...product,
                        facings: facingsCount[product.ProductId] || 0,
                        price: pricingData ? pricingData.Price : '',
                        facingsPerCase: pricingData ? pricingData.FacingsPerCase : '',
                        sku: pricingData ? pricingData.SKU : ''
                    };
                });
                setProductList(updatedProductData);
            } else {
                const payload = { action: "previewPlanogram", otherAction: "previewPlanogram", PlanogramId: planogramId, AssetId: "" };
                const data = await request({ url: apis.AssetPurityList, params: payload, history, dispatch });
                if (data) {
                    setProductList(data?.planogram[0]?.productSummary.map((item) => ({
                        ProductId: item.product.id,
                        Description: item.product.name,
                        sku: item.product.sku,
                    })));
                }
            }
        }
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
    }

    async function planogramAssignment() {
        const { planogramId, planogramName } = currentPlanogram;
        swal({
            title: t("Are you sure?", tOpts),
            text: `${t("You have selected", tOpts)} ${planogramName} ${t("to assign to", tOpts)} ${currentAsset.SerialNumber}. ${t("Do you want to continue?", tOpts)}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (isConfirm) => {
                if (isConfirm) {
                    dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
                    const headers = {};
                    let params = {
                        url: `${apis.PlanogramAssignment}`,
                        method: 'POST',
                        data: { AlertId: currentAsset.AlertOverviewId, SerialNumber: currentAsset.SerialNumber, PlanogramId: planogramId },
                        headers: {
                            "Content-Type": "application/json",
                            ...headers
                        },
                        credentials: 'include'
                    };
                    const response = await transport(params);
                    if (response.data.success) {
                        setPlanogramList(null);
                        setProductList(null);
                        setRenderKey(Math.random());
                        fetchUpdatedData();
                        snackbar.showMessage(t('Planogram Assigned Successfully.', tOpts));
                    }
                    dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
                }
            });
    }

    function onPlanogramCancelClick() {
        setPlanogramList(null);
        setProductList(null);
    };

    function alertOverviewTable() {
        return <div>
            <Card className="mt-2">
                <CardContent className="pt-0">
                    <div style={{ overflowY: 'auto', marginTop: 10 }}>
                        <alertOverviewModel.Grid additionalFilters={gridDefaultFilters} reRenderKey={renderKey} onDoubleClick={onAlertClick} onResolveClick={onResolveClick} updateParentFilters={updateParentFilters} />
                    </div>
                </CardContent>
            </Card>
            {(currentAsset && alertType === alertTypes.GPSDisplacement) ?
                <Card className="mt-2">
                    <CardContent>
                        <div>
                            <alertOverviewLocationModel.Grid onAssignmentClick={onAssignmentClick} reRenderKey={currentAsset} additionalFilters={[{ field: 'Latitude', type: "numeric", value: currentLatLong.LatestLatitude }, { field: 'Longitude', type: "numeric", value: currentLatLong.LatestLogitude }]} onDoubleClick={() => { }} />
                        </div>
                    </CardContent>
                </Card>
                : null}
            {(planogramList && alertType === alertTypes.PlanogramAssignment) ?
                <Card className="mt-2">
                    <CardContent>
                        <Grid container spacing={2} style={{ border: '1px solid #e0e0e0', borderRadius: 10 }}>
                            <Grid item xs={3} style={{ padding: 0, borderRight: '1px solid #e0e0e0', maxHeight: 450, overflowY: 'auto' }}>
                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    {planogramList.map((planogram) => {
                                        const { PlanogramId, PlanogramName } = planogram;
                                        const labelId = `checkbox-list-label-${PlanogramId}`;
                                        return (
                                            <ListItem key={PlanogramId} disablePadding style={{ padding: 0 }}>
                                                <ListItemButton role={undefined} onClick={() => getPlanogramDetails({ planogramId: PlanogramId, planogramName: PlanogramName })} dense>
                                                    <ListItemText id={labelId} primary={`${PlanogramName}`} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Grid>
                            <Grid item xs={4} style={{ padding: 0, borderRight: '1px solid #e0e0e0' }}>
                                {planogramFacingDetails ? <RenderProductFacings planogramProductFacings={planogramFacingDetails} /> : null}
                            </Grid>
                            <Grid item xs={5} style={{ padding: 0 }}>
                                {productList ?
                                    <>
                                        <DataGridPremium
                                            rows={productList || []}
                                            columns={planogramAsset}
                                            getRowId={(row) => row.ProductId}
                                            pagination={false}
                                            hideFooter={true}
                                            density='compact'
                                            sx={{ height: '400px' }}
                                            disableAggregation={true}
                                            disableRowGrouping={true}
                                            disableColumnFilter={true}
                                            disableColumnMenu={true}
                                            rowSelection={false}
                                            disableSelectionOnClick
                                        />
                                        <div style={{ float: 'right' }}>
                                            <Button style={{ margin: 10 }} variant="contained" onClick={() => planogramAssignment()}>{t('Assign', tOpts)}</Button>
                                            <Button style={{ margin: 10 }} variant="contained" onClick={() => onPlanogramCancelClick()}>{t('Cancel', tOpts)}</Button>
                                        </div>
                                    </>
                                    : null}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                : null}
        </div>
    }

    function alertOverviewMap() {

        const handleRowClick = async (event, data, filter) => {
            const editable = event?.colDef?.editable || false;
            if (!editable) {
                const selectedRow = data.mapList.filter((item) => item.OutletCode === event.row.OutletCode)
                setSelectedMarker({ ...selectedRow[0], fromTable: true })
            }
        }

        return <>
            <Card className="mt-2">
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div className="d-flex d-flex-start d-flex-align-center">
                                <AlertSelector alertStatus={alertStatus} setAlertStatus={setAlertStatus} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                        </Grid>
                        <Grid item md={12} sm={12} lg={6} className="igh-action-table">
                            <GridBase
                                clearFilter={() => clearFilter()}
                                customClass={'mt-2 data-grid-font-12 height-500'}
                                autoHeight={false}
                                columns={gridConfig({ tOpts, key: "mapList", userList, gridRef })}
                                data={data?.alertList}
                                rowsPerPage={[10, 20, 50, 100]}
                                movePagination={false}
                                onSortModelChange={onFilterModelChange}
                                showTitleColumn={true}
                                gridRef={gridRef}
                                otherOptions={{
                                    initialState: {
                                        columns: {
                                            columnVisibilityModel: {
                                                'MarketName': false,
                                                'LocationType.Name': false,
                                                'ClassificationName': false,
                                                'SalesRep.Username': false,
                                            }
                                        }
                                    },
                                    ...commonGridOptions,
                                    rowCount: data?.totalCount,
                                    onCellClick: (e) => handleRowClick(e, data, filter),
                                    experimentalFeatures: { newEditingApi: true },
                                    processRowUpdate: () => processRowUpdate()
                                }} />
                        </Grid>
                        <Grid item md={12} sm={12} lg={6} >
                            <div style={mapContainer}>
                                {data?.mapList ?
                                    <MapComponent
                                        mapData={data?.mapList}
                                        setFilterReady={setFilterReady}
                                        setFilterModel={setFilterModel}
                                        selectedMarker={selectedMarker}
                                        setSelectedMarker={setSelectedMarker}
                                        tableList={data?.alertList}
                                        mapKey={data?.mapKey}
                                        onLoadDone={onLoadDone}
                                        setOnLoadDone={setOnLoadDone} /> :
                                    <div className="text-center pt-5">{t('Loading Map data ...', tOpts)}</div>}
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    }

    function alertOverviewCalendar() {

        return <>
            <Card className="mt-2">
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div className="d-flex d-flex-start d-flex-align-center">
                                <AlertSelector alertStatus={alertStatus} setAlertStatus={setAlertStatus} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                        </Grid>
                        <Grid item md={12} sm={12} lg={6} className="igh-action-table">
                            <GridBase
                                clearFilter={() => clearFilter()}
                                customClass={'mt-2 data-grid-font-12 height-500'}
                                autoHeight={false}
                                columns={gridConfig({ tOpts, key: "mapList", userList, gridRef })}
                                gridRef={gridRef}
                                data={data?.alertList}
                                rowsPerPage={[10, 20, 50, 100]}
                                movePagination={false}
                                onSortModelChange={onFilterModelChange}
                                showTitleColumn={true}
                                otherOptions={{
                                    initialState: {
                                        columns: {
                                            columnVisibilityModel: {
                                                'MarketName': false,
                                                'LocationType.Name': false,
                                                'ClassificationName': false,
                                                'SalesRep.Username': false,
                                            }
                                        }
                                    },
                                    ...commonGridOptions,
                                    rowCount: data?.totalCount,
                                    experimentalFeatures: { newEditingApi: true },
                                    processRowUpdate: () => processRowUpdate(),
                                    onCellDoubleClick: () => onAlertClick()
                                }} />
                        </Grid>
                        <Grid item md={12} sm={12} lg={6} >
                            <div style={mapContainer}>
                                {data?.alertList ?
                                    <AppCalendar
                                        data={data}
                                        filterModel={filterModel}
                                        setFilterModel={setFilterModel}
                                        setFilterReady={setFilterReady}
                                        defaultState={defaultState}
                                        setDefaultState={setDefaultState} /> :
                                    <div className="text-center pt-5">{t('Calendar', tOpts)} {t('Loading...', tOpts)}</div>}
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    }

    if (isOutletCard) {
        return <>
            <GridBase
                clearFilter={clearFilter}
                customClass={'mt-2 data-grid-font-12'}
                columns={gridColumn}
                data={data?.alertList}
                rowsPerPage={[10, 20, 50, 100]}
                movePagination={false}
                autoHeight={false}
                onSortModelChange={onFilterModelChange}
                showTitleColumn={true}
                otherOptions={{
                    ...commonGridOptions,
                    rowCount: data?.totalCount,
                }} />
        </>
    }

    return <>
        <Card className={`mb-1 p-0  ${!isMobile.mobile ? "mt-2 " : (isMobile.portrait ? "mt-4" : "mt-2")}`} >
            <CardContent className="m-0 p-0">
                <Tabs value={activeTab.current} onChange={handleChangeTab} centered variant="fullWidth">
                    {tabList.map(tab => {
                        return <Tab
                            icon={tab.icon ? <img src={require(`../../assets/images/${tab.icon}`)} height={tab.iconSize} alt="" /> : null}
                            iconPosition={tab.iconPosition}
                            label={t(tab.label, tOpts)} />
                    })}
                </Tabs>
            </CardContent>
        </Card>

        <Grid container spacing={1}>
            {kpiCards.map((item) => {
                return (<>
                    <Grid item xs={12} sm={4} md={4} lg={3} spacing={2} >
                        {item.key ?
                            <Card className={"mt-2 hover-cursor-pointer" + (cardFilters === item.key ? ' selected-Card' : '')}
                                onClick={() => {
                                    if (cardFilters === item.key) return;
                                    item.filterCall({ setFilters, setCardFilters, getHistoryData, alertStatus, clearFilterModal, clientUserId: ClientUserId, setOldFilters })
                                }} >
                                <CardContent className="p-3">
                                    <Grid container spacing={1}>
                                        <Grid item xs={2} sm={2} md={2} lg={2} >
                                            <img src={require(`../../../src/assets/images/${item.icon}`)} height="40" alt="png Icon" />
                                        </Grid>
                                        <Grid className="pl-3 pt-2 pb-0 text-center alert-card-height-overrides" item xs={8} sm={8} md={8} lg={8} >
                                            <p className="m-0 pt-2">{t(item.label, tOpts)}</p>
                                        </Grid>
                                        <Grid className="text-center pt-2 pb-0" item xs={2} sm={2} md={2} lg={2} >
                                            <p className="m-0 pt-2" style={{ color: `${item.textColor}` }}><b>{kpiValues[item.key] === undefined ? '-' : kpiValues[item.key]}</b></p>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card> : ""}
                    </Grid>
                </>)
            })}
        </Grid>

        <>
            {activeTab.current === 0 ? alertOverviewTable() :
                activeTab.current === 1 ? alertOverviewMap() :
                    activeTab.current === 2 ? alertOverviewCalendar() : ""}
        </>

        {outletCard.modal && <OutletCard outletCard={outletCard} setOutletCard={setOutletCard} />}
    </>
}

export default AlertOverview;