import React, { useState, useCallback, memo, useMemo, useEffect } from "react";
import { request, apis } from "../../httpUtil/";
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Grid, Container, FormControlLabel, Checkbox, Card, CardContent, Button } from "@material-ui/core";
import UserInput from './UserInput';
import ClientSelect from './ClientSelect';
import UserPassword from './UserPassword';
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector, } from 'react-redux';
import actions from '../../redux/actions'
import utils from "../../utils";
import ChangeLanguage from "../../components/ChangeLanguage";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { MODULE_TO_REMOVE } from "../../constants";
import constants from "../../utils/constants";
import LoginOTP from "./LoginOTP";

const isMobile = utils.isMobile()?.length;
const t = utils.t;
const subDomain = utils.getSubdomain();
const isExistImage = () => {
  try {
    return require(`../../assets/images/login-images/${subDomain}.png`);
  } catch (err) {
    return require("../../assets/images/login-images/coolrLogo.png");
  }
}
const Logo = isExistImage();
const MAX_LENGTH_OTP = 6;



const Login = ({ history }) => {
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState("");
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const [alertStatus, setAlertStatus] = useState({ open: false });
  const userEmail = useSelector(state => state.appReducer.userEmail);
  const userPassword = useSelector(state => state.appReducer.userPassword);
  const selectClient = useSelector(state => state.appReducer.selectClient);
  const clientsList = useSelector(state => state.appReducer.clientsList);
  const loginOtp = useSelector(state => state.appReducer.loginOtp);
  const [modelOpen, setModelOpen] = useState(undefined);
  const [popUp, showPopUp] = useState(undefined);
  const [enableLoginBtn, setEnableLoginBtn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const [timer, setTimer] = useState(utils.forgotPasswordTimeInSeconds);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const location = useLocation();
  const locale = useSelector(state => state.appReducer.locale);

  const handleForgorPasswordModal = () => {
    history.push('/forgot-password');
  };
  const clearSelectedClient = () => {
    dispatch({ type: actions.SET_CLIENT_LIST_DATA, clientsList: [] });
    dispatch({ type: actions.SET_CLIENT_SELECT, selectClient: 0 });
  }

  const memoizedBackButton = useMemo(() => <Button type="submit" className="login-back-btn f-f-ebrima" onClick={clearSelectedClient} color="primary" variant="contained" size="large" >{t("BACK", tOpts)}</Button>, [translate, clientsList])
  const memoizedLogin = useMemo(() => <Button disabled={!enableLoginBtn} type="submit" className={`f-f-ebrima ${enableLoginBtn ? 'login-btn' : 'login-disbaled'}`} color="primary" variant="contained" size="large"  >{t("LOGIN", tOpts)}</Button>, [enableLoginBtn, clientsList, translate, i18n])
  const memoizedCheckbox = useMemo(() => <FormControlLabel control={<Checkbox checked={!!rememberMe} onChange={event => setRememberMe(event.target.checked)} />} label={t("Remember me", tOpts)} />, [rememberMe, translate])
  const selectClientButton = useMemo(() => <Button disabled={selectClient === 0} type="submit" className={`my-2 f-f-ebrima ${selectClient !== 0 ? 'login-btn' : 'login-disbaled'}`} color="primary" variant="contained" size="large"  >{t("LOGIN", tOpts)}</Button>, [selectClient, clientsList, translate, i18n])

  const memoizedAlertTitle = useMemo(() => <AlertTitle>{t("Error", tOpts)}</AlertTitle>, [translate])

  useEffect(() => {
    if (location && location?.state?.ScheduledOrderId) {
      if (constants.portugalFranceClientIds.includes(location?.state?.ClientId)) {
        const language = constants.portugalFranceClientIdsWithLanguages[Number(location?.state?.ClientId)];
        if (locale !== language) {
          i18n.changeLanguage(language);
          dispatch({ type: actions.SET_CURRENT_LOCALE, locale: language });
        }
      }
      showPopUp(true);
      swal({
        title: t("Info", tOpts),
        text: t(location.state.message, tOpts),
        icon: "info",
        closeOnClickOutside: false, closeOnEsc: false, buttons: false
      })
      return;
    }
  }, [location]);

  useEffect(() => {
   if (userEmail) {
      setEnableLoginBtn(true)
    } else {
      setEnableLoginBtn(false)
    }
    if(showOtpField && loginOtp && loginOtp.length === MAX_LENGTH_OTP){
      setEnableLoginBtn(true);
    }
    else if(showOtpField && (!loginOtp || loginOtp.length < MAX_LENGTH_OTP)){
      setEnableLoginBtn(false);
    }
    else if (showPassword && !userPassword){
      setEnableLoginBtn(false);
    }
  }, [userEmail, userPassword, loginOtp]);

  // Request to API for Login
  const processLogin = useCallback(
    async (params, event) => {
      if (event) event.preventDefault();
      const response = await request({ url: apis.login, params, history, dispatch });
      if (response?.success) {
        setAlertStatus({ error: "", open: false });
        if(response.showOtpField){
          swal({ title: '', text: t(response.message, tOpts), icon: "info", className: "swal-login" });
          setShowOtpField(true);
          setShowPassword(false);
          dispatch({ type: actions.SET_USER_PASSWORD, userPassword: null });
          setEnableLoginBtn(false);
        }
        else if(response.showPasswordField){
          setShowPassword(true);
          setShowOtpField(false);
          dispatch({ type: actions.SET_USER_LOGINOTP, loginOtp: null });
        }
        else if (response.showClientCombo) {
          if (response.RedirectUrl) {
            window.location.href = response.RedirectUrl;
          }
          dispatch({ type: actions.SET_CLIENT_LIST_DATA, clientsList: response.clientsList });
        } else {
          if (selectClient && !response && !response.userId) {
            utils.reLogin(history)
          } else {
            const { SalesLevelId: salesLevelId = 0 } = response.tags;
            if (MODULE_TO_REMOVE[salesLevelId]) {
              for (const module of MODULE_TO_REMOVE[salesLevelId]) {
                delete response.modules[module];
              }
            }
            let userRoutes = utils.getAssignedRoutes(response)
            if (userRoutes.length) {
              dispatch({ type: actions.SET_USER_DATA, userData: response, clientId: selectClient });
              dispatch({ type: actions.SET_USER_LOGOUT, userLogout: false });
              utils.getComboData(history, response);
              history.push(userRoutes[0]);
            } else {
              if (response.RedirectUrl)
                window.location.href = response.RedirectUrl;
              setAlertStatus({ error: "You do not have Permissions to View anything on Dashboard, Contact Admin", open: true });
            }
          }
        }
      } else if (params) {
        setAlertStatus({ error: response?.message, open: true });
        dispatch({ type: actions.SET_CLIENT_LIST_DATA, clientsList: [] });
        dispatch({ type: actions.SET_CLIENT_SELECT, selectClient: 0 });
      }
    }, [history, dispatch, selectClient]);

  // On Login Click check and validate values
  const onLogin = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    if (!userEmail || (showPassword && !userPassword)) {
      return
    }
    if (!userEmail) {
      setAlertStatus({ error: "Email is required.", open: true });
      return;
    }
    if(userEmail && showOtpField && !loginOtp){
      setAlertStatus({ error: "OTP is required.", open: true });
      return;
    }

    const isFromDemoSite = window.location.hostname?.toLowerCase()?.includes('demo');
    let params = { Username: userEmail, RememberMe: rememberMe, isFromWeb: true, key: selectClient, isFromDashboard: true, isAuthenticatedFromSSO: false, isFromDemoSite };
    if(showOtpField && loginOtp){
      params["LoginOtp"] = loginOtp;
    }
    if(showPassword && userPassword){
      params["Password"] = userPassword;
    }
    if ((clientsList && clientsList.length) && selectClient === 0) {
      return;
    }
    processLogin(params, event);
  }, [setAlertStatus, processLogin, userEmail, userPassword, clientsList, rememberMe, selectClient, t, showOtpField, loginOtp])
  const userData = useSelector(state => state.appReducer.userData);
  const userRoutes = userData ? utils.getAssignedRoutes(userData) : []
  let isLoggedIn = userData && userData.message && userData.message === "Logged in";

  useEffect(() => {
    if (isLoggedIn) {
      history.push(userRoutes[0]);
    }
    else {
      console.warn("User not logged in");
    }
  }, [userData])

  const handleClose = () => setAlertStatus({ error: "", open: false });

  const errorPopUp = () => {
    swal({
      title: '',
      text: t(alertStatus.error, tOpts),
      icon: "warning",
      dangerMode: true,
      className: "swal-login",
    })
    setAlertStatus({ error: "", open: false });
  };
  const handleResendClick = () => {
    if (timer > 0) {
      return
    }
    setIsResendDisabled(true);
    setTimer(utils.forgotPasswordTimeInSeconds);
    const isFromDemoSite = window.location.hostname?.toLowerCase()?.includes('demo');
    processLogin({ Username: userEmail, isFromWeb: true, isFromDashboard: true, isFromDemoSite });
  };
  useEffect(() => {
    let interval;
    // Update the timer every second
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }
    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [timer]);

  return (
    !popUp && <div className="app-wrapper min-vh-100">

      <div className="app-main app-main-align app-bg flex-column">
        <div className="bg-overlay">
        </div>
        <div className={clsx("app-content  p-0 min-vh-100", {
          'w-100': isMobile
        })}>
          <div className="app-content--inner d-flex align-items-center">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content" >
                <Container className='login-container login-container-page-confidential-box'>
                  <Grid container className="login-container w-100">
                    <Card className="m-0 w-100 p-0 border-0" style={{ borderRadius: '20px' }}>
                      <CardContent className="p-3">
                        <div className=" d-flex justify-content-center  w-100 mt-4" >
                          <img alt="..." className={`logo-img ${clientsList && clientsList.length != 0 ? "mb-4" : 'mb-3'}`} src={Logo} />
                        </div>
                        {
                          ((clientsList && clientsList.length <= 0) || typeof clientsList == 'undefined') &&
                          <div className="language-select-login-page">
                            <ChangeLanguage />
                          </div>
                        }
                        <form className={`${isMobile ? '' : "px-5"}`} onSubmit={(e) => onLogin(e)}>
                          {((clientsList && clientsList?.length <= 0) || typeof clientsList == 'undefined') &&
                            <>
                              <div>
                                <UserInput disabled={showOtpField || showPassword} />
                              </div>
                              {showPassword && <div>
                                <UserPassword />
                              </div>}
                              {showOtpField && <div>
                                <LoginOTP />
                                <div className="d-flex justify-content-end mt-1">
                                  <p className="f-f-ebrima">{t(`Haven’t received?`, tOpts)}</p>
                                  {isResendDisabled &&
                                      <span style={{ color: 'blue' }} className="f-f-ebrima ml-1">{t('Resend in', tOpts)}{timer >= 1 ? ` ${timer}s` : ''}</span>
                                  }
                                  {!isResendDisabled &&
                                      <span className="f-f-ebrima ml-1 forgot-pass" style={{ color: 'blue' }} onClick={handleResendClick}>
                                          {t(`Resend Now`, tOpts)}
                                      </span>
                                  }
                              </div>
                              </div>}
                              {
                                showPassword && ((clientsList && clientsList.length <= 0) || typeof clientsList == 'undefined') &&
                                <div className="d-flex justify-content-end">
                                  <span onClick={() => handleForgorPasswordModal()} className="forgot-pass" > {t("Forgot Password", tOpts) + "?"}</span>
                                </div>
                              }

                            </>
                          }
                          <ClientSelect />
                          <div className="w-100 d-flex-justify-sb-align-c">
                            {memoizedCheckbox}
                          </div>

                          <div className="text-center mt-2">
                            <div >
                              {
                                ((clientsList && clientsList.length <= 0) || typeof clientsList == 'undefined') &&
                                memoizedLogin
                              }
                              {
                                !((clientsList && clientsList.length <= 0) || typeof clientsList == 'undefined') &&
                                selectClientButton
                              }

                            </div>
                            <div>
                              {clientsList && clientsList.length ? memoizedBackButton : null}
                            </div>
                          </div>
                          {alertStatus.open ?
                            errorPopUp()
                            : null}
                        </form>
                      </CardContent>
                    </Card>
                    <Card className="confidential-notice-message-loginpage">
                      <div>
                        <h2 className="confidential-notice-heading">{t("Confidentiality Notice:", tOpts)}</h2>
                        <span className="confidential-notice-message">{t("This website and its contents are intended solely for the use of the individual or entity to whom it is addressed and may contain confidential or privileged information. Any unauthorized review, use, disclosure, or distribution of the information contained herein is strictly prohibited. If you have received this website in error, please notify the sender immediately and delete the website and any attachments from your system. Unauthorized access to or use of this website may violate applicable laws and could result in civil and/or criminal penalties. Thank you for your cooperation in maintaining the confidentiality of this information.", tOpts)}</span>
                      </div>
                    </Card>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default memo(withRouter(Login));
