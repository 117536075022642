import React, { useState, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import { useGridApiRef, DataGridPremium, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from "@mui/x-data-grid-premium";
import { apis, request } from '../../httpUtil';
import { useHistory } from 'react-router-dom';
import addIcon from '../../assets/images/add.svg';
import utils from '../../utils';
import { useDispatch, useSelector } from "react-redux";
import useMobile from '../../utils/useMobile';
import { useTranslation } from 'react-i18next';

const t = utils.t;

const GridBase = (props) => {
    const { columns,
        data,
        movePagination,
        customClass = '',
        otherOptions = {},
        gridRef = null,
    } = props;

    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [paginationClass, setPaginationClass] = useState("");
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const gridRefLocal = useGridApiRef();
    const apiRef = gridRef || gridRefLocal;
    const locale = useSelector(state => state.appReducer.locale);

    useEffect(() => {
        if (!columns) {
            return;
        }
        if (columns.length > 0) {
            const getColumns = columns.map((column) => {
                const { id, field, flex } = column;

                let colObject = column;

                colObject = { ...colObject, field: field || id, flex: flex === undefined ? 1 : flex, }
                return colObject;
            });
            setGridColumns(getColumns);
        }
        if (movePagination) {
            setPaginationClass("move-pagination-right");
        }
    }, [columns, movePagination, locale])

    useEffect(() => {
        if (!data) {
            return;
        }
        if (data && Array.isArray(data) && data.length) {
            const getRows = data.map(row => {
                let rowId;
                const objKeys = Object.keys(row)
                objKeys.map((obj, index) => {
                    row[obj] = Object.values(row)[index]
                })
                if (row.id) {
                    return row;
                } else {
                    rowId = utils.generateUUID();
                    return { id: rowId, ...row }
                }
            })
            setGridRows(getRows);
        } else {
            setGridRows([]);
        }

    }, [data]);

    return (
        <>
            <DataGridPremium
                className={paginationClass + `data-grid-base ${customClass}`}
                columns={gridColumns}
                rows={gridRows}
                hideFooter={true}
                pagination={false}
                sx={{ height: '414px' }}
                disableColumnFilter={true}
                disableColumnMenu={true}
                rowSelection={false}
                disableSelectionOnClick
                {...otherOptions}
                apiRef={apiRef}

            />
        </>
    );
};

function ExpandedRowOrderingTab(props) {
    const { data = [], onAddItem, t, tOpts ,row, filterValues = { clientIds: [] }, multiClientFilters } = props;
    const isMobile = useMobile();
    function formatCurrency(number, currency) {
        const formattedNumber = number.toFixed(2);
        return currency?.replace('{0}', formattedNumber);
    }
    let mobile = isMobile.mobile
    const expandedColumns = [
        { id: 'ProductName', field: 'ProductName', headerName: t('Product Name',tOpts), width: mobile ? 165 : 250, aggregable: false, groupable: false, filterable: false, sortable: false, flex: 0, align: 'left' },
        { id: 'CaseSize', field: 'CaseSize', headerName: t('Case Size',tOpts), width: mobile ? 75 : 90, aggregable: false, groupable: false, filterable: false, sortable: false, flex: 0, align: 'right' },
        { id: 'UnitsNeeded', field: 'UnitsNeeded', headerName: t('Qty',tOpts), aggregable: true, flex: 1, align: 'right', width: mobile ? 45 : 60, filterable: false, sortable: false, valueGetter: (value) => Number(value.row.UnitsNeeded || '0'), type: "number" },
        {
            id: 'Amount', field: 'Amount', headerName: t('Value',tOpts), width: 70, aggregable: true, flex: 1, align: 'right', filterable: false, sortable: false, justifyContent: 'flex-end', type: "number", valueGetter: (value) => Number(value.row.Amount || '0'), valueFormatter: (item) => {
                return formatCurrency(item.value, row.Currency);
            }
        },
    ];
    const history = useHistory();
    const dispatch = useDispatch();
    const [productId, setProductId] = useState(0);
    const [assetLocation, setAssetLocation] = useState([])

    const gridRef = useGridApiRef();

    const uniqueRows = [];

    data.forEach(row => {
        const { ProductName, CaseSize } = row;
        const existingRow = uniqueRows.find(r => r.ProductName === ProductName && r.CaseSize === CaseSize);

        if (existingRow) {
            existingRow.UnitPrice += row.UnitPrice;
            existingRow.UnitsNeeded += row.UnitsNeeded;
            existingRow.Amount += row.Amount;
        } else {
            uniqueRows.push({
                ...row,
                Amount: row.UnitPrice * row.UnitsNeeded
            });
        }
    });

    const sortedData = uniqueRows.sort(sortData).map((data, index) => {
        return {
            ...data,
            hierarchy: [data.ProductCategoryName ? data.ProductCategoryName : 'No Category', `${data.ProductName} (${data.CaseSize} pack)`],
            rowIndex: index
        }
    });

    useEffect(() => {
        setAssetLocation([]);
    }, [row])

    useEffect(() => {
        if (productId) {
            document.getElementsByName("productQty")[0] && document.getElementsByName("productQty")[0].focus();
        }
    }, [productId])

    function sortData(objF, ObjS) {
        const first = Number(objF.UnitsNeeded) * Number(objF.UnitPrice), second = Number(ObjS.UnitsNeeded) * Number(ObjS.UnitPrice);
        if (first < second) {
            return 1;
        }
        if (first > second) {
            return -1;
        }
        return 0;
    }

    return (
        <div>
            <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <GridBase
                        applyPreferences={false}
                        customClass={'mt-2 py-2 data-grid-font-12 bordered-data-grid'}
                        columns={expandedColumns}
                        data={sortedData}
                        showTitleColumn={true}
                        gridRef={gridRef}
                        pagination={false}
                        hideFooter={true}
                        disableColumnSelector={true}
                        otherOptions={{
                            showCustomToolbar: false,
                            initialState: {
                                aggregation: {
                                    model: {
                                        UnitsNeeded: "sum",
                                        Amount: "sum"
                                    }
                                }
                            },
                            treeData: false,
                        }}
                    />
                </Grid>

            </Grid>
            {
                onAddItem && <Grid item xs={2} sm={2} md={2} lg={2} >
                    <Card className="expand-item-center " style={{ minHeight: '60px' }}>
                        <img src={addIcon} alt='Add' height="18" />
                        <span className="add-item" onClick={onAddItem} >{t("ADD ITEM", tOpts)}</span>
                    </Card>
                </Grid>
            }
        </div >
    )
}

export { ExpandedRowOrderingTab, GridBase };