import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Box,
  Typography,
  Paper,
} from "@mui/material";
import { FaCamera } from "react-icons/fa";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilitySensor from "react-visibility-sensor";
import { useTranslation } from "react-i18next";
import { BiQr } from "react-icons/bi";
import { request, apis } from "../../httpUtil";
import actions from "../../redux/actions";
import template from "./template";
import dbUtil from "./dbUtil";
import PageTitle from "../../components/PageTitle";
import { PageIcon } from "../../utils/Icons";
import DialogModal from "../../components/Common/DialogModel";
import Loader from "../../components/Dialog/Loader";
import { AutoSizer, CellMeasurerCache, List } from "react-virtualized";
import VistaDetailCard from "../../components/VistaLocationDetailsCardList/VistaDetailCard";
import utils from "../../utils";
import { useMedia } from "the-platform";
import dayjs from "dayjs";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import useMobile from "../../utils/useMobile";
import { addRepeatedQuestions } from "./addRepeatedQuestions";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers-pro";

const guidLength = 36;
const AssessmentType = {
  Radio: "radio",
  Checkbox: "checkbox",
  Text: "text",
  Textarea: "textarea",
  Select: "select",
  File: "file",
  MultiFile: "multiFile",
  Number: "number",
  barcode: "barcode",
  Scene: "scene",
  MultiFileStitch: "multiFileStitch",
  date: "date",
};

const OTHER_OPTION = "Other";

const cache = new CellMeasurerCache({
  defaultHeight: 550,
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 400,
});

export default function RenderSurveyAnswers({
  jsonForPreview,
  fromDashboard = false,
  SurveyIdFromDashboard,fromSurveyDashboard
}) {
  let dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  const t = utils.t;
  const { t: translate, i18n } = useTranslation();
  const tOpts = { t: translate, i18n };
  const { SurveyId = SurveyIdFromDashboard } = useParams();
  const [readOnlyView, setReadOnlyView] = useState(true);
  const [surveyTypeName, setSurveyTypeName] = useState(true);
  const [expanded, setExpanded] = useState();
  const [allSurveyData, setAllSurveyData] = useState();
  const [surveyData, setSurveyData] = useState({});
  const [typeQuestion, setTypeQuestion] = useState([]);
  const [answerData, setAnswerData] = useState();
  const [answerDataDictionary, setAnswerDataDictionary] = useState();
  const smallDevice = useMedia("(max-width: 400px)");
  const mediumDevice = useMedia({ minWidth: 401, maxWidth: 960 });
  const [showDetails, setShowDetails] = useState("");
  const [repeatCount, setRepeatCount] = useState({});
  const [repeatedJson, setRepeatedJson] = useState({});
  const [questionByAsset, setQuestionByAsset] = useState({});
  const [currentQuestionPivot, setCurrentPivot] = useState({
    questionId: "",
    assetId: "",
  });
  const [assets, setAssets] = useState({});
  const [products, setProducts] = useState({});
  const [asset_products, setAssetProducts] = useState({});
  const [store_products, setStoreProducts] = useState({});
  const userData = useSelector(state => state.appReducer.userData);
  const { ClientId } = userData?.tags || {};
  const redirectPath = location.pathname.split("/")[2] === "SurveyInbox" ? "/Survey/SurveyInbox" : '/Survey/Report';
  const isClientSelected = (ClientId && ClientId != 0);
  const isMobile = useMobile();
  const vistaData = useSelector(
    (state) => state.appReducer.vistaData,
    shallowEqual
  );
  const headerTitle = useSelector(
    (state) => state.appReducer.headerTitle,
    shallowEqual
  );
  const openVistaDetail = useSelector(
    (state) => state.appReducer.openVistaDetail
  );

  let { recordCount: vistaDataRecordCount, records: vistaDataRecords } =
    vistaData;

  let count = 0;
  /*  Temporary Check for SurveyWIP card and old Survey Card */

  const openPopUp = (e, index = 0, self = [], url) => {
    const { src } = e.target;
    if (self.length > 1) {
      self = self.map((value) => {
        return { src: `${url.replace("@id", value)}&width=1000&height=1000` };
      });
      dispatch({
        type: "SET_VISTA_IMAGE_POPUP",
        vistaPopupData: {
          open: true,
          isMultiImages: true,
          url: self,
          useSingleDisplay: true,
          showIndex: index,
        },
      });
      return;
    }
    dispatch({
      type: "SET_VISTA_IMAGE_POPUP",
      vistaPopupData: { open: true, url: `${src}&width=1000&height=1000` },
    });
  };

  const createDictionary = (answerData = []) => {
    const data = {};
    answerData.forEach((item) => {
      data[item.questionNumber] = item.value !== "";
    });
    return data;
  };

  const replaceProductIdByIndex = (json, products, assetsAndProducts) => {
    products = Array.from(products);
    if (!products.length) {
      return json;
    }

    for (const item of json) {
      let splittedId = "";
      if (item.questionNumber.includes(`~${utils.PREFIX_FOR_PRODUCT_ID}`)) {
        splittedId = item.questionNumber.split(
          `~${utils.PREFIX_FOR_PRODUCT_ID}`
        );
        const currentProductId = splittedId.pop();
        const assetPart = splittedId[0];
        const currentAssetId = assetPart
          .split(`~${utils.PREFIX_FOR_ASSET_ID}`)
          .pop();
        const repeatCount = Number(
          assetsAndProducts[currentAssetId]?.[currentProductId] || 0
        );
        if (repeatCount === 0) {
          item.questionNumber = splittedId + `~${utils.PREFIX_FOR_PRODUCT_ID}`;
          item.name = item.questionNumber;
        } else if (repeatCount !== -1) {
          item.questionNumber =
            splittedId +
            `~${utils.PREFIX_FOR_PRODUCT_ID}` +
            utils.USER_REPEAT_COUNT_SEPARATOR +
            repeatCount;
          item.name = item.questionNumber;
        }
      } else {
        splittedId = item.questionNumber.split("~");
        const currentProductId = splittedId.pop();
        const repeatCount = products.findIndex(
          (id) => id.trim() === currentProductId.trim()
        );
        if (repeatCount === 0) {
          item.questionNumber = splittedId.join("~");
          item.name = item.questionNumber;
        } else if (repeatCount !== -1) {
          item.questionNumber =
            splittedId.join("~") +
            utils.USER_REPEAT_COUNT_SEPARATOR +
            repeatCount;
          item.name = item.questionNumber;
        }
      }
    }
    return json;
  };

  const getSurveyTypeData = async ({forPreview, SurveyTypeId}) => {
    if (forPreview) {
      dbUtil.set("surveyType", forPreview);
      return;
    }
    let params = { action: "list", asArray: 0, start: 0, limit: 10,  filter: {"fieldName":"SurveyTypeId","operatorId":"NUMBER_EQUAL","convert":false,"values":[SurveyTypeId]} };
    const response = await request({
      url: apis.SurveyType,
      params,
      history,
      dispatch,
    });
    if (response && response.records) {
      await dbUtil.set("surveyType", response);
    }
  };

  const importSurveyFile = async (surveyTypeId) => {
    const surveyTypeData = (await dbUtil.get("surveyType")) || {};
    if (
      surveyTypeData &&
      (Object.entries(surveyTypeData).length === 0 ||
        !surveyTypeData.recordCount)
    ) {
      return require("./1.json");
    }
    const surveyTypeIndex = surveyTypeData.records.findIndex(
      (e) => e.SurveyTypeId == Number(surveyTypeId)
    );
    if (surveyTypeIndex < 0) {
      return require("./1.json");
    } else {
      const { SurveyQuestions, SurveyTypeName } = surveyTypeData.records[surveyTypeIndex];
      setSurveyTypeName(SurveyTypeName)
      if (SurveyQuestions) {
        return JSON.parse(SurveyQuestions);
      } else {
        return require("./1.json");
      }
    }
  };

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const mergeProducts = (parentProducts, childProducts) => {
    // if two same product are on planogram it will return the single entry
    const mergedProducts = [...parentProducts, ...childProducts];
    const uniqueProducts = Array.from(
      new Set(mergedProducts.map(JSON.stringify)),
      JSON.parse
    );
    return uniqueProducts;
  };

  const getParentAssetsWithCombinedProducts = (assets) => {
    var parentAssets = [],
      rowNumber = 0;
    // We will show only parent assetInfo, if the product are there then will show combine info
    for (const asset of assets) {
      if (asset.ParentAssetId > 0) {
        const parentAsset = assets.find(
          (a) => a.AssetId === asset.ParentAssetId
        );
        if (parentAsset) {
          if (parentAsset.Products && asset.Products) {
            const parentProducts = isJson(parentAsset.Products)
              ? JSON.parse(parentAsset.Products)
              : isJson(parentAsset.Products + '"}]')
                ? JSON.parse(parentAsset.Products + '"}]')
                : parentAsset.Products;
            const childProducts = isJson(asset.Products)
              ? JSON.parse(asset.Products)
              : isJson(asset.Products + '"}]')
                ? JSON.parse(asset.Products + '"}]')
                : asset.Products;
            parentAsset.Products = JSON.stringify(
              mergeProducts(parentProducts, childProducts)
            );
          }
          // if the entry already there need to remove and add the new entry
          parentAssets = parentAssets.filter(
            (item) => item.AssetId !== parentAsset.AssetId
          );
          parentAssets.push(parentAsset);
        }
      } else {
        var index = parentAssets.findIndex((obj) => {
          return obj.AssetId === asset.AssetId;
        });
        if (index < 0) {
          parentAssets.push(asset);
        }
      }
    }
    parentAssets.forEach((asset, index) => {
      asset.RowNumber = index + 1;
    });
    return parentAssets;
  };

  const checkRemoveProductIdFromQuestion = (json) => {
    if (!json?.sections) return;

    const replaceId = (id, isAsset) => {
      if (isAsset) {
        return id.replace(
          "${item.AssetId}",
          utils.PREFIX_FOR_ASSET_ID + "${item.AssetId}"
        );
      } else {
        return id.replace(
          "${item.ProductId}",
          utils.PREFIX_FOR_PRODUCT_ID + "${item.ProductId}"
        );
      }
    };

    const processQuestion = (question, isAsset) => {
      if (isAsset) {
        if (/AssetId/.test(question.id)) {
          question.id = replaceId(question.id, true);
        }
        if (
          /ProductId/.test(question.id) &&
          !/P\${item\.ProductId}/.test(question.id)
        ) {
          question.id = replaceId(question.id, false);
        }
      } else {
        if (/ProductId/.test(question.id)) {
          question.id = replaceId(question.id, false);
        }
        if (
          /AssetId/.test(question.id) &&
          !/A\${item\.AssetId}/.test(question.id)
        ) {
          question.id = replaceId(question.id, true);
        }
      }

      if (question.dependentQuestion) {
        for (const dependent of question.dependentQuestion) {
          if (/ProductId/.test(dependent.question)) {
            dependent.question = replaceId(dependent.question, false);
          }
          if (
            /AssetId/.test(dependent.question) &&
            !/A\${item\.AssetId}/.test(dependent.question)
          ) {
            dependent.question = replaceId(dependent.question, true);
          }
        }
      }

      if (question.repeat) {
        for (const [pos, questId] of question.repeat.entries()) {
          if (/ProductId/.test(questId)) {
            let newId = replaceId(questId, false);
            if (
              /AssetId/.test(questId) &&
              !/A\${item\.AssetId}/.test(questId)
            ) {
              newId = replaceId(newId, true);
            }
            question.repeat.splice(pos, 1, newId);
          }
        }
      }
    };

    for (const section of json.sections) {
      const isAssets = section.items === 'assets';
      for (const question of section.questions) {
        processQuestion(question, isAssets);
      }
    }

    return json;
  }

  const runUpdate = async ({
    _surveyTypeId,
    _repeatCount,
    _repeatedJson,
    currentPivot,
  }) => {
    let { assetId: pivot } = currentPivot;
    let currentCount = 0;

    pivot = currentPivot;
    currentCount = _repeatCount;

    let surveyJson = [],
      updatedJson = {},
      datajson = [],
      questionByAsset = {},
      typeObj = [],
      includeProductInfo = false,
      isRepeated = _repeatedJson && Object.keys(_repeatedJson).length,
      alerts = [],
      noListFoundOnDynamicOption = {};
    if (isRepeated) {
      datajson = _repeatedJson["sections"];
    } else {

      surveyJson = await importSurveyFile(_surveyTypeId);

      if (!surveyJson) {
        return false;
      } else {
        surveyJson = checkRemoveProductIdFromQuestion(surveyJson);
      }

      let typeQuestionArr = typeQuestion || [];
      typeObj = [...typeQuestionArr];

      const survey = JSON.stringify(surveyJson);
      const newSurvey = JSON.parse(survey);

      let repetitionItemType;

      if (!isRepeated) {
        for (const section of [...newSurvey.sections]) {
          repetitionItemType = section.items;
          if (typeof section.items === "string") {
            const records = await dbUtil.get(section.items); // Asset Data
            if (records?.records?.length && section.items === 'assets') {
              let data = getParentAssetsWithCombinedProducts(records.records);
              records.records = data;
            }
            section.items = records?.records || [];
          }
          const origSection = JSON.stringify(section);
          if (section.includeProductInfo) {
            includeProductInfo = true;
          }

          for (const item of [...section.items]) {
            const newSection = JSON.parse(origSection);
            newSection.title = template.replaceTags(newSection.title, item);
            newSection.hintImage = newSection.hintImage
              ? loadPlanogramImage(item.AssetId)
              : false;
            newSection.planogramName = item.PlanogramName || "";
            let sectionId = template.replaceTags(newSection.id, {
              section: newSection,
              item,
            }); // At section level 'item' should not be added to tags. E.g: ${item.AssetId} should be {AssetId} | TODO:  change this line if json convention is changed to only {AssetId}
            newSection.id = `${sectionId}~${item.RowNumber}`;
            newSection.assetId = item.AssetId;
            questionByAsset[newSection.id] = [];
            for (const q of newSection.questions) {
              q.text = template.replaceTags(q.text, item);
              q.id = template.replaceTags(q.id, { section: newSection, item });
              q.name = `${sectionId}.${q.id}${item ? "~" + item.RowNumber : ""
                }`;
              q.hintImage = q.hintImage
                ? loadPlanogramImage(item.AssetId)
                : false;
              q.planogramName = item.PlanogramName || "";
              q.SerialNumber = item.SerialNumber;
              if (SurveyId) {
                delete q.defaultDisplay;
              }
              switch (repetitionItemType) {
                case "assets":
                  q.repetitionItemType = utils.REPETITION_TYPES.Asset;
                  q.repetitionItemId = item.AssetId;
                  break;
                case "products":
                  q.repetitionItemType = utils.REPETITION_TYPES.Product;
                  q.repetitionItemId = item.ProductId;
                  break;
                case "promotions":
                  q.repetitionItemType = utils.REPETITION_TYPES.Promotion;
                  q.repetitionItemId = item.PromotionId;
                  break;
                default:
                  q.repetitionItemType = repetitionItemType;
                  break;
              }
              const { dynamicOptions } = q;
              if (dynamicOptions) {
                const qOptions = [];
                const qOptionItems = isJson(item[dynamicOptions.items])
                  ? JSON.parse(item[dynamicOptions.items])
                  : item[dynamicOptions.items];
                const optionLayout = JSON.stringify(dynamicOptions.layout);
                if (qOptionItems) {
                  for (const qOptionItem of qOptionItems) {
                    const qOption = JSON.parse(optionLayout);
                    qOption.type = qOption?.value.split("{")[1]?.split("}")[0];
                    qOption.key = template.replaceTags(
                      qOption.key,
                      qOptionItem
                    );
                    qOption.value = template.replaceTags(
                      qOption.value,
                      qOptionItem
                    );
                    qOptions.push(qOption);
                  }
                  qOptions.push({
                    type: qOptions[0]?.type || "",
                    key: qOptionItems.length + 2,
                    value: "Other",
                    isNotListed: true,
                  }); // add statically the label other
                }
                if (qOptions.length === 0) {
                  const qOption = JSON.parse(optionLayout);
                  const type = qOption?.value?.split("{")[1]?.split("}")[0];
                  noListFoundOnDynamicOption = {
                    ...noListFoundOnDynamicOption,
                    [q.id]: { type: type, sectionId: newSection.id },
                  };
                } else {
                  q.options = qOptions;
                }
              }
              if (q.dependentQuestion) {
                for (const dependent of q.dependentQuestion) {
                  dependent.question = template.replaceTags(
                    dependent.question,
                    {
                      section: newSection,
                      item,
                    }
                  );
                }
              }
              if (q.repeat) {
                const newRepeatArr = [];
                for (const questionToRepeat of q.repeat) {
                  newRepeatArr.push(
                    template.replaceTags(questionToRepeat, {
                      section: newSection,
                      item,
                    })
                  );
                }
                q.repeat = newRepeatArr;
              }

              questionByAsset[newSection.id].push(q);
            }
            datajson.push(newSection);
          }
        }
      }
    }

    let sectionCount = 0;
    for (const section of datajson) {
      typeObj.push({
        title: section.title,
        id: section.id,
        hintImage: section.hintImage,
        planogramName: section.planogramName,
      });
      if (!pivot) {
        //while taking the survey and have not repeated
        if (!updatedJson["sections"]) {
          updatedJson["sections"] = [];
        }
        updatedJson["sections"][sectionCount] = section;
        sectionCount++;
        continue;
      }
      let sectionIncludePivot = false;
      [section.assetId, section.id].forEach((id) => {
        if (sectionIncludePivot || id?.toString().includes(pivot)) {
          sectionIncludePivot = true;
          return;
        }
      });

      if (section.includeProductInfo === undefined) {
        sectionIncludePivot = false
      }

      if (sectionIncludePivot) {
        //addRepeatedQuestions is expecting json sections array, as quick fix will create it Just in Time
        const sections = [];
        sections.push(section);
        const justInTimeJson = { sections };

        if (currentCount > 0) {
          const { noListFound, questionJsonCopy: _repeatedJson } =
            addRepeatedQuestions(justInTimeJson, currentCount, SurveyId); // add repeated questions if any
          noListFoundOnDynamicOption = {
            ...noListFoundOnDynamicOption,
            ...noListFound,
          };
          datajson[sectionCount] = _repeatedJson && _repeatedJson["sections"][0];
          questionByAsset[section.id] = _repeatedJson &&
            _repeatedJson["sections"][0]["questions"];
          if (!updatedJson["sections"]) {
            updatedJson["sections"] = [];
          }
          updatedJson["sections"][sectionCount] = _repeatedJson && _repeatedJson["sections"][0];
        }
      } else {
        if (!updatedJson["sections"]) {
          updatedJson["sections"] = [];
        }
        updatedJson["sections"][sectionCount] = section;
      }
      sectionCount++;
    }

    return {
      typeObj,
      datajson,
      questionByAsset,
      tempJson: updatedJson || {},
      includeProductInfo,
      alerts,
      noListFoundOnDynamicOption: { ...noListFoundOnDynamicOption },
    };
  };

  const updateQuestionJson = async ({
    surveyTypeId,
    SurveyId,
    _repeatCount,
  }) => {
    let assets = await dbUtil.get("assets");

    let result,
      repeatCount,
      currentPivot = currentQuestionPivot;
    if (_repeatCount) {
      repeatCount = _repeatCount;
    }

    let repeatLimit,
      repeatAssets,
      isRepeated = true,
      isObjectBased = true;
    if (typeof repeatCount === "object" && Object.values(repeatCount).length) {
      repeatAssets = Object.keys(repeatCount).filter(key => repeatCount[key] !== 0 || Object.keys(repeatCount).length === 1);
      
      isRepeated = true;
    } else {
      isObjectBased = false;
      if (repeatCount > 0) {
        repeatLimit = repeatCount;
        repeatAssets = assets.records?.map((asset) => asset.AssetId);
      } else {
        isRepeated = false;
      }
    }

    if (isRepeated && repeatAssets) {
      let updatedJson = {};
      for (const asset of repeatAssets) {
        if (isObjectBased) {
          repeatLimit = repeatCount[asset];
        }
        for (
          let counter = repeatLimit === 0 ? 0 : 1;
          counter <= repeatLimit;
          counter++
        ) {
          currentPivot = asset;
          result = await runUpdate({
            _surveyTypeId: surveyTypeId,
            _repeatCount: counter,
            _repeatedJson: updatedJson,
            currentPivot,
          });
          updatedJson = result?.tempJson;
        }
      }
    } else {
      result = await runUpdate({
        _surveyTypeId: surveyTypeId,
        _repeatCount,
        _repeatedJson: repeatedJson,
        currentPivot,
      });
    }

    if (
      result?.datajson &&
      result?.typeObj &&
      result?.questionByAsset &&
      result?.tempJson
    ) {
      const questionByAssetOld = questionByAsset || {},
        assets = questionByAssetOld && Object.keys(questionByAssetOld);
      let newQuestionByAsset = {};
      if (assets.length) {
        assets.map((asset) => {
          if (result.questionByAsset[asset]) {
            newQuestionByAsset[asset] = result.questionByAsset[asset];
          } else {
            newQuestionByAsset[asset] = questionByAssetOld[asset];
          }
        });
      } else {
        newQuestionByAsset = result.questionByAsset;
      }

      const stateObj = {
        typeQuestion: result.typeObj,
        data: result.datajson,
        questionByAsset: newQuestionByAsset,
        repeatedJson: result.tempJson,
      };
      if (SurveyId > 0) {
        stateObj.repeatCount = -1;
        setRepeatCount(-1);
      }

      setTypeQuestion(result.typeObj);
      setQuestionByAsset(newQuestionByAsset);
      setRepeatedJson(result.tempJson);
      setAllSurveyData(result.datajson);
    }
  };

  const loadPlanogramImage = (AssetId) => {
    return `${apis.url}/Controllers/CoolerImagePreview.ashx?AssetId=${AssetId}&ImageType=PlanogramByAsset`;
  };

  const getSubmittedSurveyData = async (SurveyId) => {
    //Get Survey detail
    const response = await request({
      url: apis.Survey,
      params: { action: "load", id: SurveyId },
      history,
      dispatch,
    });

    const oldClientId = response?.data?.ClientId || 0;
    if (Number(ClientId) && Number(ClientId) !== oldClientId) {
        dispatch({
            type: actions.SET_PAGE_TITLE_DETAILS,
            pageTitleDetails: null,
        });
        if(!fromSurveyDashboard){
          history.push(redirectPath);
        }

    }
    if (response && response.success) {
      const answerDataTemp = response.data.SurveyData
        ? JSON.parse(response.data.SurveyData)
        : [];
        const SurveyTypeId = response?.data?.SurveyTypeId;

      let answerData = [];

      let uniqueProductIds = new Set(),
        surveyItemsIds = new Set(),
        repetitionType,
        assetInfo = {},
        productInfo = {},
        assetsAndProducts = {},
        repeatCount,
        assetsCount = {};
      for (const item of answerDataTemp) {
        if(item.answer !== ''){
          let obj = {
            attachment: item.attachment ? typeof (item.attachment) !== "number" && item.attachment.indexOf(",") > -1 ? item.attachment.split(',') : [item.attachment] : '',
            childName: undefined,
            ids: [],
            name: item?.SerialNumber === undefined && item?.questionId.includes("~") && item.questionId.split("~").pop() === 'A' ? item.questionId.split("~")[0] : item.questionId,
            questionNumber: item?.SerialNumber === undefined && item?.questionId.includes("~") && item.questionId.split("~").pop() === 'A' ? item.questionId.split("~")[0] : item.questionId,
            value: item.answer,
            optionId: item.optionId,
            options: item.options,
            questionId: item?.SerialNumber === undefined && item?.questionId.includes("~") && item.questionId.split("~").pop() === 'A' ? item.questionId.split("~")[0] : item.questionId,
            repetitionItemId: item.repetitionItemId,
            repetitionItemType: item.repetitionItemType
          }
  
          switch (Number(item.repetitionItemType)) {
            case utils.REPETITION_TYPES.Asset:
              surveyItemsIds.add(Number(item.repetitionItemId));
              repetitionType = Number(item.repetitionItemType);
              assetInfo[item.repetitionItemId] = {
                SerialNumber: item?.SerialNumber,
                AssetType: item?.AssetType,
              };
              break;
            case utils.REPETITION_TYPES.Product:
              surveyItemsIds.add(Number(item.repetitionItemId));
              repetitionType = Number(item.repetitionItemType);
              productInfo[item.repetitionItemId] = {
                Product: item?.Product,
                ProductId: item?.ProductId,
              };
              break;
            case utils.REPETITION_TYPES.AssetProduct:
              surveyItemsIds.add(Number(item.repetitionItemId));
              repetitionType = Number(item.repetitionItemType);
              productInfo[item.repetitionItemId] = {
                Product: item?.Product,
                ProductId: item?.ProductId,
              };
              break;
            case utils.REPETITION_TYPES.StoreProduct:
              surveyItemsIds.add(Number(item.repetitionItemId));
              repetitionType = Number(item.repetitionItemType);
              productInfo[item.repetitionItemId] = {
                Product: item?.Product,
                ProductId: item?.ProductId,
              };
              break;
            default:
              break;
          }
          if (item?.options?.length) {
            const otherId = item.options.findIndex(
              (option) => option.value === "Other"
            );
            if (otherId !== -1) {
              const otherComment = item?.options[otherId + 1]
                ? item?.options[otherId + 1]["value"]
                : "";
              obj.othercomment = otherComment;
              delete item?.options[otherId + 1];
            }
          }
  
          if (item.userRepeatType === "Products") {
            let productId = "";
            if (item.questionId.includes(`~${utils.PREFIX_FOR_PRODUCT_ID}`)) {
              productId = item.questionId
                .split(`~${utils.PREFIX_FOR_PRODUCT_ID}`)
                .pop();
            } else {
              productId = item.questionId.split("~").pop();
            }
            uniqueProductIds.add(productId);
            if (
              assetsAndProducts[item.repetitionItemId] &&
              !assetsAndProducts[item.repetitionItemId][productId]
            ) {
              assetsAndProducts[item.repetitionItemId][productId] =
                Number(assetsCount[item.repetitionItemId]) + 1;
              assetsCount[item.repetitionItemId] =
                Number(assetsCount[item.repetitionItemId]) + 1;
            } else if (!assetsAndProducts[item.repetitionItemId]) {
              if (item.repetitionItemId) {
                assetsAndProducts[item.repetitionItemId] = {};
                assetsAndProducts[item.repetitionItemId][productId] = "0";
                assetsCount[item.repetitionItemId] = "0";
              }
            }
          }
          answerData.push(obj);
        }

      }

      surveyItemsIds = Array.from(surveyItemsIds);
      //convert to item's array

      const repetitionArray = surveyItemsIds.map((item, key) => {
        const rowNumber = key + 1;
        const repetitionItem = { RowNumber: rowNumber };

        switch (repetitionType) {
          case utils.REPETITION_TYPES.Asset:
            repetitionItem.Type = "assets";
            repetitionItem.AssetId = item;
            repetitionItem.SerialNumber = assetInfo[item]?.SerialNumber || "";
            repetitionItem.AssetType = assetInfo[item]?.AssetType || "";
            return repetitionItem;
          case utils.REPETITION_TYPES.Product:
            repetitionItem.Type = "products";
            repetitionItem.ProductId = item;
            repetitionItem.Product = productInfo[item]?.Product || "";
            return repetitionItem;
          case utils.REPETITION_TYPES.AssetProduct:
            repetitionItem.Type = "asset_products";
            repetitionItem.ProductId = item;
            repetitionItem.Product = productInfo[item]?.Product || "";
            return repetitionItem;
          case utils.REPETITION_TYPES.StoreProduct:
            repetitionItem.Type = "store_products";
            repetitionItem.ProductId = item;
            repetitionItem.Product = productInfo[item]?.Product || "";
            return repetitionItem;
          default:
            break;
        }
      });

      answerData = replaceProductIdByIndex(
        answerData,
        uniqueProductIds,
        assetsAndProducts
      );
      dispatch({ type: actions.SET_ANSWER_DATA, answerData: answerData });
      setAnswerData(answerData);
      const answerDictionary = createDictionary(answerData);
      setAnswerDataDictionary(answerDictionary);
      setRepeatCount(response.data?.RepeatCount);
      setSurveyData(response.data);
      if(isClientSelected){
        await getSurveyTypeData({forPreview: false, SurveyTypeId: SurveyTypeId })
      }
      if (repetitionArray?.length) {
        switch (repetitionType) {
          case utils.REPETITION_TYPES.Asset:
            let isAssetInformationDefined = false;
            for (let asset of repetitionArray) {
              // Check if SerialNumber and AssetType are empty or undefined
              if (asset.SerialNumber !== "" || asset.AssetType !== "") {
                isAssetInformationDefined = true;// Return false if any asset has a non-empty SerialNumber or AssetType
              }
            }
            if (isAssetInformationDefined) {
              await dbUtil.set('assets', { records: repetitionArray })
              setAssets({ records: repetitionArray })
            } else {
              await getLocationDetail()
            }

          case utils.REPETITION_TYPES.Product:
            await dbUtil.set('products', { records: repetitionArray })
            setProducts({ records: repetitionArray })
          case utils.REPETITION_TYPES.AssetProduct:
            await dbUtil.set('asset_products', { records: repetitionArray })
            setAssetProducts({ records: repetitionArray })
          case utils.REPETITION_TYPES.StoreProduct:
            await dbUtil.set('store_products', { records: repetitionArray })
            setStoreProducts({ records: repetitionArray })
          default:
        }
      }

      await updateQuestionJson({
        surveyTypeId: SurveyTypeId,
        SurveyId,
        _repeatCount: response.data?.RepeatCountByAsset
          ? JSON.parse(response.data?.RepeatCountByAsset)
          : response.data?.RepeatCount,
      });
    }
  };

  const getLocationDetail = async () => {
    let selectedResult = {};
    if (Number(surveyData?.LocationId)) {
      const response = await request({ url: apis.SurveyDetails, params: { action: 'load', locationId: surveyData?.LocationId, includeAsset: true }, history, dispatch });
      if (response && response.Results && response.Results.length) {
        selectedResult = response.Results[0];
      }
    }
    if (Object.keys(selectedResult).length > 0) {
      dbUtil.set('assets', { records: selectedResult.AssetList ? JSON.parse(selectedResult.AssetList) : [] });
    }

  }

  useEffect(() => {
    const init = async () => {
      if(isClientSelected){
        await getSubmittedSurveyData(SurveyId);
      }
    };
    init();
  }, [SurveyId]);

  useEffect(() => {
    if (!fromDashboard && !fromSurveyDashboard) {
      const title = async () => {
        dispatch({
          type: actions.SET_PAGE_TITLE_DETAILS,
          pageTitleDetails: (
            <PageTitle
              titleDescription=""
              icon={PageIcon["ProgressReport"]}
              title={"Survey Answers"}
              showTitleInfo={
                <h1 className="text-string-them">
                  &nbsp;{`${t("Survey Type", tOpts)}`}:{" "}
                  <b>
                    <i>{surveyTypeName} </i>
                  </b>
                  ,&nbsp;&nbsp;{`${t("Location", tOpts)}`}:{" "}
                  <b>
                    <i>{surveyData?.OutletName}</i>
                  </b>
                  ,&nbsp;&nbsp;{`${t("User", tOpts)}`}:{" "}
                  <b>
                    <i>{surveyData.ModifiedByUser}</i>
                  </b>
                  ,&nbsp;&nbsp;{`${t("Survey Date Time", tOpts)}`}:{" "}
                  <b>
                    <i>{dayjs(surveyData?.EndDate).format(utils.systemDateTimeFormat())}</i>
                  </b>
                  ,&nbsp;&nbsp;{`${t("Location Code", tOpts)}`}:{" "}
                  <b>
                    <i>
                      {surveyData?.OutletCode}
                    </i>
                  </b>
                </h1>
              }
            />
          ),
        });
      };
      if(isClientSelected){
        title();
      }

      dispatch({
        type: actions.PASS_FILTERS_TOHEADER,
        filtersInHeader: {
          filterButton: null,
          clear: null,
          apply: null,
          hidden: {
            search: true,
            operation: true,
            export: true,
            print: true,
            filter: true,
          },
        },
      });
      return () => {
        dispatch({
          type: actions.PASS_FILTERS_TOHEADER,
          filtersInHeader: {
            filterButton: null,
            clear: null,
            apply: null,
            hidden: {
              search: false,
              operation: false,
              export: false,
              print: false,
              filter: false,
            },
          },
        });
        dispatch({
          type: actions.SET_PAGE_TITLE_DETAILS,
          pageTitleDetails: null,
        });

        dbUtil.del('assets')
        dbUtil.del('products')
        dbUtil.del('asset_products')
        dbUtil.del('store_products')
        dbUtil.del('surveyType')
      };
    }
  }, [SurveyId, surveyData, surveyTypeName]);

  useEffect(() => {
    dispatch({
      type: actions.SET_PAGE_BACK_BUTTON,
      pageBackButton: { status: true, backRoute: redirectPath },
    });
    return (()=>{
      dispatch({
        type: actions.SET_PAGE_BACK_BUTTON,
        pageBackButton: { status: false, backRoute: '' },
      });
    })
  }, [isMobile]);

  const renderOtherInputText = (
    index,
    val,
    valOutside,
    answerArray,
    inputProps,
    ids,
    readOnlyView,
    dependencyStatus,
    showOtherText
  ) => {
    return (
      <div key={index} className="survey-questions">
        <Grid
          sm="12"
          sx={{ position: "relative", left: "6px" }}
        >
          <input
            type="text"
            autoComplete="off"
            name={val?.id}
            placeholder={t(val?.placeholder) || `${t("Type Here")}...`}
            value={valOutside > -1 ? answerArray[valOutside]?.othercomment : ""}
            {...inputProps}
            data-ids={ids}
            data-actualquestion={val?.text}
            data-questionnumber={val?.id}
            data-repeated={val?.repeated || false}
            data-repeatkey={val?.repeatkey}
            disabled={readOnlyView || dependencyStatus}
            data-repetitionitemtype={val?.repetitionItemType}
            data-repetitionitemid={val?.repetitionItemId}
            data-othercomment={showOtherText}
          />
        </Grid>
      </div>
    );
  };

  const renderAnswer = (val, index, isDisableAll, dependencyStatus) => {
    let lastResponseText = null;
    var inputProps = {
      disabled: isDisableAll ? true : dependencyStatus ? true : false,
    };
    let answerArray = answerData;
    let valOutside =
      answerArray && answerArray.findIndex((x) => x.name == val.id);
    let ids = (val.dependentQuestion && val.dependentQuestion.answerkey) || [];
    if (valOutside >= 0) {
      if (val.options || val.dynamicOptions) {
        val.options = answerArray[valOutside]?.options;
        val.children = answerArray[valOutside]?.children;
      }
    }

    const isMultipleSelect = val.subType === "Multiple Choice";
    const dropDownValue =
      valOutside > -1
        ? answerArray[valOutside].value
        : isMultipleSelect
          ? []
          : "";
    let selectedOptionValues;
    if (val.type === AssessmentType.Select || val.type === AssessmentType.Checkbox) {
      const optionsArray = answerArray[valOutside]?.options ? answerArray[valOutside]?.options : val.options
      selectedOptionValues = optionsArray
        ?.filter(item => answerArray[valOutside]?.optionId?.includes(item.key))
        .map(item => item.value);
    }
    let showOtherText = false;
    const showDropdownOtherInput = (val.type === AssessmentType.Select && (val.dynamicOptions && typeof (val.dynamicOptions?.items) === 'string') && Array.isArray(selectedOptionValues) && selectedOptionValues.includes(OTHER_OPTION));

    return val.type == AssessmentType.Select ? (
      <div key={index}>
        <Grid item>
          <Box check>
            <textarea
              type="textarea"
              className="assessment-textarea"
              disabled
              value={
                val?.options
                  ? val.options
                    .filter((option) => option.selected)
                    .map((option) => option.value)
                    .join(",\n")
                  : ""
              }
            ></textarea>
            {showDropdownOtherInput ? renderOtherInputText(index,
              val,
              valOutside,
              answerArray,
              inputProps,
              ids || [],
              readOnlyView,
              dependencyStatus,
              showDropdownOtherInput
            ) : <></>
            }
          </Box>
        </Grid>
        <div className="clearfix"></div>
      </div>
    ) : (
      <Grid key={index} className="survey-questions">
        {lastResponseText ? <div>{lastResponseText} </div> : null}
        {val.options && val.options.length > 0 ? (
          val.options.map((ans, i) => {
            let valIndex = answerArray.findIndex((x) => {
              let xName = x.name;
              if (AssessmentType.Checkbox == val.type) {
                return xName == val.id;
              }
              if (val.type == "text") {
                return xName == ans.name;
              }

              return x.value == ans.value && xName == val.id;
            });

            let conditionBasedOnType, disableOption;
            if (ans.selected) {
              conditionBasedOnType = true;
            }
            showOtherText =
              ans.value === OTHER_OPTION &&
              answerArray[valOutside]?.value == ans.value;
            switch (ans.type) {
              case "radiotext":
              case "checktext":
                let childQuestionValueIndex = -1;
                if (valIndex > -1 && answerArray[valIndex].childName) {
                  childQuestionValueIndex = answerArray.findIndex((x) => {
                    return x.name === answerArray[valIndex].childName;
                  });
                }
                return (
                  <Box key={i} check>
                    <label
                      check
                      className={
                        (readOnlyView || dependencyStatus) &&
                        "label-disable-color"
                      }
                    >
                      <input
                        name={val.id}
                        value={ans.value}
                        type={val.type}
                        checked={
                          valIndex > -1 && conditionBasedOnType ? true : false
                        }
                        data-required={val.required}
                        {...inputProps}
                        disabled={readOnlyView || dependencyStatus}
                      />
                      {ans.value}
                    </label>
                    {valIndex > -1 &&
                      answerArray.length > 0 &&
                      answerArray[valIndex].childName && (
                        <input
                          type="textarea"
                          name={ans.name}
                          className="assessment-textarea"
                          placeholder="Type Here..."
                          {...inputProps}
                          data-ids={ids}
                          value={
                            childQuestionValueIndex > -1
                              ? answerArray[childQuestionValueIndex].value
                              : ""
                          }
                          disabled={readOnlyView || dependencyStatus}
                        />
                      )}
                  </Box>
                );
              case "text":
                return (
                  <div key={index}>
                    <Grid sm={12} md={12} lg={12}>
                      <input
                        type="textarea"
                        name={ans.name}
                        className="assessment-textarea"
                        placeholder={ans.placeholder || "Type Here..."}
                        {...inputProps}
                        data-ids={ids}
                        value={valIndex > -1 ? answerArray[valIndex].value : ""}
                        disabled={readOnlyView || dependencyStatus}
                      />
                    </Grid>
                    <div className="clearfix"></div>
                  </div>
                );
            }
            switch (val.type) {
              case AssessmentType.Radio:
              case AssessmentType.Checkbox:
                return (
                  <>
                    <Box key={i} check>
                      <label
                        className={
                          (readOnlyView || dependencyStatus) &&
                          "label-disable-color"
                        }
                      >
                        <input
                          name={val.id}
                          value={ans.value}
                          type={val.type}
                          checked={
                            valIndex > -1 && conditionBasedOnType ? true : false
                          }
                          {...inputProps}
                          className={
                            val.type == AssessmentType.Radio
                              ? "radioButton"
                              : ""
                          }
                          disabled={readOnlyView || dependencyStatus}
                        />
                        {ans.value}
                      </label>
                    </Box>
                    {showOtherText ? (
                      <div key={index}>
                        <Grid
                          sm="12"
                          sx={{ position: "relative", left: "6px" }}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            name={val.id}
                            placeholder={val.placeholder || "Type Here..."}
                            value={
                              valOutside > -1
                                ? answerArray[valOutside].othercomment
                                : ""
                            }
                            {...inputProps}
                            disabled={readOnlyView || dependencyStatus}
                          />
                        </Grid>
                      </div>
                    ) : (
                      <></>
                    )}
                    {readOnlyView && ans.isFile && (
                      <div className="image-container">
                        <img
                          onClick={openPopUp}
                          src={`${apis.Thumbnail}?imagePath=${valOutside > -1
                            ? answerArray[valOutside].attachment
                            : ""
                            }&isLocalImage=true&isShowAttachmentRecord=true`}
                          className="survey-view-image"
                        />
                      </div>
                    )}
                  </>
                );
              default:
                return;
            }
          })
        ) : val.type == AssessmentType.File ? (
          <div className="file-center">
            <div className="input--file">
              <FaCamera
                color="#3f51b5"
                size="24"
                className={dependencyStatus && "disable-img"}
              />
              <label
                className={`${(readOnlyView || dependencyStatus) && "label-disable-color"
                  }`}
              >
                {" "}
                Take Picture
              </label>
            </div>

            {readOnlyView && (
              <div className="image-container">
                <img
                  onClick={openPopUp}
                  src={`${apis.Thumbnail}?imagePath=${valOutside > -1 ? answerArray[valOutside].attachment : ""
                    }&isLocalImage=true&isShowAttachmentRecord=true`}
                  className="survey-view-image"
                />
              </div>
            )}
          </div>
        ) : val.type == AssessmentType.MultiFile ? (
          <div>
            <div className="input--file">
              <FaCamera
                color="#3f51b5"
                size="24"
                className={dependencyStatus && "disable-img"}
              />
              <label
                className={`${(readOnlyView || dependencyStatus) && "label-disable-color"
                  }`}
              >
                {" "}
                Take Pictures
              </label>
            </div>
            {readOnlyView &&
              valOutside > -1 &&
              answerArray[valOutside].attachment &&
              answerArray[valOutside].attachment.map((imageId, index, self) => {
                return (
                  <div className="image-container" key={imageId}>
                    <img
                      onClick={(e) => {
                        openPopUp(
                          e,
                          index,
                          self,
                          `${apis.Thumbnail}?imagePath=@id&isLocalImage=true&isShowAttachmentRecord=true`
                        );
                      }}
                      src={`${apis.Thumbnail}?imagePath=${imageId}&isLocalImage=true&isShowAttachmentRecord=true`}
                      className="survey-view-image"
                    />
                  </div>
                );
              })}
          </div>
        ) : //capture scene start
          val.type == AssessmentType.Scene ? (
            <div>
              <div className="input--file">
                <span>
                  <FaCamera
                    color="#3f51b5"
                    size="24"
                    className={dependencyStatus && "disable-img"}
                  />
                  <label
                    className={`${(readOnlyView || dependencyStatus) && "label-disable-color"
                      }`}
                  >
                    {" "}
                    Take Picture
                  </label>
                </span>
              </div>
              <div className="scene-capture-container">
                {readOnlyView &&
                  valOutside > -1 &&
                  answerArray[valOutside].attachment &&
                  answerArray[valOutside].attachment.map((imageId) => {
                    if (
                      answerArray[valOutside].attachment[0]?.includes(
                        "ImagetType"
                      )
                    )
                      answerArray[valOutside].attachment[0] = answerArray[
                        valOutside
                      ].attachment[0].replace("ImagetType", "ImageType");
                    return (
                      <>
                        <div className="image-container " key={imageId}>
                          <img
                            onClick={openPopUp}
                            src={`${answerArray[valOutside].attachment[0]}`}
                            className="survey-view-image"
                          />
                        </div>
                        <div>
                          <Button
                            sx={{ mr: 2, pl: "1rem", pr: "1rem" }}
                            value={imageId}
                            type="button"
                            onClick={onImageShowButton}
                            variant="outlined"
                            className="background-theme-blue"
                          >
                            {t("Image Analysis", tOpts)}
                          </Button>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          ) : //capture scene end

            //assessmentType=multiFileStitch -start-
            val.type == AssessmentType.MultiFileStitch ? (
              <div>
                <div className="input--file">
                  <FaCamera
                    color="#3f51b5"
                    size="24"
                    className={dependencyStatus && "disbale-img"}
                  />
                  <label
                    className={`${(readOnlyView || dependencyStatus) && "label-disable-color"
                      }`}
                  >
                    {" "}
                    Take Pictures
                  </label>
                </div>

                {readOnlyView &&
                  valOutside > -1 &&
                  answerArray[valOutside].attachment &&
                  answerArray[valOutside].attachment.map((imageId) => {
                    if (
                      answerArray[valOutside].attachment[0]?.includes("ImagetType")
                    )
                      answerArray[valOutside].attachment[0] = answerArray[
                        valOutside
                      ].attachment[0].replace("ImagetType", "ImageType");
                    return (
                      <>
                        <div className="image-container" key={imageId}>
                          <img
                            onClick={openPopUp}
                            src={`${answerArray[valOutside].attachment[0]}`}
                            className="survey-view-image"
                          />
                        </div>
                        <div>
                          <Button
                            sx={{ mr: 2, pl: "1rem", pr: "1rem" }}
                            value={imageId}
                            type="button"
                            onClick={onImageShowButton}
                            variant="outlined"
                            className="background-theme-blue"
                          >
                            {t("POS Analysis", tOpts)}
                          </Button>
                        </div>
                      </>
                    );
                  })}
              </div>
            ) : //assessmentType=multiFileStitch -end-
              val.type == AssessmentType.Number ? (
                <div key={index}>
                  <Grid sm="12">
                    <input
                      type="number"
                      name={val.id}
                      placeholder={val.placeholder || "Type Here..."}
                      max={val.max}
                      min={val.min}
                      value={valOutside > -1 ? answerArray[valOutside].value : ""}
                      {...inputProps}
                      data-ids={ids}
                    />
                  </Grid>
                  <div className="clearfix"></div>
                </div>
              ) : val.type === AssessmentType.date ? (
                <div>
                  {!readOnlyView && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        defaultValue={dayjs()}
                        name={val.id}
                        key={val.key}
                        disableTime
                        max={val.max}
                        min={val.min}
                        value={valOutside > -1 ? answerArray[valOutside].value : ""}
                        {...inputProps}
                        disabled={readOnlyView || dependencyStatus}
                      />
                    </LocalizationProvider>
                  )}
                  {readOnlyView && (
                    <input
                      type="text"
                      value={valOutside > -1 ? answerArray[valOutside].value : ""}
                      disabled
                    />
                  )}
                </div>
              ) : val.type == AssessmentType.barcode ? (
                <div key={index}>
                  <>
                    <BiQr
                      color="#3f51b5"
                      size="26"
                      className={dependencyStatus && "disable-img"}
                      key={val.name}
                    />
                    <label
                      className={`ml-2 ${(readOnlyView || dependencyStatus) && "label-disable-color"
                        }`}
                    >
                      {" "}
                      Scan Barcode
                    </label>
                    <input
                      type="text"
                      disabled={true}
                      value={valOutside > -1 ? answerArray[valOutside].value : ""}
                    />
                  </>
                </div>
              ) : (
                <div key={index}>
                  <Grid sm="12">
                    <input
                      type="textarea"
                      name={val.id}
                      className="assessment-textarea"
                      placeholder={val.placeholder || "Type Here..."}
                      value={valOutside > -1 ? answerArray[valOutside].value : ""}
                      {...inputProps}
                      data-ids={ids}
                      data-actualquestion={val.text}
                      data-questionnumber={val.id}
                      disabled={readOnlyView || dependencyStatus}
                    />
                  </Grid>
                  <div className="clearfix"></div>
                </div>
              )}
      </Grid>
    );
  };

  const onImageShowButton = async (e) => {
    let text = e?.currentTarget?.value;
    if (text?.length > 0) {
      let guidIndex = text.indexOf("=") + 1;
      let result = text.substr(guidIndex, guidLength);
      let arr = [];
      if (result?.length > 0) {
        let params = {
          action: "CustomList",
          asArray: 0,
          limit: 10,
          start: 0,
          showAllImages: false,
          isFetchCoolerData: true,
          sort: "AvgStockPercentage",
          dir: "ASC",
          isForRank: true,
          LocationId: surveyData?.LocationId,
          uniqueId: result,
        };
        const response = await request({
          url: apis.assetLatestImagesNew,
          params,
          history,
          dispatch,
        });
        let next = [...response.records];
        arr.push(next.shift());
        dispatch({
          type: actions.SET_VISTA_DATA,
          vistaData: {
            records: arr,
            recordCount: response.recordCount,
            TotalCount: response.TotalCount,
            NeedAttentionCount: response.NeedAttentionCount,
          },
        });
        dispatch({
          type: actions.SHOW_VISTA_DETAIL_POP_UP,
          openVistaDetail: true,
        });
        dispatch({
          type: actions.SET_HEADER_TITLE,
          headerTitle: `${surveyData?.OutletName}`,
        });
      }
    }
  };

  const onVistaDetailClose = () => {
    dispatch({
      type: actions.SHOW_VISTA_DETAIL_POP_UP,
      openVistaDetail: false,
    });
    dispatch({
      type: actions.RESET_VISTA_DATA,
      vistaData: {
        records: [],
        recordCount: 0,
        TotalCount: 0,
        NeedAttentionCount: 0,
      },
    });
  };

  const closeSurveyAnswer = () => {
    history.push(redirectPath)
  };

  const handleAcordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const _onResize = () => cache.clearAll();

  const UnprovisionedImage = () => {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
        >
          <div>
            <p style={{ textAlign: 'center' }}>{t('Image Not Provisioned', tOpts)}</p>
          </div>
        </Box>
      </div>
    );
  };
  return isClientSelected ? (<>
 
    {fromSurveyDashboard ?   
    <Box
        sx={{
          width: "100%", 
          padding: {
            sm: "10px 10px",
            md: "10px 20px",
            lg: "30px 30px",
            xl: "30px 30px",
          },
        }}
        component="form"
      >
        <Grid container>
          <Grid item sx={{ width: "100%" }} sm={12} md={12} lg={12} xl={12}>
            <div id="inner-area">
              {allSurveyData &&
                allSurveyData.map((v, i) => {
                  const panelName = typeQuestion[i].title;
                  return (
                    <>
                      <Accordion
                        expanded={expanded === i}
                        key={i}
                        className="w-100"
                        onChange={handleAcordionChange(i)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id={`${i}${Math.random()}`}
                        >
                          <Typography>{panelName}</Typography>
                        </AccordionSummary>

                        <div key={i} className="assementAnswer" id={panelName}>
                          <AccordionDetails>
                            {v.questions.map((question, j) => {
                              count++;
                              let {
                                name,
                                text,
                                required,
                                info,
                                defaultDisplay,
                              } = question;
                              var typeId = parseInt(v.id) - 1;
                              let dependencyStatus = null;
                              let hideElement =
                                dependencyStatus === "hide"
                                  ? "hide-question"
                                  : dependencyStatus === "disable"
                                    ? "disable-question"
                                    : dependencyStatus === "show"
                                      ? "show-question"
                                      : "";

                              if (
                                readOnlyView &&
                                !answerDataDictionary[question.id]
                              ) {
                                return null;
                              }
                              return (
                                <VisibilitySensor key={count}>
                                  <Box
                                    id={name}
                                    className={`seperator ${hideElement}`}
                                  >
                                    <Typography
                                      className={
                                        dependencyStatus &&
                                        "label-disable-color"
                                      }
                                    >
                                      <strong>{count}. </strong> {text}{" "}
                                      {required ? (
                                        <span className="required-color-red">
                                          {" "}
                                          *
                                        </span>
                                      ) : null}
                                    </Typography>
                                    {renderAnswer(
                                      question,
                                      j,
                                      false,
                                      question.text,
                                      dependencyStatus
                                    )}
                                  </Box>
                                </VisibilitySensor>
                              );
                            })}
                          </AccordionDetails>
                        </div>
                      </Accordion>
                    </>
                  );
                })}
            </div>
          </Grid>
        </Grid>
      </Box> :    <Paper
      elevation={fromDashboard ? 0 : 6}
      className={clsx({
        flatten: fromDashboard,
      })}
      sx={{ width: "95%", margin: "20px 0px", padding: "10px 10px" }}
    >
      {!fromDashboard ? (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          sx={{ padding: "10px 20px 0px 0px" }}
        >
          <CloseIcon className="cursor_pointer" onClick={closeSurveyAnswer} />
        </Grid>
      ) : null}
      {!fromDashboard ? (
        <span className="data-answer-title">{t("Survey Answers", tOpts)}</span>
      ) : null}
 
      <Box
        sx={{
          padding: {
            sm: "10px 10px",
            md: "10px 20px",
            lg: "30px 30px",
            xl: "30px 30px",
          },
        }}
        component="form"
      >
        <Grid container>
          <Grid item sx={{ width: "100%" }} sm={12} md={12} lg={12} xl={12}>
            <div id="inner-area">
              {allSurveyData &&
                allSurveyData.map((v, i) => {
                  const panelName = typeQuestion[i].title;
                  return (
                    <>
                      <Accordion
                        expanded={expanded === i}
                        key={i}
                        className="w-100"
                        onChange={handleAcordionChange(i)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id={`${i}${Math.random()}`}
                        >
                          <Typography>{panelName}</Typography>
                        </AccordionSummary>

                        <div key={i} className="assementAnswer" id={panelName}>
                          <AccordionDetails>
                            {v.questions.map((question, j) => {
                              count++;
                              let {
                                name,
                                text,
                                required,
                                info,
                                defaultDisplay,
                              } = question;
                              var typeId = parseInt(v.id) - 1;
                              let dependencyStatus = null;
                              let hideElement =
                                dependencyStatus === "hide"
                                  ? "hide-question"
                                  : dependencyStatus === "disable"
                                    ? "disable-question"
                                    : dependencyStatus === "show"
                                      ? "show-question"
                                      : "";

                              if (
                                readOnlyView &&
                                !answerDataDictionary[question.id]
                              ) {
                                return null;
                              }
                              return (
                                <VisibilitySensor key={count}>
                                  <Box
                                    id={name}
                                    className={`seperator ${hideElement}`}
                                  >
                                    <Typography
                                      className={
                                        dependencyStatus &&
                                        "label-disable-color"
                                      }
                                    >
                                      <strong>{count}. </strong> {text}{" "}
                                      {required ? (
                                        <span className="required-color-red">
                                          {" "}
                                          *
                                        </span>
                                      ) : null}
                                    </Typography>
                                    {renderAnswer(
                                      question,
                                      j,
                                      false,
                                      question.text,
                                      dependencyStatus
                                    )}
                                  </Box>
                                </VisibilitySensor>
                              );
                            })}
                          </AccordionDetails>
                        </div>
                      </Accordion>
                    </>
                  );
                })}
            </div>
          </Grid>
        </Grid>
      </Box>
      {openVistaDetail && // For Prevent Rerendering

        <DialogModal open={openVistaDetail} onClose={onVistaDetailClose} dialogTitle={headerTitle || "Vista Location"} maxWidth="xl" hideCancel={true}>
          {
            vistaData && vistaData.records?.length ?
              <>
                <Loader />
                <AutoSizer onResize={_onResize}>
                  {({ height, width }) => {
                    return (
                      <div className="vista-virtual-list">
                        <List
                          height={height - (smallDevice ? 10 : mediumDevice ? 24 : 0)}
                          rowCount={vistaDataRecordCount}
                          rowHeight={({ index }) => {
                            if (vistaDataRecords[index]) {
                              const rowHt = smallDevice ? 538 : cache.rowHeight({ index });
                              return (!smallDevice && vistaDataRecordCount - 1 === index ? 100 + rowHt : rowHt)
                            }
                            else {
                              return 0;
                            }
                          }
                          }
                          overscanRowCount={3}
                          rowRenderer={({ key, index, style, parent, isScrolling }) => {
                            return <VistaDetailCard key={index} keyCard={key} index={index} style={style} parent={parent} cache={cache} hideHeaderFilter={true} hidePrevNext={true} hideChildInfo={true} />
                          }
                          }
                          width={width}
                        /></div>
                    )
                  }}

                </AutoSizer>
              </> : <UnprovisionedImage />
          }

        </DialogModal>}
    </Paper>}</>
  )
  : (
    <>{t('No data available', tOpts)}</>
  );
}