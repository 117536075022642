import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { transport } from '../../httpUtil/httpRequest';
import utils from '../../utils';
import ChartToggleButtonGroup from '../ChartToggleButtonGroup';
import BarChart from './BarChart';
import { apis } from '../../httpUtil';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@durlabh/dframework-ui';

const addCurrencySign = ['Value'];
const chartKeys = { sales: { key: 'sales', value: 'Potential Sales' }, stock: { key: 'stock', value: 'Pending Time' } };

const loaderStyle= {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '250px',
  height: '300px'
}

const DataBlock = ({ title, data = {}, currencySymbol, t, tOpts }) => (
  <Box className='datablock-container'>
    <Typography variant='h4' className='datablock-title'>{t(title, tOpts)}</Typography>
    {Object.keys(data).map((ele, index) => {
      const label = utils.chartCountLabelEnum[ele];
      let value = data[ele];
      if (addCurrencySign.includes(label)) {
        value = `${currencySymbol} ${value}`;
      }
      return (
        <React.Fragment key={index}>
          <Typography variant='h5' className='datablock-maintitle'>{t(label, tOpts)}</Typography>
          <Typography variant='h6' className='datablock-subtitle'>{value || 0}</Typography>
        </React.Fragment>
      )
    })}
  </Box>
);

const InboxOrderAnalysis = ({ valueField = 'FinalTotalOrder', caseField = 'FinalTotalCase', source, fetchData = false, tabsData, keyColumn, additionalWhere, filterValues, isSales, refresh }) => {
  const [activePotentialSalesTab, setActivePotentialSalesTab] = useState(tabsData[0].value);
  const [activePendingTimeTab, setActivePendingTimeTab] = useState(tabsData[0].value);

  const [potentialSalesCounts, setPotentialSalesCounts] = useState({});
  const [pendingTimeCounts, setPendingTimeCounts] = useState({});

  const [potentialSalesChartData, setPotentialSalesChartData] = useState([]);
  const [pendingTimeChartData, setPendingTimeChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const loaderOpen = useSelector(state => state.appReducer.loaderOpen) 
  const dispatch = useDispatch();
  const userData = useSelector(state => state.appReducer.userData);
  const { CurrencySymbol } = userData?.tags ? userData.tags : 0;
  const t = utils.t
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const finalWhere = { ...additionalWhere, LastMOVAndMOQDateTime: { value: "", fieldName: "LastMOVAndMOQDateTime", operator: "IS NOT NULL" } };
  const selectedClients = filterValues?.uiClientIds || [];
  utils.getMoreFilters({ filterValues, finalWhere });
  const snackbar = useSnackbar();
  const getChartData = async (dataType) => {
    setIsLoading(true);
    const aggregates = {};
    if (!dataType || dataType === "potentital") {
      aggregates[chartKeys.sales.key] = {
        measures: {
          [valueField]: {
            calc: "Sum"
          },
          [caseField]: {
            calc: "Sum"
          },
          LocationId: {
            calc: "Count"
          }
        },
        groupBy: [activePotentialSalesTab],
        source,
        where: finalWhere,
        selectedClients,
        gridFilters: true
      };
    }
    if (!dataType || dataType === "pendingTime") {
      aggregates[chartKeys.stock.key] = {
        measures: {
          OrdersWithDatesCount: {
            calc: "Sum"
          },
          [keyColumn]: {
            calc: "Count"
          },
          OrdersLessThan2DaysOldCount: {
            calc: "Sum"
          }
        },
        groupBy: [activePendingTimeTab],
        source,
        where: finalWhere,
        selectedClients,
        gridFilters: true
      };
      aggregates.count = {
        measures: {
          OrdersWithDatesCount: {
            calc: "Sum"
          },
          [keyColumn]: {
            calc: "Count"
          },
          OrdersLessThan2DaysOldCount: {
            calc: "Sum"
          }
        },
        groupBy: [activePendingTimeTab],
        source,
        where: finalWhere,
        selectedClients,
        gridFilters: true
      };
    }
    for (const item in finalWhere) {
      if (item === "TotalCase" || item === "TotalOrder") {
        const { gt, lt } = finalWhere[item]?.value || {};
        const hasGt = gt !== undefined && gt !== null && gt !== '';
        const hasLt = lt !== undefined && lt !== null && lt !== '';
        if (!hasGt || !hasLt) {
          snackbar.showError('Required values are missing');
          setIsLoading(false);
          dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
          return;
        }
        if (hasGt && hasLt) {
          if(gt > lt){
            snackbar.showError(t('Max is less than Min', tOpts));
            setIsLoading(false);
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
            return;
          }
        }
      }
  }

    const response = await transport({
      url: apis.potentialPendingChartData,
      data: { aggregates, gridFilters: selectedClients?.length > 0 },
      method: 'POST',
    });
    const { sales, stock, count } = response.data;
    if (sales) {
      const chartData = [];
      sales.records.map(record => {
        if (!record[activePotentialSalesTab]) {
          const nullGroupByIndex = chartData.findIndex(ele => ele.Categories === 'Not Defined');
          if (nullGroupByIndex > -1) {
            chartData[nullGroupByIndex].Data += record[valueField];
          } else {
            chartData.push({ Data: record[valueField], Categories: 'Not Defined' })
          }
        } else {
          chartData.push({ Data: record[valueField], Categories: record[activePotentialSalesTab] })
        }
      })
      const potentialSalesCount = { Orders: sales.agg.LocationId, Value: sales.agg[valueField], Cases: sales.agg[caseField] };
      setPotentialSalesCounts(potentialSalesCount);
      setPotentialSalesChartData(chartData);
    }

    if (stock) {
      const chartData = [];
      stock?.records.map(record => {
        if (!record[activePendingTimeTab]) {
          const nullGroupByIndex = chartData.findIndex(ele => ele.Categories === 'Not Defined');
          if (nullGroupByIndex > -1) {
            chartData[nullGroupByIndex].Data += record?.OrdersWithDatesCount;
          } else {
            chartData.push({ Data: record?.OrdersWithDatesCount, Categories: 'Not Defined' })
          }
        } else {
          chartData.push({ Data: record?.OrdersWithDatesCount, Categories: record[activePendingTimeTab] })
        }
      })
      setPendingTimeChartData(chartData);
    }

    if (count) {
      const pendingTimeCount = { AverageDays: Math.round((count?.agg?.OrdersWithDatesCount / count?.agg[keyColumn]) * 100), OrderCount: count?.agg?.OrdersLessThan2DaysOldCount };
      setPendingTimeCounts(pendingTimeCount);
    }
    setIsLoading(false)
    dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
  }

  const handleTabChange = (e) => {
    const { target: { value } } = e;
    if (value !== activePotentialSalesTab) {
      setActivePotentialSalesTab(value);
    }
  }

  const handlePendingTimeTabChange = (e) => {
    const { target: { value } } = e;
    if (value !== activePendingTimeTab) {
      setActivePendingTimeTab(value);
    }
  }

  useEffect(() => {
    const condition = isSales ? (fetchData || refresh > 0) : (fetchData && refresh > 0);
    if (condition) {
      getChartData();
    }
    setIsLoading(false);
  }, [activePendingTimeTab, activePotentialSalesTab, fetchData, refresh]);

  return (
    <Box className='order-analysis-container'>
      <Box className='order-analysis-datablock' style={{ position: 'relative' }}>
        <DataBlock title={t('Potential Sales', tOpts)} data={potentialSalesCounts} currencySymbol={CurrencySymbol} t={t} tOpts={tOpts} />
        {isLoading && !loaderOpen ? (
          <Box style={loaderStyle}>
            <CircularProgress color='primary' />
          </Box>
        ) : (
          <Box style={{ width: '70%' }}>
            <ChartToggleButtonGroup
              tabs={tabsData}
              activeTab={activePotentialSalesTab}
              handleTabChange={handleTabChange}
              t={t}
              tOpts={tOpts}
            />
            <Box>
              <BarChart
                t={t}
                tOpts={tOpts}
                titleColor={utils.chartOptionEnum.potentialColor}
                seriesData={potentialSalesChartData}
                seriesName={utils.chartOptionEnum.potentialName}
                minBarHeight={15}
                CurrencySymbol={CurrencySymbol}
              />
            </Box>
          </Box>
        )}

      </Box>
      <Box className='order-analysis-datablock'>
        <DataBlock title={t('Pending Time', tOpts)} data={pendingTimeCounts} t={t} tOpts={tOpts} />
        {isLoading && !loaderOpen ? <Box
          style={loaderStyle}
        >
          <CircularProgress color='primary' />
        </Box> : <Box style={{ width: '70%' }}>
          <ChartToggleButtonGroup tabs={tabsData} activeTab={activePendingTimeTab} handleTabChange={handlePendingTimeTabChange} t={t} tOpts={tOpts} />
          <Box>
            <BarChart t={t} tOpts={tOpts} titleColor={utils.chartOptionEnum.pendingColor} seriesData={pendingTimeChartData} seriesName={utils.chartOptionEnum.pendingName} minBarHeight={3} />
          </Box>
        </Box>}

      </Box>
    </Box>
  );
};

export default InboxOrderAnalysis;