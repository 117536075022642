import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import MultiSelectDropdown from "../../components/mutliSelectDropdown";
import { apis, request } from "../../httpUtil";
import actions from "./../../redux/actions";
import utils from '../../utils';
import constants from '../../utils/constants';

export default function PageLeftFilters({ onSubmit, resetFilters }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedOptions = useSelector(state => state.appReducer.pageLeftFilters) || {};
    const userData = useSelector(state => state.appReducer.userData) || {};
    const portalCombos = useSelector(state => state.appReducer.comboData);
    const { ClientId, ClientIds: tagsClientIds = '' } = userData?.tags || {};
    const filterValues = useSelector(state => state.appReducer.filterValues) || {};
    const clientOptions = useSelector(state => state.appReducer.clientsList) || [];
    const comboData = useSelector(state => state.appReducer.multiClientComboData);
    const { Market: marketOptions, Distributor: distributorOptions, LocationClassification: classificationOptions, LocationType: channelOptions, SalesPerson: salesRepOptions, RouteType: routeOptions, PreSellerRoute: preSellerRouteOptions } = comboData || {};
    const options = { clientOptions, marketOptions, classificationOptions, channelOptions, salesRepOptions, routeOptions, distributorOptions, preSellerRouteOptions };
    const { t: trans, i18n } = useTranslation();
    const tOpts = { t: trans, i18n };
    const t = utils.t;

    const handleChange = (event) => {
        const finalFilters = { ...selectedOptions, IsSmart: event.target.value }
        dispatch({ type: actions.SET_PAGE_LEFT_FILTERS, filters: finalFilters });
    };

    const handleSearchChange = (event) => {
        const { value, name } = event.target;
        const finalFilters = { ...selectedOptions, [name]: value };
        dispatch({ type: actions.SET_PAGE_LEFT_FILTERS, filters: finalFilters });
    };

    const handleClientSelectChange = (event) => {
        const { value, name } = event.target;
        const { selectedOptionsKey, optionsKey } = Object.values(filtersConfig).filter(filter => filter.label === name)?.[0];
        if (value[value.length - 1] === -1) {
            const value = selectedOptions[selectedOptionsKey]?.length === options[optionsKey]?.length ? [] : options[optionsKey].map(option => (option.Key || option.LookupId));
            const updatdValue = { ...selectedOptions, [selectedOptionsKey]: value };
            dispatch({ type: actions.SET_PAGE_LEFT_FILTERS, filters: updatdValue });
            return;
        }
        const updatdValue = { ...selectedOptions, [selectedOptionsKey]: value };
        dispatch({ type: actions.SET_PAGE_LEFT_FILTERS, filters: updatdValue });
    };

    useEffect(() => {
        if (filterValues && selectedOptions?.ClientId) {
            filterValues.uiClientIds = selectedOptions?.ClientId;
        }
        utils.getFilteredLookups({ filterValues, tagsClientIds, portalCombos, dispatch, history, isFromDataPage: true });
    }, [selectedOptions, portalCombos])


    function clearFilters(props) {
        const { resetHeaderFilters = false } = props || {};
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {}
        });
        if (resetHeaderFilters) {
            const values = { Market: [], LocationClassification: [], LocationType: [], SalesPerson: [], RouteType: [] }
            dispatch({ type: actions.SET_MULTICLIENT_COMBO_DATA, comboData: values });
        }
    }

    const getMultiClientsFilter = async () => {
        const { success, data } = await request({ url: apis.GetMultiClientFilters, disableLoader: false, history, dispatch });
        dispatch({ type: actions.SET_MULTICLIENT_FILTERS, filters: success ? data : [] });
        dispatch({ type: actions.SET_FILTER_VALUES, filterValues: { uiClientIds: [Number(ClientId)], changedKey: 'uiClientIds' } });
    }

    useEffect(() => {
        const isMultiClientsAssigned = tagsClientIds.split(',').length > 1 && tagsClientIds.trim() !== '0';
        dispatch({ type: actions.OVERVIEW_RECORD_SELECT, overviewRecordSelect: { code: null } });
        dispatch({ type: actions.REPLENISHMENT_GRID_DATA, replenishmentGridData: { data: [] } });
        clearFilters();
        if (isMultiClientsAssigned) {
            getMultiClientsFilter();
        }
    }, []);

    const handleSearch = () => {
        const filters = {};
        Object.keys(filtersConfig).forEach((filter) => {
            const { label, optionsKey, selectedOptionsKey, defaultSelectedKey } = filtersConfig[filter];
            let defaultValues = [];

            if (defaultSelectedKey && filterValues[defaultSelectedKey]) {
                defaultValues = filterValues[defaultSelectedKey];
            }
            const optionsMenu = options[optionsKey] || [], selectedOptionsMenu = (selectedOptions[selectedOptionsKey]?.length ? selectedOptions[selectedOptionsKey] : defaultValues)
                .filter(selectedOption => optionsMenu.some(option => option.Key === selectedOption || option.LookupId === selectedOption));
            filters[selectedOptionsKey] = selectedOptionsMenu;
        });
        constants?.pageFilterFields.forEach(key => {
            if (selectedOptions.hasOwnProperty(key)) {
                filters[key] = selectedOptions[key];
            }
        });
        dispatch({ type: actions.SET_PAGE_LEFT_FILTERS, filters: filters });
        onSubmit({ filters });
    }

    const filtersConfig = {
        ClientFilter: {
            label: "Client",
            titleMessage: 'Select & Unselect All',
            multiSelectLabel: 'Multiple Clients',
            optionsKey: 'clientOptions',
            selectedOptionsKey: 'ClientId',
            defaultSelectedKey: 'uiClientIds'
        },
        MarketFilter: {
            label: "Market",
            titleMessage: 'Select & Unselect All',
            multiSelectLabel: 'Multiple Markets',
            optionsKey: 'marketOptions',
            selectedOptionsKey: 'MarketId'
        },
        ChannelFilter: {
            label: "Channel",
            titleMessage: 'Select & Unselect All',
            multiSelectLabel: 'Multiple Channels',
            optionsKey: 'channelOptions',
            selectedOptionsKey: 'LocationTypeId'
        },
        ClassificationFilter: {
            label: "Classification",
            titleMessage: 'Select & Unselect All',
            multiSelectLabel: 'Multiple Classifications',
            optionsKey: 'classificationOptions',
            selectedOptionsKey: 'ClassificationId'
        },
        DistributorFilter: {
            label: "Distributor",
            titleMessage: 'Select & Unselect All',
            multiSelectLabel: 'Multiple Distributors',
            optionsKey: 'distributorOptions',
            selectedOptionsKey: 'DistributorId'
        },
        SalesRepFilter: {
            label: "Sales Rep",
            titleMessage: 'Select & Unselect All',
            multiSelectLabel: 'Multiple Sales Rep',
            optionsKey: 'salesRepOptions',
            selectedOptionsKey: 'SalesRepId'
        },
        RouteFilter: {
            label: "Route",
            titleMessage: 'Select & Unselect All',
            multiSelectLabel: 'Multiple Routes',
            optionsKey: 'routeOptions',
            selectedOptionsKey: 'RouteId'
        },
        PreSellerRouteFilter: {
            label: "Pre Seller Route",
            titleMessage: 'Select & Unselect All',
            multiSelectLabel: 'Multiple Pre Seller Routes',
            optionsKey: 'preSellerRouteOptions',
            selectedOptionsKey: 'PreSellerRouteId'
        }
    };

    const isFilterExists = !((selectedOptions.ClientId?.length > 0 || selectedOptions.MarketId?.length > 0 || selectedOptions.LocationTypeId?.length > 0 || selectedOptions.ClassificationId?.length > 0 || selectedOptions.DistributorId?.length > 0 || selectedOptions.SalesRepId?.length > 0 || selectedOptions.RouteId?.length > 0 || selectedOptions.PreSellerRouteId?.length > 0) || selectedOptions.IsSmart > -1 || selectedOptions.Location?.length > 0 || selectedOptions.LocationCode?.length > 0);

    return (
        <div>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    {t('Filters', tOpts)}
                </AccordionSummary>

                <AccordionDetails sx={{ maxHeight: 630.5, overflowY: 'auto', overflowX: 'hidden' }}>
                    <FormControl sx={{ ml: 1, mb: 2, width: '100%' }}>
                        <TextField value={selectedOptions.Location} name='Location' onChange={handleSearchChange} id="outlined-basic" label={t('Search Outlet Name', tOpts)} variant="outlined" />
                    </FormControl>

                    <FormControl sx={{ ml: 1, mb: 1, width: '100%' }}>
                        <TextField value={selectedOptions.LocationCode} name='LocationCode' onChange={handleSearchChange} id="outlined-basic" label={t('Search Outlet Code', tOpts)} variant="outlined" />
                    </FormControl>

                    {Object.keys(filtersConfig).map(filter => {
                        const { label, optionsKey, selectedOptionsKey, defaultSelectedKey } = filtersConfig[filter];
                        let defaultValues = [];
                        if (defaultSelectedKey && filterValues[defaultSelectedKey]) {
                            defaultValues = filterValues[defaultSelectedKey];
                        }
                        const optionsMenu = options[optionsKey] || [], selectedOptionsMenu = (selectedOptions[selectedOptionsKey]?.length ? selectedOptions[selectedOptionsKey] : defaultValues).filter(selectedOption => optionsMenu.some(option => option.Key === selectedOption || option.LookupId === selectedOption));
                        return (
                            <FormControl sx={{ width: '100%' }} key={label}>
                                <MultiSelectDropdown config={filtersConfig[filter]} options={optionsMenu} selectedOptions={selectedOptionsMenu} handleChange={handleClientSelectChange} width={'100%'} />
                            </FormControl>
                        )
                    })}

                    <FormControl sx={{ mt: 1, mb: 1, ml: 1, width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Smart</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedOptions.IsSmart}
                            label={t('Smart', tOpts)}
                            onChange={handleChange}
                        >
                            <MenuItem value={-1}>{t('None', tOpts)}</MenuItem>
                            <MenuItem value={1}>{t('Yes', tOpts)}</MenuItem>
                            <MenuItem value={0}>{t('No', tOpts)}</MenuItem>
                        </Select>
                    </FormControl>

                </AccordionDetails>

                <AccordionActions>
                    <Button disabled={isFilterExists} onClick={resetFilters}>{t('Reset', tOpts)}</Button>
                    <Button onClick={handleSearch}>{t('Search', tOpts)}</Button>
                </AccordionActions>
            </Accordion>
        </div>
    );
}