import UiModel from './ui-model';
import Location from './Location';

const outletGroupModel = new UiModel({
    title: "Create Group",
    defaultSort: 'GroupName ASC',
    linkColumn: 'GroupName',
    api: 'LocationGroup',
    module: "OutletGroup",
    gridTitle: "Outlets",
    titleDescription: 'Create and configure groups (clusters) of outlets for use in StoreBuddy',
    pageTitle: "Outlet Groups Overview",
    showAddIcon: false,
    breadCrumbs: "Outlet Group",
    overrideFileName: 'Outlet Groups',
    overrideLoaderOnInitialRender: true,
    backURL: "/Survey/OutletGroup",
    columns: [
        { field: "LocationGroupId", type: 'number', hide: true, width: 80, label: "Group ID", fieldLabel: null },
        { field: "GroupName", type: "string", label: "Group Name", pinned: true, width: 240, required: true, min: 5, max: 50, requiredIfNew: true, link: true },
        { field: "Description", type: "string", label: "Description", width: 300, max: 200 },
        { field: "IsActive", type: "boolean", label: "Active", width: 80, tab: "schedule", defaultValue: true },
        { field: "LocationGroupMembers", type: "oneToMany", label: "Outlets", countInGrid: true, relation: Location.ChildGrid, filterable: false, sortable: false, showLabel: false }
    ]
});

export default outletGroupModel;
