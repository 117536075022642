import UiModel from './ui-model';

const missedOpportunityOutletModel = new UiModel({
    title: "Missed Opportunity Market",
    defaultSort: 'MissedOpportunityCases DESC',
    idProperty: 'MissedOpportunityOutletId',
    readOnly: true,
    preferenceId: 'playbookMissedOpportunityOutlet',
    updateTitle: false,
    hideBackButton: true,
    addModifiedByColumn: false,
    addModifiedOnColumn: false,
    addCreatedOnColumn: false,
    addCreatedByColumn: false,
    pivotAPI: 'MissedOpportunityOutlet.ashx',
    template: 'missed-opportunity-outlet-pivot',
    configFileName: 'missed-opportunity-outlet',
    api: 'MissedOpportunityOutlet',
    showPivotExportBtn: true,
    addSelectedClientsForExport: true,
    columns: [
        { field: "OutletName", label: "Outlet Name", type: 'string', width: 200 },
        { field: "OutletCode", label: "Outlet Code", type: 'string', width: 200 },
        { field: "Product", label: "Product", type: 'string', width: 250 },
        { field: "AssetSerialNumber", label: "Asset Serial Number", type: 'string', width: 220 },
        { field: "CasesPerFacing", label: "Cases Per Facings", type: 'number', width: 200 },
        {
            field: "Price", label: "Case Price", type: "decimal", width: 150, headerAlign: 'right', align: 'right', renderCell: (param) => {
                const { Currency, Price } = param.row;
                return Currency.replace('{0}', Price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0);
            }
        },
        {
            field: "UnitPrice", label: "Unit Price", type: "decimal", width: 150, headerAlign: 'right', align: 'right', renderCell: (param) => {
                const { Currency, UnitPrice } = param.row;
                return Currency.replace('{0}', UnitPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0);
            }
        },
        { field: "CaseSize", label: "Case Size", width: 200, type: 'number' },
        { field: "TotalInStockDays", label: "Total InStock Days", type: 'decimal', headerAlign: 'right', align: 'right', width: 200 },
        { field: "OutofStockDays", label: "OOS Days", type: 'decimal', headerAlign: 'right', align: 'right', width: 200 },
        { field: "TotalCasesSold", label: "Total Cases Sold", type: 'decimal', headerAlign: 'right', align: 'right', width: 150 },
        { field: "TotalUnitsSold", label: "Total Units Sold", type: 'decimal', headerAlign: 'right', align: 'right', width: 150 },
        { field: "TimesRefilled", label: "Times Refilled", type: 'number', width: 150 },
        { field: "CasesSoldInOneDay", label: "Avg Daily Cases Sold", type: 'decimal', headerAlign: 'right', align: 'right', width: 200 },
        { field: "UnitsSoldInOneDay", label: "Avg Daily Units Sold", type: 'decimal', headerAlign: 'right', align: 'right', width: 200 },
        { field: "MissedOpportunityUnites", label: "Missed Opportunity Units", type: 'decimal', headerAlign: 'right', align: 'right', width: 200 },
        {
            field: "MissedRevenue", label: "Missed Revenue", type: "decimal", headerAlign: 'right', align: 'right', width: 150, renderCell: (param) => {
                const { Currency, MissedRevenue } = param.row;
                return Currency.replace('{0}', MissedRevenue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0);
            }
        },
        {
            field: "ActualRevenue", label: "Actual Revenue", type: "decimal", headerAlign: 'right', align: 'right', width: 150, renderCell: (param) => {
                const { Currency, ActualRevenue } = param.row;
                return Currency.replace('{0}', ActualRevenue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0);
            }
        },
        {
            field: "PotentialRevenue", label: "Potential Revenue", type: "decimal", headerAlign: 'right', align: 'right', width: 200, renderCell: (param) => {
                const { Currency, PotentialRevenue } = param.row;
                return Currency.replace('{0}', PotentialRevenue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0);
            }
        },
        { field: "MarketName", label: "Market Name", width: 200 },
        { field: "DistributorName", label: "Distributor Name", width: 200 },
        { field: "ChannelName", label: "Channel Name", width: 200 },
        { field: "ClassificationName", label: "Classification", width: 200 }
    ]
});

export default missedOpportunityOutletModel;