import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import utils from '../../utils'
import { apis } from '../../httpUtil';
import useMobile from '../../utils/useMobile';

function ClientLogo({ customClassName = "" }) {
  const isMobileDevice = useMobile();
  const userData = useSelector(state => state.appReducer.userData);
  const [clientLogo, setClientLogo] = useState({ exist: false, url: "" });

  useEffect(() => {
    const { tags = "" } = userData || {};
    let clientId = tags && Number(tags.ClientId) ? Number(tags.ClientId) : "",
    isFromDemoSite = tags.IsFromDemoSite || false,
    ClientName = tags.ClientName || "",
    clientLogoURL = clientId && `${apis.ClientLogoBaseUrl}?ImageName=${clientId}.png&ImageType=ClientLogo`,
    IsClientImageExists = tags.IsClientImageExists || false;

    ClientName = isFromDemoSite ? tags.DemoClientName || 'Demo' : ClientName;
    clientLogoURL = isFromDemoSite ? `${apis.ClientLogoBaseUrl}?ImageName=demosite.png&ImageType=ClientLogo` : clientLogoURL;

    if (IsClientImageExists) {
      setClientLogo({ exist: true, url: clientLogoURL })
    } else {
      setClientLogo({ exist: false, url: ClientName })
    }
  }, [userData])

  return <>
    {clientLogo.exist ?
      <img src={clientLogo.url} width="80" alt="client logo" /> :
      isMobileDevice.mobile && !isMobileDevice.tablet ? '' : <span className={`client-logo-name ${customClassName}`}>
        {clientLogo.url}
      </span>}
  </>
}

export default ClientLogo;
