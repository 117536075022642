import React, { useState, useEffect } from "react";
import utils from "../../utils";
import { useTranslation } from 'react-i18next'
import { Grid, TextField, FormGroup, FormControl, FormLabel } from "@material-ui/core";
import { useSnackbar } from "@durlabh/dframework-ui";
const t = utils.t;
const defaultValue = { "gt": "", "lt": "" };

const MinMax = (props) => {

    const { onChange, value: initialValue = defaultValue, type = "number", label } = props || {};
    const [values, setValues] = useState(initialValue);
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const snackbar = useSnackbar();

    useEffect(() => {
        setValues(initialValue);
    }, [initialValue]);

    const handleValueChange = (event, key) => {
        const { value } = event.target;
        let numericValue = value ? Number(value) : '';
        if (numericValue < 0) {
            snackbar.showError(t("Negative values are not allowed"));
            return;
        }
        const updatedValues = { ...values, [key]: numericValue };
        setValues(updatedValues);
        onChange && onChange(updatedValues);
    };
    return (
        <FormControl component="fieldset" variant="outlined">
            <FormLabel component="legend">{t(label, tOpts)}</FormLabel>
            <FormGroup>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} className="dis-grid pr-1">
                        <TextField
                            error={values.gt == null && values.lt != null}
                            size="small"
                            placeholder="Min"
                            variant="outlined"
                            type={type}
                            label={t("Min", tOpts)}
                            name="min"
                            value={values.gt}
                            onChange={(e) => handleValueChange(e, "gt")}
                            />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className="dis-grid pl-1">
                        <TextField
                            error={values.lt == null && values.gt != null}
                            size="small"
                            placeholder={t("Max", tOpts)}
                            variant="outlined"
                            type={type}
                            label={t("Max")}
                            name="max"
                            value={values.lt}
                            onChange={(e) => handleValueChange(e, "lt")}
                            />
                    </Grid>
                </Grid>
            </FormGroup>
        </FormControl>
    )
}
export default MinMax;