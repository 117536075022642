import UiModel from './ui-model';
import utils from '../utils';
const VistaZBatteryAnalysis = new UiModel({
    title: "VistaZ Battery Analysis",
    linkColumn: 'SmartDeviceId',
    titleDescription: 'The report operates on a weekly basis, with the week starting from Monday. If records for the first day of a given week (Monday) are not available, the system will automatically fetch data from the subsequent days within that week, continuing until the end of the week.',
    readOnly: true,
    idProperty: 'SmartDeviceId',
    module: 'VistaZBatteryAnalysis',
    api: 'VistaZBatteryAnalysis',
    addCreatedModifiedColumns: false,
    hideBackButton: true,
	isClient: true,
	pivotAPI: 'VistaZBatteryAnalysis.ashx',
    template: 'vistaz-battery-analysis',
    configFileName: 'vistaz-battery-analysis',
    showPivotExportBtn: true,
    columns: [
        { field: "AssetSerialNumber", type: 'string', label: "Asset Serial #", required: true, width: 150 },
        { field: "ClientName", type: 'string', label: "Client Name", required: false, width: 120 },
		{ field: "ConsumptionStatus", type: 'string', label: "Consumption Status", required: false, width: 120 },
		{ field: "CurrentBatteryStatus", type: 'string', label: "Current Battery Status", required: false, width: 120 },
		{ field: "BatteryStatus", type: 'string', label: "Battery Status", required: false, width: 120 },
		{ field: "InstallBattery", type: 'decimal', label: "Install Battery", required: false, width: 120 },
		{ field: "InstallBatteryStatus", type: 'string', label: "Install Battery Status", required: false, width: 120 },
		{
            field: "InstallDate", type: 'date', label: "Install Date", required: false, width: 120, valueFormatter: params => {
                return utils.formatDate(params.value, false, true)
            }, keepUTC: true
        },
		{ field: "LocationName", type: 'string', label: "Location", required: false, width: 120 },
		{ field: "LocationCode", type: 'string', label: "Location Code", required: false, width: 120 },
		{ field: "OrientationType", type: 'string', label: "Orientation", required: false, width: 120 },
		{ field: "RSSI", type: 'number', label: "RSSI", required: false, width: 120 },
		{ field: "SerialNumber", type: 'string', label: "Serial #", required: true, width: 160 },
		{ field: "VLoss", type: 'decimal', label: "VLoss", required: false, width: 120 },
    ]
});
export default VistaZBatteryAnalysis;