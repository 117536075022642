import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import utils from "../../utils";
import { apis, request } from "../../httpUtil";
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid, Typography, List, ListSubheader, ListItem, Button, TextField
} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import swal from 'sweetalert';
import useMobile from '../../utils/useMobile';
import dayjs from "dayjs";
import actions from '../../redux/actions';
import Masonry from '@mui/lab/Masonry';
import { store } from '../../redux/store/index'
import dateUtils from '../../utils/date'

const { t } = utils;

function FormGenerator(props) {

  const { formConfig, moduleName, id, api, comboArray, additionalData = {}, localComboData = {}, isEditEnabled = false, filterValues = {}, multiClientFilters, outletClientId, reduceMargin = false, cardClassName = '' } = props;
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMobile();
  const userData = useSelector(state => state.appReducer.userData);
  const isEditable = userData?.modules[moduleName]?.Edit;
  const [formData, setFormData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [comboState, setComboState] = useState({});
  const [editModeOn, setEditModeOn] = useState(false);
  const editModeOnRef = useRef(false);
  const wrapperRef = useRef(null);
  const hideEditButton = Number(userData?.tags?.IsSuperAdmin) > 0 && isEditEnabled;
  const className = reduceMargin ? 'formGenerator-space-equal-outlet-information' : 'formGenerator-space-equal';

  useEffect(() => {
    loadFormData();
    window.addEventListener('beforeunload', handleAlert);

    return () => {
      window.removeEventListener('beforeunload', handleAlert);
    }
  }, []);

  useEffect(() => {
    if (editModeOn) {
      dispatch({
        type: actions.SET_EDIT_MODE, editMode: true
      });
    }

    return () => {
      dispatch({
        type: actions.SET_EDIT_MODE, editMode: false
      });
    }
  }, [editModeOn])

  async function loadFormData() {
    let params = { id: id, action: "load" };
    if (filterValues.uiClientIds?.length) {
      params.uiClientIds = outletClientId?.toString()
      utils.applyMultiClientFilters(params, filterValues, multiClientFilters);
    }
    const comboTypes = [];
    for (const name of comboArray) {
      comboTypes.push({ type: name, loaded: true });
    }
    if (Array.isArray(comboTypes)) {
      params.comboTypes = JSON.stringify(comboTypes);
    }

    const result = await request({ url: apis[api], params, history, dispatch });
    if (result.success) {
      setFormData({ ...result.data, ...additionalData });
      setComboState({ ...result.combos, ...localComboData });
    }
  }

  function handleChange(name, value) {
    setFormValues({ ...formValues, [name]: value });
  }

  function handleAlert(event) {
    if (editModeOnRef.current) {
      event.preventDefault();
      event.returnValue = true;
    }
  }

  function handleEditMode() {
    setEditModeOn(true);
    editModeOnRef.current = true;
  }

  function handleCancel() {
    setFormValues({});
    setEditModeOn(false);
    editModeOnRef.current = false;
  }

  async function handleConfirm() {
    try {
      for (const key in formValues) {
        if (formValues[key] === null || formValues[key] === undefined) {
          delete formValues[key];
        }
      }

      if (!formValues || Object.keys(formValues).length <= 0) {
        return swal({
          title: t("No action done", tOpts),
          text: t("Can not save, No modification was done in data", tOpts),
          dangerMode: false,
          button: {
            text: t("Ok", tOpts)
          }

        })
      }

      const payload = { ...formValues, action: "save", id: id };
      const formClosingTime = payload.BusinessClosingTime, formOpeningTime = payload.BusinessOpeningTime;
      if (formOpeningTime && formOpeningTime !== formData.BusinessOpeningTime) {
        payload.BusinessOpeningTime = dayjs(payload.BusinessOpeningTime || formData.BusinessOpeningTime).format(utils.timeFormat);
      }
      if (formClosingTime && formClosingTime !== formData.BusinessClosingTime) {
        payload.BusinessClosingTime = dayjs(payload.BusinessClosingTime || formData.BusinessClosingTime).format(utils.timeFormat);
      }

      const businessOpeningTime = new Date("1900/01/01 " + payload.BusinessOpeningTime);
      const businessClosingTime = new Date("1900/01/01 " + payload.BusinessClosingTime);
      if (businessOpeningTime > businessClosingTime) {
        swal('Warning', "Business Hours From should be less than Business Hours To time");
        return false;
      }
      const result = await request({ url: apis[api], params: payload, history, dispatch });
      if (result.success) {
        handleCancel();
        setFormData(result.data);
        let reduxStore = store?.getState()?.appReducer;
        let userData = reduxStore.userData ? reduxStore.userData.tags : {};
        const dateFormatString = comboState.DateFormat.find(obj => obj.LookupId === result.data.DateFormatId);
        const dateString = dateFormatString.DisplayValue + ' hh:mm A';
        userData.DateTimeFormat = dateString.length ? dateString : 'DD-MM-YYYY hh:mm:ss A';
        userData.TempratureUnitId = result.data.TempratureUnitId;
      }
    } catch (err) {
      return swal({
        title: "Some error occurred",
        text: err.message || err,
        dangerMode: true
      })
    }
  }

  function fieldGenerator(field, index, editMode) {
    let jsxComponent = <></>, displayValue = '';
    const calValue = formValues[field.name] !== undefined ? formValues[field.name] :
      formData[field.name] !== undefined ? formData[field.name] : '';
    switch (field.type) {
      case 'text':
        jsxComponent = !editMode ? (calValue || '-') :
          <TextField
            key={field.type + '-' + index}
            name={field.name}
            value={calValue}
            onChange={(event) => { handleChange(field.name, event.target.value) }}
            variant="outlined"
            size="small"
            fullWidth={true}
            disabled={field.disabled} />
        break;
      case 'select':
        if (!editMode) {
          displayValue = (calValue !== undefined && calValue !== null && comboState[field.comboKey]) ? (comboState[field.comboKey].filter((item) => item.LookupId === calValue)[0]) : "";
          displayValue = displayValue ? displayValue.DisplayValue : '-';
        }
        jsxComponent = !editMode ? displayValue :
          <Autocomplete
            clearText={t("Clear", tOpts)}
            closeText={t('Close', tOpts)}
            openText={t('Open', tOpts)}
            key={field.type + '-' + index}
            value={calValue !== undefined && comboState[field.comboKey] ? comboState[field.comboKey].filter((item) => item.LookupId === calValue)[0] : {}}
            getOptionLabel={(option) => field?.comboKey?.toLowerCase() === 'language' ? option.DisplayValue : t(option.DisplayValue, tOpts)}
            options={comboState[field.comboKey] || []}
            onChange={(event, newValue) => { handleChange(field.name, newValue?.LookupId) }}
            size="small"
            fullWidth={true}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" />} />
        break;
      case "date":
        jsxComponent = !editMode ? (calValue || '-') :
          <KeyboardDatePicker
            key={field.type + '-' + index}
            value={calValue}
            margin="normal"
            onChange={(newValue) => { handleChange(field.name, newValue) }}
            format="MM/dd/yyyy"
            size="small"
            fullWidth={true}
            invalidDateMessage={t("Invalid Date Format", tOpts)}
            minDateMessage={t('Date should not be before minimal date', tOpts)}
            maxDateMessage={t('Date should not be after maximal date', tOpts)}
          />
        break;
      case "time":
        jsxComponent = !editMode ? (calValue || '-') :
          <KeyboardTimePicker
            key={field.type + '-' + index}
            value={calValue || null}
            onChange={(newValue) => { handleChange(field.name, newValue) }}
            clearable
            inputVariant={"outlined"}
            size="small"
            fullWidth={true}
            disabled={field.disabled}
            invalidDateMessage={t("Invalid Time format", tOpts)}
          />
        break;
      case "date-time":
        const dateObj = dayjs(calValue, 'YYYYMMDDHHmmssSSS');
        const hours = dateObj.format('hh');
        const minutes = dateObj.format('mm');
        const formattedClockTime = dateObj.format('A');
        const formattedDateTime = `${hours}:${minutes} ${formattedClockTime}`;
        jsxComponent = !editMode ? (formattedDateTime || '-') :
          <KeyboardTimePicker
            key={field.type + '-' + index}
            value={typeof (calValue) === 'string' ? dateUtils.dateParse(calValue) : calValue ?? null}
            onChange={(newValue) => { handleChange(field.name, newValue) }}
            clearable
            inputVariant={"outlined"}
            size="small"
            fullWidth={true}
            disabled={field.disabled}
            invalidDateMessage={t("Invalid Time format", tOpts)}
            inputProps={{disabled: field.disableKeyboardInput, endAdorment: true}}
          />
        break;
      default:
        jsxComponent = <></>;
    }
    return !editMode ?
      <Typography className={`${isMobile.mobile ? "pl-2" : "pl-3"} pb-0 pt-1`} variant="p" display="block" gutterBottom>
        {typeof jsxComponent === 'string' && jsxComponent.includes(":") ? jsxComponent : t(jsxComponent, tOpts)}
      </Typography> :
      jsxComponent;
  }

  return <>
    <div ref={wrapperRef}>
      <Grid container style={{ minHeight: '400px' }} className={cardClassName}>
        <Masonry className={className} columns={isMobile.portrait && !isMobile.tablet ? 1 : 2} >
          {formConfig.map((form, index) => {
            return form.active ? <>
              {form.mapping.map((subForm) => {
                return <>
                  <div>
                    <List className='pt-0 pb-0 text-break ' subheader={subForm.heading ? <ListSubheader className='pl-0 unsetTop'>{t(subForm.heading, tOpts)}</ListSubheader> : ''} alignItems="center" dense={true} disablePadding={true}>
                      {subForm.felids.map((field, idx) => {
                        return (<ListItem className={`form-list-container ${isMobile.mobile ? "pl-0 pr-0" : ""}`} alignItems="center" dense={true} disablePadding={true}>
                          <Grid container spacing={1}>
                            <Grid item xs={5} sm={6} md={6} lg={6} >
                              <Typography className={`${isMobile.mobile ? "pl-2" : "pl-3"} pb-0 pt-1 ${editModeOn ? 'mt-2' : ''}`} variant="p" display="block" gutterBottom>
                                {t(field.label, tOpts)}:
                              </Typography>
                            </Grid>
                            <Grid item xs={7} sm={6} md={6} lg={6} >
                              {fieldGenerator(field, idx, editModeOn)}
                            </Grid>
                          </Grid>
                        </ListItem>)
                      })}
                    </List>
                  </div>
                </>
              })}
            </> : ''
          })}
        </Masonry>
      </Grid>
      {!reduceMargin && <hr />}
      <Grid container spacing={1}>
        <Grid item xs={6} sm={6} md={6} lg={6} >
          {(formData.ModifiedOn && formData.ModifiedByUser) && <Typography variant="p">{`${t('Last Updated', tOpts)}: ${dayjs(formData.ModifiedOn).format(utils.systemDateTimeFormat(true))}, ${formData.ModifiedByUser}`}</Typography>}
        </Grid>
        {hideEditButton ?
          <Grid item xs={6} sm={6} md={6} lg={6} >
            <Grid justifyContent="flex-end" container spacing={1}>
              <Grid item>
                {!editModeOn && <Button variant="contained" size='small' className="background-theme-blue sub-header-button" onClick={handleEditMode}>{t("Edit", tOpts)}</Button>}
              </Grid>
              <Grid item>
                {editModeOn && <Button variant="contained" size='small' className="background-theme-green sub-header-button" onClick={handleConfirm}>{t("Confirm", tOpts)}</Button>}
              </Grid>
              <Grid item >
                {editModeOn && <Button variant="contained" size='small' className="background-theme-red sub-header-button" onClick={handleCancel}>{t("Cancel", tOpts)}</Button>}
              </Grid>
            </Grid>
          </Grid>
          : null}
      </Grid>
    </div>
  </>
}

export default FormGenerator;