import React, { memo, useCallback, useMemo } from 'react';
import { InputAdornment, FormControl ,TextField } from "@material-ui/core";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import { useSelector, useDispatch } from 'react-redux';
import { LOGIN_CONST } from '../../constants';
import actions from '../../redux/actions'
import { withTranslation } from 'react-i18next';
import utils from '../../utils';


const t = utils.t;
const UserInput = memo(function ({ t: translate, i18n, disabled }) {
    const tOpts = { t: translate, i18n };
    const dispatch = useDispatch();

    const memoizedUserIcon = useMemo(() => <InputAdornment position="start"> <MailOutlineTwoToneIcon /> </InputAdornment>, []);
    // set user login email in redux
    const memoizedSetUserLogin = useCallback((e) => {
        dispatch({ type: actions.SET_USER_EMAIL, userEmail: e.target.value, clientsList: [] })
    }, [dispatch]);

    const userEmail = useSelector(state => state.appReducer.userEmail);

    return <FormControl className="w-100 my-2 mt-4">
        <TextField
            label={t(LOGIN_CONST.USERNAME, tOpts) + ' *'}
            id="standard-basic"
            variant="standard" 
            key={LOGIN_CONST.USERNAME}
            onChange={memoizedSetUserLogin}
            value={userEmail}
            disabled={disabled}
        />
    </FormControl>
})

export default withTranslation()(UserInput);