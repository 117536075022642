import React, { useState, useEffect } from 'react';
import {
    Card, CardContent, Grid, Typography, Tabs, Tab, Chip, Box
} from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useMobile from '../../utils/useMobile';
import { useTranslation } from 'react-i18next';
import { request, apis } from '../../httpUtil';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import CoolerView2 from '../VistaLocationDetailsCardList/CoolerView2';
import actions from '../../redux/actions';
import utils from '../../utils';
const { t } = utils;

const OutletPlanogram = (props) => {
    const { t: translate, i18n } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const tOpts = { t: translate, i18n };
    const { assets, pricing, userSymbolCurreny } = props;
    const hasAssets = assets && assets.length > 0;
    const [activeTab, setActiveTab] = useState(0);
    const [planogramDetails, setPlanogramDetails] = useState({ status: false, heading: "", data: {} });
    const [productData, setProductData] = useState([]);
    const selectedAssetHasPlanogram = assets && assets.length > 0 && assets[activeTab]?.PlanogramId;
    const [assetId, setAssetId] = useState(null);
    const filterValues = useSelector(state => state.appReducer.filterValues);
    const multiClientFilters = useSelector(state => state.appReducer.filters);
    const isMobile = useMobile();
    const planogramAsset = [
        { field: "Description", headerName: t("Product Name", tOpts), flex: 0, minWidth: 300, filterable: false, sortable: false },
        { field: "sku", headerName: t("SKU", tOpts), flex: 1, minWidth: 150, filterable: false, sortable: false },
        { field: "CaseType", headerName: t("Case Type", tOpts), flex: 1, minWidth: 150, filterable: false, sortable: false },
        {
            field: "price", type: 'number', headerName: t("Pricing", tOpts), flex: 1, minWidth: 150, filterable: false, sortable: false, renderCell: (params) => {
                const price = params.value;
                return price ? `${userSymbolCurreny} ${price}` : '';
            }
        },
        { field: "facings", type: 'number', headerName: t("Facings", tOpts), flex: 1, minWidth: 150, filterable: false, sortable: false },
        { field: "facingsPerCase", type: 'number', headerName: t("Cases per Facings", tOpts), flex: 1, minWidth: 150, filterable: false, sortable: false }
    ]

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const fetchData = async () => {
        const payload = { action: "previewPlanogram", otherAction: "previewPlanogram", PlanogramId: assets[activeTab]?.PlanogramId, AssetId: "" }
        if (filterValues.uiClientIds?.length) {
            payload.uiClientIds = filterValues.uiClientIds?.toString();
            utils.applyMultiClientFilters(payload, filterValues, multiClientFilters);
        }
        const data = await request({ url: apis.AssetPurityList, params: payload, history, dispatch });
        if (data) {
            dispatch({ type: 'SET_PLANOGRAM_CHECKBOX', isPlanogram: { key: 0, value: true } })
            dispatch({ type: 'SET_INSTORE_CHECKBOX', isInStore: { key: 0, value: false } })
            setPlanogramDetails({ status: true, heading: assets[activeTab]?.PlanogramName, data: data.planogram[0] })
        }
        return data;
    }

    useEffect(() => {
        fetchData();
        const fetchAndSetData = async () => {
            if (assets.length > 0 && assets[activeTab]) {
                const selectedAsset = assets[activeTab];
                setAssetId(selectedAsset.AssetId);

                // Parse and set product data
                const replenishDetails = selectedAsset?.ReplenishDetails;
                const facingDetails = selectedAsset?.FacingDetails;

                if (replenishDetails !== "") {
                    const parsedReplenishDetails = JSON.parse(replenishDetails);
                    let parsedFacingDetails = [];
                    if (facingDetails) {
                        parsedFacingDetails = JSON.parse(facingDetails).flatMap(facing => facing.products);
                    }

                    const facingsCount = parsedFacingDetails.reduce((acc, { id }) => {
                        acc[id] = (acc[id] || 0) + 1;
                        return acc;
                    }, {});

                    const updatedProductData = parsedReplenishDetails?.map(product => {
                        const pricingData = pricing.find(p => {
                            const sizeInProduct = parseInt(product.CaseType?.match(/\d+/)?.[0] || "0");
                            return p.ProductId === product.ProductId && p.Size === sizeInProduct;
                        });
                        return {
                            ...product,
                            facings: facingsCount[product.ProductId] || 0,
                            price: pricingData ? pricingData.Price : '',
                            facingsPerCase: pricingData ? pricingData.FacingsPerCase : '',
                            sku: pricingData ? pricingData.SKU : ''
                        };
                    });

                    setProductData(updatedProductData);

                } else if (replenishDetails === "") {
                    const assetPurityData = await fetchData();
                    if (assetPurityData && assetPurityData?.planogram && assetPurityData?.planogram.length > 0 && Array.isArray(assetPurityData.planogram[0].productSummary)) {
                        setProductData(assetPurityData?.planogram[0]?.productSummary.map((item) => ({
                            ProductId: item.product.id,
                            Description: item.product.name,
                            sku: item.product.sku,
                        })));
                    } else {
                        setProductData([]);
                    }
                } else {
                    setProductData([]);
                }
            }
        };

        fetchAndSetData();
    }, [activeTab, assets, pricing]);

    return (
        <div>
            {hasAssets ? (
                <Card className='pt-0 m-0 pl-1 pr-1 pb-1'>
                    <CardContent className='pt-0 m-0 pl-1 pr-1 pb-1'>
                        <div className='flex-d-c'>
                            <Tabs
                                value={activeTab}
                                onChange={handleChangeTab}
                                variant={"scrollable"}
                                scrollButtons={'on'} >
                                {assets.map(tab => {
                                    return <Tab className="pt-0" label={t(tab.AssetSerialNumber, tOpts)} />
                                })}
                            </Tabs>
                        </div>
                        {selectedAssetHasPlanogram ? (
                            <>
                                <Typography className='pt-1 pb-1'>{t('Planogram Name', tOpts)} - {assets[activeTab]?.PlanogramName}</Typography>
                                <Box display={'flex'}>
                                    {
                                        planogramDetails.status && <div className="planogram-outlet-container">
                                            <CoolerView2 data={{ Planogram: planogramDetails.data, Facings: planogramDetails.data }} index={0} showSeparator={true} />
                                        </div>
                                    }
                                    <div className='planogram-outlet-container'>
                                        <DataGridPremium
                                            rows={productData || []}
                                            columns={planogramAsset}
                                            getRowId={(row) => row.ProductId}
                                            pagination={false}
                                            hideFooter={true}
                                            density='compact'
                                            sx={{ height: '450px' }}
                                            disableAggregation={true}
                                            disableRowGrouping={true}
                                            disableColumnFilter={true}
                                            disableColumnMenu={true}
                                            rowSelection={false}
                                            disableSelectionOnClick
                                        />
                                    </div>
                                </Box>
                            </>
                        ) : (
                            <div className='text-center mt-5 d-flex justify-content-center outletcard-no-data'>{t("No Planogram assigned to this asset", tOpts)}</div>
                        )}
                    </CardContent>
                </Card>
            ) : (
                <div className='text-center mt-5 d-flex justify-content-center outletcard-no-data'>{t("No Asset found on the location", tOpts)}</div>
            )}


        </div>
    )
}

export default OutletPlanogram;