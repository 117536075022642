import React, { useState, useEffect, useRef } from "react";
import { Grid, Card, CardContent, CardActions, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useHistory } from "react-router";
import { ORDER_STATUS, STRINGS_CONST } from "../../constants";
import { apis, request } from "../../httpUtil";
import utils from '../../utils'
import actions from "./../../redux/actions";
import { useParams } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import ExportExcelFile from './../ExportFile/ExportExcelFile';
import useMobile from '../../utils/useMobile';
import { useDebounce } from 'use-debounce';
import swal from 'sweetalert';
import { useSnackbar } from "@durlabh/dframework-ui";
import { ExpandedRow, GridBase } from "./../DeliveriesList/LocationReorderExpand";
import enums from '../../utils/constants';
import constants from "../../utils/constants";
import DialogModal from "../Common/DialogModel";

let timer = null;
const Ignore = (props) => {
    const isMobileDevice = useMobile();
    const { columns, isReorder, globalFilter, refresh, onClickItem, multiClientFilters } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const gridRef = useGridApiRef();
    const customExportRef = useRef();
    const snackbar = useSnackbar();

    const columnsVisibilityModel = {
        Code: true,
        LocationName: true,
        Street: !isMobileDevice.mobile,
        City: !isMobileDevice.mobile,
        State: !isMobileDevice.mobile,
        PostalCode: false,
        OrderSuggestion: !isMobileDevice.mobile,
        ReorderDate: false,
        DeliveryNotes: false,
        ShareOfShelf: false,
        TotalCase: !isMobileDevice.mobile,
        TotalOrder: true,
        MarketName: false,
        LocationTypeName: false,
        ClassificationName: false,
        LocationRoute: false,
        PreSellerRouteName: false,
        SalesRepName: false,
        Image: false,
        __detail_panel_toggle__: !isMobileDevice.mobile,
        LastDeliveryDate: false,
        LastOrderDate: false,
        FinalMoQ: false,
        FinalMoV: false,
        LastMOVAndMOQDateTime: false,
        ClientName: false
    }

    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const gridData = useSelector(state => state.appReducer.replenishmentGridData);
    const overviewRecordSelect = useSelector(state => state.appReducer.overviewRecordSelect);
    const userData = useSelector(state => state.appReducer.userData);
    const { DeliveryNotesCaption } = userData?.tags || {};
    const { ClientId, CurrencySymbol, CustomDateFormat: DateFormat } = userData?.tags ? userData.tags : 0;
    const { data, editProductRowId = 0 } = gridData;
    const locationSearch = useSelector(state => state.appReducer.locationSearch);
    const [searchDebounce] = useDebounce(locationSearch, 1000);
    const locationParams = useParams();
    const { locationId } = locationParams;

    //State - define 
    const [oldParams, setOldParams] = useState({});
    const [params, setParams] = useState({ ...utils.listDefaultParams, orderStatusId: ORDER_STATUS.IGNORED, IncludeKPI: true, sort: 'ReorderDate', dir: 'desc' });
    const [headerBtnEnable, setHeaderBtnEnable] = useState(true);
    const [manualRefresh, setManualRefresh] = useState(0);
    const [checkedData, setCheckedData] = useState([]);
    const [filterModel, setFilterModel] = useState({ items: [], linkOperator: "and" });
    const [sortModel, setSortModel] = useState();
    const [gridColumns, setGridColumns] = useState();
    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const [expandedRowId, setExpandedRowId] = useState('');
    const [columnVisibility, setColumnVisibility] = useState(columnsVisibilityModel)
    const [isGridPreferenceFetched, setIsGridPreferenceFetched] = useState(false);

    let initialGridColumns;
    const [open, setOpen] = useState(false);
    const [IssueType, setIssueType] = useState('');
    const [issueDetails, setIssueDetails] = useState('');
    const [currentRow, setCurrentRow] = useState(null);
    const [currentTab, setCurrentTab] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);

    const handleOpen = ({ row, tab }) => {
        setCurrentRow(row);
        setCurrentTab(tab);
        setOpen(true);
    };

    const handleClose = () => {
        setIssueType('');
        setIssueDetails('');
        setOpen(false);
        setIsDisabled(true);
    };

    if (!initialGridColumns) {
        let ignoreGridColumns = columns({ dateKey: "ReorderDate", action: onClickItem, t, tOpts, isMobileDevice, DateFormat, DeliveryNotesCaption, onOutletCodeCellClick: onClickItem });
        initialGridColumns = ignoreGridColumns.filter(ele => ele.id !== 'ReorderDate')
    }

    const commonGridOptions = {
        filterMode: 'server',
        sortingMode: 'server',
        disableAggregation: true,
        disableRowGrouping: true,
        filterModel: filterModel,
        sortModel: sortModel,
        paginationModel: paginationModel,
        setPaginationModel: (e) => {
            setPaginationModel(e);
            clearExpandedRow();
        },
        paginationMode: 'server',
        page: params.start || 0,
        pageSize: params.limit,
        onPageChange: (pageVal) => {
            cancelOverviewRecordSelect();
            setParams((prev) => ({ ...prev, start: pageVal }));
            clearExpandedRow();
        },
        onPageSizeChange: (pageSizeVal) => {
            cancelOverviewRecordSelect();
            setParams((prev) => ({ ...prev, start: 0, limit: pageSizeVal }));
            clearExpandedRow();
        },
        onFilterModelChange: (filterValues) => {
            cancelOverviewRecordSelect();
			filterValues.items.forEach(x => {
				const filterColumn = gridColumns?.find(y=>y.field === x.field);
				x.type = filterColumn.type
			});
            setFilterModel(filterValues);
            clearExpandedRow();
        },
        onSortModelChange: (sortVal) => {
            if (sortVal.length > 0) {
                const { field, sort } = sortVal[0];
                params["sort"] = field;
                params["dir"] = sort;
            } else {
                delete params["sort"];
                delete params["dir"];
            }
            setParams((prev) => ({ ...prev, ...params }));
            setSortModel(sortVal);
            clearExpandedRow();
        },
        disableSelectionOnClick: !(isMobileDevice.mobile && !isMobileDevice.tablet),
        density: "compact",
        hideFooter: false,
    }
    //state - end

    const cancelOverviewRecordSelect = () => {
        if (overviewRecordSelect.code) {
            dispatch({ type: actions.OVERVIEW_RECORD_SELECT, overviewRecordSelect: { code: null } });
        }
    }

    useEffect(() => {
        dispatch({ type: actions.SET_OPERATIONS_INFORMATION, operationsCheckedData: { onExportClick } });
        return () => {
            dispatch({ type: actions.SET_OPERATIONS_INFORMATION, operationsCheckedData: { onExportClick: null } });
        }
    }, [globalFilter, params, data]);

    useEffect(() => {
        if (refresh > 0 && isGridPreferenceFetched)
            getData({ manualRefresh: false });
    }, [params, filterModel, refresh, paginationModel, isGridPreferenceFetched]);

    useEffect(() => {
        if (manualRefresh > 0 && isGridPreferenceFetched)
            getData({ manualRefresh: true });
    }, [manualRefresh, isGridPreferenceFetched]);

    useEffect(() => {
        if (searchDebounce !== undefined) {
            setParams({ ...params, assetOrLocationName: searchDebounce })
        }
    }, [searchDebounce]);

    useEffect(() => {
        clearTimeout(timer);
        const { records } = gridData.data;
        if (records && records.length > 0 && editProductRowId !== 0) {
            timer = setTimeout(() => utils.autoExpendRow(gridRef, editProductRowId, setExpandedRowId), 500);
        }
    }, [gridData]);

    function clearExpandedRow() {
        setExpandedRowId();
    }

    const onScheduledIgnoreClick = (e, orderStatus, row) => {
        const record = row ? [row.LocationId] : checkedData;
        const successCBK = () => setManualRefresh((val) => (val + 1));
        utils.onScheduledIgnoreClick({ e, isMulti: true, data, statusId: orderStatus, successCBK, isReorder, dispatch, history, checkedData: record, t, tOpts, updateData: true, clearSelectionModel, clearExpandedRow });
    }

    async function getData({ manualRefresh }) {
        let payload = utils.deepCloneObject(params);
        payload.limit = paginationModel.pageSize;
        payload.start = paginationModel.page;
        payload.start = payload.start * payload.limit;
        payload = utils.updateFilter(payload, filterModel, globalFilter, overviewRecordSelect, false);
        utils.applyMultiClientFilters(payload, globalFilter, multiClientFilters);
        if (Number(locationId) > 0) {
            payload.LocationId = Number(locationId);
        }

        let payloadChanged = JSON.stringify(payload) === JSON.stringify(oldParams)
        if(Object.keys(payload.filter).length === 0) {
            payloadChanged = false;
        }
        if (!payloadChanged || manualRefresh) {
            setOldParams(payload);
            let finalPayload = utils.deepCloneObject(payload);

            if (Object.keys(payload.filter).length === 0) {
                delete finalPayload.filter;
            }
            const caseValidation = utils.validateRange('casesRange', 'Cases', finalPayload, t, tOpts, snackbar);
            const orderValidation = utils.validateRange('orderRange', 'Order', finalPayload, t, tOpts, snackbar);
            if (!caseValidation || !orderValidation) {
                return;
            } 
            const data = await request({ url: apis.ScheduledOrder, params: finalPayload, history, dispatch });
            if (data) {
                dispatch({ type: actions.REPLENISHMENT_GRID_DATA, replenishmentGridData: { data: data } });
            }
        }
    }

    function clearFilter() {
        dispatch({ type: actions.OVERVIEW_RECORD_SELECT, overviewRecordSelect: { code: null } });
        setParams((prev) => ({ ...prev, start: 0, filter: [] }));
        setFilterModel({
            pagination: { page: 0 },
            linkOperator: "and",
            items: []
        })
    }

    const onColumnVisibilityModelChange = (columns) => {
        setColumnVisibility(columns);
    }

    function onSelectionModelChange(selectionModel, details) {
        setHeaderBtnEnable(selectionModel.length === 0);
        const selectedRowData = [];
        selectionModel.forEach(key => {
            selectedRowData.push(details?.current?.state?.rows?.dataRowIdToModelLookup[key]?.LocationId);
        });
        setCheckedData(selectedRowData);
    }

    function clearSelectionModel() {
        setCheckedData([]);
    }

    const onExportClick = () => {
        if (data?.records?.length === 0 || data.length === 0) {
            swal({
                title: '',
                text: "No data to export",
                icon: "warning",
                dangerMode: false
            });
            return;
        }
        const { orderedFields, columnVisibilityModel, lookup } = gridRef.current.state.columns;
        const gridExportColumns = [];
        const hiddenColumns = Object.keys(columnVisibilityModel).filter(key => columnVisibilityModel[key] === false);
        const visibleColumns = orderedFields.filter(ele => !hiddenColumns?.includes(ele) && ele !== '__check__' && ele !== '__detail_panel_toggle__' && ele !== 'Image');
        if (visibleColumns?.length === 0) {
            snackbar.showMessage(t('You cannot export while all columns are hidden... please show at least 1 column before exporting', tOpts));
            return;
        }
        visibleColumns.forEach(ele => {
            const { type, headerName } = lookup[ele];
            gridExportColumns.push({ id: ele, label: headerName, type: type });
        })

        const _cols = gridExportColumns.map((e) => {
            const { label, type } = e;
            let { id } = e;
            // if (id === enums.locationReorderExportFields.ReorderDate) {
            //     id = enums.locationReorderExportFields.ReorderDateText;
            // }
            // if (id === enums.locationReorderExportFields.LastDeliveryDate) {
            //     id = enums.locationReorderExportFields.LastDeliveryDateString;
            // }
            return {
                dataIndex: id,
                title: label,
                type: type
            }
        })

        let _params = utils.deepCloneObject(params);
        _params = utils.updateFilter(_params, filterModel, globalFilter, overviewRecordSelect);
        utils.applyMultiClientFilters(_params, globalFilter, multiClientFilters);
        if (Number(ClientId) > 0) {
            _params = { ..._params, ...{ clientId: Number(ClientId) } }
        }
        if (Object.keys(_params.filter).length === 0) {
            delete _params.filter;
        }
        utils.fileExport({ form: customExportRef.current, params: _params, columns: _cols, currencySymbol: CurrencySymbol, title: STRINGS_CONST.REPLENISHMENT_ORDER_STATUS_IGNORE });
    }

    function expandedRowIdsChange(row) {
        let id = '';
        if (Array.isArray(row)) {
            id = row[1];
        } else {
            id = row.id;
        }
        if (expandedRowId === id) {
            setExpandedRowId("")
        } else {
            setExpandedRowId(id)
        }
    }

    function closeExpandedRows() {
        setExpandedRowId('');
    }

    const getDetailPanelContent = ({ row }) => {
        return (
            <Card>
                <CardContent sx={{ padding: '8px' }}>
                    <ExpandedRow data={row.ProductInfo} isReorder={isReorder} locationId={row.LocationId} closeExpandedRows={closeExpandedRows} onClickItem={onClickItem} row={row} filterValues={globalFilter} multiClientFilters={multiClientFilters} />
                </CardContent>
                <CardActions>
                    <Grid justifyContent="flex-end" container spacing={1}>
                        <Grid item >
                            <Button size="small" variant="outlined" color="secondary" className="background-theme-brown sub-header-button location-reorder-yellow-help" onClick={(e) => handleOpen({ row, tab: constants.Ignore })}>{t("Help", tOpts)}</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" variant="contained" color="secondary" className="background-theme-brown sub-header-button Location-Reorder-yellow" onClick={(e) => dispatch({ type: actions.EDIT_REPLENISHMENT_ORDER, replenishmentOrder: { open: true, data: row } })} >{t("Edit", tOpts)}</Button>
                        </Grid>
                        <Grid item >
                            <Button size="small" className="background-theme-green sub-header-button Location-Reorder-green" variant="contained" color='primary' onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.SCHEDULED, row)}>{t("Schedule", tOpts)}</Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        )
    }

    if (isMobileDevice.mobile && !isMobileDevice.tablet) {
        commonGridOptions.selectionModel = [expandedRowId]
    } else {
        delete commonGridOptions.selectionModel;
    }

    if (!gridColumns) {
        let ignoreGridColumns = columns({ dateKey: "ReorderDate", action: onClickItem, t, tOpts, isMobileDevice, DateFormat, DeliveryNotesCaption, onOutletCodeCellClick: onClickItem });
        ignoreGridColumns = ignoreGridColumns.filter(ele => ele.id !== 'ReorderDate')
        setGridColumns(ignoreGridColumns);
    }

    return (
        <>
            {isMobileDevice.mobile && !isMobileDevice.tablet ? "" :
                <Grid justifyContent="flex-end" container spacing={1}>
                    <Grid item >
                        <Button size="small" disabled={headerBtnEnable} className="background-theme-green sub-header-button Location-Reorder-green" variant="contained" color='primary' onClick={(e) => onScheduledIgnoreClick(e, ORDER_STATUS.SCHEDULED)}>{t("Schedule", tOpts)}</Button>
                    </Grid>
                </Grid>}
            <GridBase
                key={`ignore-grid-${JSON.stringify(isMobileDevice)}`}
                clearFilter={clearFilter}
                customClass={`mt-2 data-grid-font-12 location-reorder-grid-overrides`}
                columns={gridColumns}
                data={data.records}
                rowsPerPage={[10, 20, 50]}
                movePagination={false}
                showTitleColumn={!(isMobileDevice.mobile && !isMobileDevice.tablet)}
                onRowDoubleClick={onClickItem}
                gridRef={gridRef}
                preferenceName='locationReorderIgnore'
                columnVisibilityModel={columnVisibility}
                setIsGridPreferenceFetched={setIsGridPreferenceFetched}
                otherOptions={{
                    rowCount: data?.recordCount,
                    checkboxSelection: !(isMobileDevice.mobile && !isMobileDevice.tablet),
                    getDetailPanelContent: getDetailPanelContent,
                    onSelectionModelChange: onSelectionModelChange,
                    getRowId: (row) => row.RowNumber,
                    onRowClick: (row) => isMobileDevice.mobile ? expandedRowIdsChange(row) : null,
                    detailPanelExpandedRowIds: [expandedRowId],
                    onDetailPanelExpandedRowIdsChange: expandedRowIdsChange,
                    disableColumnMenu: !!(isMobileDevice.mobile && !isMobileDevice.tablet),
                    onColumnVisibilityModelChange: onColumnVisibilityModelChange,
                    selectionModel: checkedData,
                    onSortModelChange: commonGridOptions.onSortModelChange,
                    onFilterModelChange: commonGridOptions.onFilterModelChange,
                    ...commonGridOptions
                }}
            />
            <ExportExcelFile ref={customExportRef} url={apis.ScheduledOrder} />
            <DialogModal
                open={open}
                onClose={handleClose}
                onSubmit={(e) => {
                    e.preventDefault();
                    utils.handleSubmit(e, { currentRow, currentTab, IssueType, issueDetails: utils.orderIssueOption, userData, snackbar, t, tOpts, handleClose, setIssueDetails, setIssueType, dispatch });
                }}
                dialogTitle="Please select Issue Type"
                submitBtnTitle="Submit"
                closeBtnTitle="Cancel"
                maxWidth="sm"
                maxHeight='sm'
                closeBtnCls="light"
                submitBtnColor="success"
                actionButtonsCls='dialog-actions-centered '
                className={IssueType === 'Others' ? 'vista-dialog-bx' : ''}
                normalHeight={false}
                submitBtnDisabled={(!IssueType)}
            >
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <RadioGroup
                            aria-label="issue-type"
                            name="IssueType"
                            value={IssueType}
                            onChange={(e) => utils.handleIssueTypeChange(e, { setIssueType })}
                        >
                            <FormControlLabel value="OverOrder" control={<Radio />} label={t("Over Order", tOpts)} />
                            <FormControlLabel value="UnderOrder" control={<Radio />} label={t("Under Order", tOpts)} />
                            <FormControlLabel value="IncorrectOrder" control={<Radio />} label={t("Incorrect Order", tOpts)} />
                            <FormControlLabel value="Others" control={<Radio />} label={t("Others", tOpts)} />
                        </RadioGroup>
                    </Grid>
                    {IssueType === 'Others' && (
                        <Grid item>
                            <TextField
                                label={t("Comments", tOpts)}
                                variant="outlined"
                                margin="dense"
                                name="issueType"
                                value={utils.orderIssueOption}
                                onChange={(e) => utils.handleIssueTypeChange(e, { snackbar, optionType: 'others' })}
                                fullWidth
                                multiline
                                rows={5}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogModal >
        </>

    )
}
export default Ignore;