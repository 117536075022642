import React, { useEffect, useState, useRef } from "react";
import {
    Button, Grid,
    Select, FormControl, MenuItem,
    IconButton, List, ListItem, Typography, InputLabel
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import PlusOneRoundedIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import actions from "../../redux/actions";
import { request, apis } from "../../httpUtil";
import utils from "../../utils";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import DialogModal from '../Common/DialogModel';
import ClearIcon from '@material-ui/icons/Clear';
import swal from 'sweetalert';
import useMobile from '../../utils/useMobile';
import constants from '../../utils/constants';
import { ORDER_STATUS } from "../../constants";
import { UserContext } from '../../utils/orderContext';
import enums from '../../utils/constants';
const t = utils.t;
const { hidePriceForClients, roles } = enums

const qtyDdlOptions = [...Array(enums.maximumQuantityToOrder + 1).keys()];
const keysToConvert = ['DisplayValue', 'SKU', 'ShortEANCode'];
const ITEM_ACTION = {
    DELETE: 0,
    ON_CHANGE_QTY: 1,
    ON_CHANGE_PRODUCT_NAME: 2,
    ON_CHANGE_CASE_TYPE: 3
};

const productFilter = (options, { inputValue }) => {
    const filteredOptions = options.map(obj =>
        Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, keysToConvert.includes(key) ? value.toLowerCase() : value]))
    );
    const indexes = filteredOptions.filter(item => keysToConvert.some(key => item[key].includes(inputValue.toLowerCase()))).map(ele => ele.LookupId);
    return options?.filter(ele => indexes.includes(ele.LookupId));
}

const ProductItem = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const isMobile = useMobile();
    const { caseTypeDdlData = {} } = useSelector((state) => state.appReducer.productCaseTypeDdl);
    const { data, onItemAction, ddlData, cloneProductList, tRow, checkProductAndCaseTypeSelected, onRevertChanges, checkOrderStatus, isOrderScheduled, ClientId, RoleId, columnConfig } = props;
    const { ProductName, UnitsNeeded, isNew, ProductId, CaseSize, UnitPrice, UnitLabelId } = data;
    const [openDialog, setOpenDialog] = useState(isMobile.mobile && isNew)
    const [productId, setProductId] = useState()
    const [showForm, setShowForm] = useState(isNew)
    let { UnitLabel } = data;
    UnitLabel = UnitLabelId == constants.UnpackUnitLabelId ? t(UnitLabel, tOpts) : t("pack", tOpts);

    const ddlUpdatedData = ddlData.map((e) => {
        return { ...e, label: e.DisplayValue };
    });
    const onClearProduct = (productData) => {
        onItemAction({ action: ITEM_ACTION.ON_CHANGE_PRODUCT_NAME, data: productData, value: null });
    };

    const onProductChange = async (eData, newValue, closeModal) => {
        if (!newValue) {
            return;
        }
        for (const { ProductId, ProductName } of cloneProductList) {
            if (ProductId === newValue.LookupId) {
                swal({ title: `${t('Info', tOpts)}`, text: `${ProductName} ${t('cannot be added as it already exists', tOpts)}`, icon: "info", buttons: { button: t('Ok', tOpts) } });
                { closeModal && onItemAction({ action: ITEM_ACTION.DELETE, data: {} }) }
                return;
            }
        }
        setProductId(newValue.LookupId)
        onItemAction({ action: ITEM_ACTION.ON_CHANGE_PRODUCT_NAME, data: props.data, value: newValue });
        if (newValue?.LookupId && !caseTypeDdlData.hasOwnProperty(newValue.LookupId)) {
            const params = {
                start: 0,
                limit: 0,
                comboType: "ProductPrice",
                asArray: 0,
                ProductId: newValue.LookupId,
                LocationId: tRow.LocationId
            }
            const response = await request({ url: apis.Combo, params, history, dispatch });
            if (response?.records?.length) {
                response.records[0].ProductCategoryName = newValue.ProductCategoryName
                dispatch({
                    type: actions.PRODUCT_CASE_TYPE_DDL, productCaseTypeDdl: {
                        caseTypeDdlData: { ...caseTypeDdlData, [newValue.LookupId]: response.records }
                    }
                });
            }
        }
    }

    const getCaseTypeValue = () => {
        let returnValue = null;
        if (caseTypeDdlData.hasOwnProperty(ProductId)) {
            if (caseTypeDdlData[ProductId].length > 1) {
                returnValue = caseTypeDdlData[ProductId].find(e => e.CaseSize === CaseSize);
            } else {
                if (caseTypeDdlData[ProductId].length > 0 && CaseSize !== caseTypeDdlData[ProductId][0].CaseSize) {
                    onItemAction({ action: ITEM_ACTION.ON_CHANGE_CASE_TYPE, data: props.data, value: caseTypeDdlData[ProductId][0] });
                }
                returnValue = caseTypeDdlData[ProductId][0];
            }
        }
        return returnValue
    }

    const closeDialog = () => {
        setOpenDialog(false)
        onItemAction({ action: ITEM_ACTION.DELETE, data: {} })
    }

    const addProduct = async () => {
        if (isOrderScheduled) {
            await checkOrderStatus(t, tOpts, dispatch, onRevertChanges);
        }
        const ddlSelection = checkProductAndCaseTypeSelected({ showDelete: false });
        if (!ddlSelection.success) {
            swal({
                title: ddlSelection.message,
                icon: "warning",
                dangerMode: true,
                buttons: {
                    button: t('Ok', tOpts),
                }
            })
            return;
        }
        setOpenDialog(false)
        setShowForm(false)
    }

    return (
        <>
            <DialogModal
                dialogTitle={`${t("Add Product", tOpts)}`}
                maxWidth="md"
                className={`outletCard-modal mx-2 ${isMobile.mobile && !isMobile.tablet ? "edit-order-mobile-overrides" : ""}`}
                open={openDialog}
                onClose={closeDialog}
                dividers={true}
                hideFooter={true}>
                <div className='w-100 px-3'>
                    <div className="pt-2 pb-2">
                        <Grid container spacing={1}>
                            <Grid className="text-center" item xs={12} sm={12} md={12} lg={12}>
                                <div className="add-reorder-product-image">
                                    {productId ? <img alt='...' src={apis.imgBaseUrl + "/controllers/thumbnail.ashx?imageType=Thumbnail&ImagePath=" + productId + ".png&maxWidth=100"} className="expanded-row-card-product-dialog py-2" /> : 'Product Image'}
                                </div>
                            </Grid>
                            <Grid className="text-center" item xs={12} sm={12} md={12} lg={12} >
                                <Autocomplete size="medium"
                                    fullWidth
                                    filterOptions={productFilter}
                                    value={ddlUpdatedData.find((e) => e.LookupId === ProductId) || ''}
                                    onChange={(eData, newValue) => onProductChange(eData, newValue, false)}
                                    options={ddlUpdatedData}
                                    disableClearable={true}
                                    renderInput={(params) => <TextField className={`${isMobile.mobile ? "edit-Product-input" : ""} product-popup`} {...params} label={t("Product", tOpts)} />}
                                    renderOption={(params, option) => <li {...params} className={`${isMobile.mobile ? "" : ""} editorder-border`} >{option.DisplayValue}</li>} />
                            </Grid>
                            <Grid className="text-center" item xs={6} sm={6} md={6} lg={6} >
                                <FormControl variant="outlined" fullWidth={true} size="medium" className="h50px">
                                    <InputLabel id="demo-simple-select-label" className="maxH10px">Case Size</InputLabel>
                                    <Select
                                        className="edit-order-select product-reorder-select"
                                        label={t("Case Size", tOpts)}
                                        labelId="demo-simple-select-label"
                                        onChange={(e) => { onItemAction({ action: ITEM_ACTION.ON_CHANGE_CASE_TYPE, data: props.data, value: e.target.value }); }}
                                        value={getCaseTypeValue() || ''} >
                                        {
                                            caseTypeDdlData.hasOwnProperty(ProductId) && caseTypeDdlData[ProductId].map((qtyItem) => {
                                                return (<MenuItem className={`${isMobile.mobile ? "product-font-size" : ""} editorder-border `} key={qtyItem} name={qtyItem} value={qtyItem}>{t(qtyItem.CaseSize, tOpts)}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid className="text-center" item xs={6} sm={6} md={6} lg={6} >
                                <FormControl variant="outlined" fullWidth={true} size="medium" className="h50px">
                                    <InputLabel id="demo-simple-select-label" className="maxH10px" >QTY</InputLabel>
                                    <Select
                                        className="edit-order-select product-reorder-select"
                                        label={t("QTY", tOpts)}
                                        onChange={(e) => { onItemAction({ action: ITEM_ACTION.ON_CHANGE_QTY, data: props.data, value: e.target.value }) }}
                                        value={UnitsNeeded} >
                                        {qtyDdlOptions.map((qtyItem) => {
                                            return <MenuItem className={`${isMobile.mobile ? "product-font-size" : ""} editorder-border `} key={qtyItem} value={qtyItem}>{qtyItem}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="">
                        <Grid className="mt-2 pb-0" justifyContent="center" container spacing={1}>
                            <Grid item>
                                <Button onClick={addProduct} size="small" variant="contained" color="secondary" className="background-theme-blue sub-header-button">
                                    <PlusOneRoundedIcon /> {t('Add', tOpts)}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </DialogModal>
            <ListItem key={ProductName} className='outletCard-list-container pl-1 pr-1' alignItems="flex-start" dense={true}>
                <Grid container>
                    {!columnConfig.some(item => item['key'] === 'product') && roles.storeOwner.includes(Number(RoleId)) ? '' : <Grid container alignItems="center" xs={3} sm={6} md={7} lg={7}>
                        {(showForm || isNew) ? <> <Autocomplete size="small"
                            fullWidth
                            filterOptions={productFilter}
                            value={ddlUpdatedData.find((e) => e.LookupId === ProductId)}
                            onChange={onProductChange}
                            options={ddlUpdatedData}
                            disableClearable={true}
                            renderInput={(params) => <TextField className={`${isMobile.mobile ? "edit-Product-input" : ""}`} {...params} label={t("Product", tOpts)} />}
                            renderOption={(params, option) => <li {...params} className={`${isMobile.mobile ? "product-font-size" : ""} editorder-border `} >{option.DisplayValue}</li>} />
                            {!isMobile.mobile && <IconButton className="edit-order-clear-button" onClick={() => {
                                onClearProduct(props.data)
                                onItemAction({ action: ITEM_ACTION.DELETE, data: props.data })
                            }}>
                                <ClearIcon fontSize="small" />
                            </IconButton>}
                        </> :
                            <Typography variant="p" className={`${isMobile.mobile ? "product-font-size" : ""}`}>{ProductName}</Typography>}
                    </Grid>}
                    {!columnConfig.some(item => item['key'] === 'quantity') && roles.storeOwner.includes(Number(RoleId)) ? '' : <Grid className="text-center" item xs={2} sm={2} md={1} lg={1} >
                        <FormControl variant="outlined" fullWidth={true} size="small">
                            <Select
                                className="edit-order-select"
                                onChange={(e) => { onItemAction({ action: ITEM_ACTION.ON_CHANGE_QTY, data: props.data, value: e.target.value }) }}
                                value={UnitsNeeded} >
                                {qtyDdlOptions.map((qtyItem) => {
                                    return <MenuItem className={`${isMobile.mobile ? "product-font-size" : ""} editorder-border `} key={qtyItem} value={qtyItem}>{qtyItem}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>}
                    {!columnConfig.some(item => item['key'] === 'caseSize') && roles.storeOwner.includes(Number(RoleId)) ? '' : <Grid className="text-center" container alignItems="center" xs={2} sm={1} md={1} lg={1} >
                        <FormControl variant="outlined" fullWidth={true} size="small">
                            {(showForm || isNew) ? <Select
                                className="edit-order-select"
                                onChange={(e) => { onItemAction({ action: ITEM_ACTION.ON_CHANGE_CASE_TYPE, data: props.data, value: e.target.value }); }}
                                value={getCaseTypeValue()} >
                                {
                                    caseTypeDdlData.hasOwnProperty(ProductId) && caseTypeDdlData[ProductId].map((qtyItem) => {
                                        return (<MenuItem className={`${isMobile.mobile ? "product-font-size" : ""} editorder-border `} key={qtyItem} name={qtyItem} value={qtyItem}>{t(qtyItem.CaseSize, tOpts)}</MenuItem>)
                                    })
                                }
                            </Select> :
                                <Typography align="center" variant="button" className={`${isMobile.mobile || isMobile.tablet ? "product-font-size" : ""}`}>{CaseSize}</Typography>}
                        </FormControl>
                    </Grid>}
                    {!columnConfig.some(item => item['key'] === 'unit') && roles.storeOwner.includes(Number(RoleId)) ? '' : <Grid className="text-center" container justifyContent="center" alignItems="center" xs={2} sm={1} md={1} lg={1} >
                        <Typography align="center" variant="button" className={`${isMobile.mobile || isMobile.tablet ? "product-font-size" : ""}`}>{UnitLabel}</Typography>
                    </Grid>}
                    {!columnConfig.some(item => item['key'] === 'price') && roles.storeOwner.includes(Number(RoleId)) ? '' : <Grid className="text-center" container justifyContent="center" alignItems="center" xs={2} sm={1} md={1} lg={1} >
                        <Typography align="center" variant="text" className={`${isMobile.mobile || isMobile.tablet ? "product-font-size" : ""}`}>{tRow?.Currency?.replace('{0}', UnitPrice)}</Typography>
                    </Grid>}
                    <Grid className="text-center" container justifyContent="center" xs={1} sm={1} md={1} lg={1} >
                        <IconButton
                            className='ml-15'
                            onClick={async () => {
                                if (isOrderScheduled) {
                                    await checkOrderStatus(t, tOpts, dispatch, onRevertChanges);
                                }
                                onItemAction({ action: ITEM_ACTION.DELETE, data: props.data })
                            }} size="small">
                            <DeleteIcon className="button-red" />
                        </IconButton>
                    </Grid>
                </Grid>
            </ListItem>
        </>)
}

let scrollTimer = null;
const EditOrder = (props) => {
    const { isForOrderingTab = false } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const listRef = useRef();
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const { open, data = {}, isOrderingTab = false } = useSelector((state) => state.appReducer.replenishmentOrder);
    const { ProductInfo = [], LocationName = '' } = data || {};
    const outletProductList = utils.combineProducts(ProductInfo);
    const [cloneProductList, setCloneProductList] = useState(outletProductList);
    const gridData = useSelector(state => isOrderingTab ? state.appReducer.replenishmentGridOrderingData : state.appReducer.replenishmentGridData);
    const [productList, setProductList] = useState([]);
    const userData = useSelector(state => state.appReducer.userData);
    const { ClientId, ScheduledOrderId, RoleId } = userData && userData.tags ? userData.tags : 0;
    const isMobile = useMobile();
    const [userContext, setUserContext] = React.useContext(UserContext);
    const [columnConfig, setColumnConfig] = useState([]);
    const isOrderScheduled = Number(ScheduledOrderId) > 0;
    function compare(a, b) {
        if (a.ProductName < b.ProductName) {
            return -1;
        } else if (a.ProductName > b.ProductName) {
            return 1;
        } else {
            // If Product Name are equal, compare by Product Category Name
            if (a.ProductCategoryName < b.ProductCategoryName) {
                return -1;
            } else if (a.ProductCategoryName > b.ProductCategoryName) {
                return 1;
            } else {
                return 0;
            }
        }
    }


    const checkOrderStatus = async (t, tOpts, dispatch, onRevertChanges) => {
        const response = await request({ url: apis.ScheduledOrder, dispatch });
        if (response && response.error && response.error.status === 403) {
            setUserContext({ isOrderInTransit: true });
            swal({ title: t('This order cannot be edited now as it is out for delivery.', tOpts), icon: "info", buttons: { button: t('Ok', tOpts) } });
            typeof onRevertChanges === 'function' && onRevertChanges();
            return false;
        }
    }

    const setProductOriginalData = () => {
        const itemsData = utils.deepCloneObject(outletProductList);
        itemsData.sort(compare);
        itemsData.forEach(element => {
            element.customId = utils.generateUUID();
        });
        setCloneProductList(itemsData);
    }

    useEffect(() => {
        setProductOriginalData();
    }, [open]);

    useEffect(() => {
        if (open) {
            loadProduct();
        }
    }, [data]);

    const loadProduct = async () => {
        const userClientId = data.ClientId || ClientId;
        let params = { start: 0, comboType: 'Product', asArray: 0, limit: 0, ClientId: userClientId, OnlyClientProducts: true, LoadIsActiveProducts: true }
        const response = await request({ url: apis.Combo, params, history, dispatch });
        if (response && response.records) {
            setProductList(Object.keys(response.records).map((key) => response.records[key]));
        }
    }

    const onCancel = () => {
        dispatch({ type: actions.EDIT_REPLENISHMENT_ORDER, replenishmentOrder: { open: false, data: null } });
    }

    const onRevertChanges = async () => {
        if (isOrderScheduled) {
            await checkOrderStatus(t, tOpts, dispatch);
        }
        setProductOriginalData();
    }

    const checkProductAndCaseTypeSelected = ({ showDelete = true }) => {
        const response = { success: true, message: "" };
        const products = cloneProductList.filter(e => e.isNew);
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            if (product.ProductId === 0) {
                response.message = t(`Please select a product ${showDelete ? 'or delete' : ''}`, tOpts);
                response.success = false;
                break;
            }
            if (product.CaseSize === 0) {
                response.success = false;
                response.message = t("Please select one case size", tOpts);
                break;
            }
        }
        return response;
    }

    const updateOrderForStoreOwner = (e, orderStatus, gridData) => {
        const { records } = gridData.data, approveStatusId = ORDER_STATUS.ORDERAPPROVEDSTATUSID;
        const row = records[0];
        const record = [row.LocationId];
        const successCBK = (response) => {
            if (response.error && response.error.status === 403) {
                swal({ title: t('This order cannot be edited now as it is out for delivery.', tOpts), icon: "info", buttons: { button: t('Ok', tOpts) } })
            } else if (!response.success) {
                swal({ title: t(response.info, tOpts), icon: "info", buttons: { button: t('Ok', tOpts) } })
            } else {
                swal({
                    title: t('Thank you, your order has been saved.', tOpts), icon: "info", buttons: {
                        button: t('Ok', tOpts),
                    }
                })
            }
        };
        const title = 'Do you want to update the order';
        utils.onScheduledIgnoreClick({ e, isMulti: true, data: gridData.data, statusId: orderStatus, isReorder: false, dispatch, history, checkedData: record, t, successCBK, tOpts, updateData: true, title, approveStatusId, ScheduledOrderId, onRevertChanges });
    }

    const onConfirm = (e) => {
        const ddlSelection = checkProductAndCaseTypeSelected({});
        if (!ddlSelection.success) {
            swal({
                title: ddlSelection.message,
                icon: "warning",
                dangerMode: true,
                buttons: {
                    button: t('Ok', tOpts),
                }
            })
            return;
        }
        const gridDataClone = utils.deepCloneObject(gridData);
        const index = gridDataClone.data.records.findIndex(e => e.RowNumber === data.RowNumber);
        gridDataClone.data.records[index].ProductInfo = cloneProductList;

        let newCases = 0, newValue = 0;
        for (const product of cloneProductList) {
            newCases += Number(product.UnitsNeeded);
            newValue += Number((product.UnitPrice * product.UnitsNeeded));
            product.Amount = Number(product.UnitPrice) * Number(product.UnitsNeeded);
            product.isNew = false;
        }
        gridDataClone.data.records[index].FinalTotalCase = newCases;
        gridDataClone.data.records[index].FinalTotalOrder = Math.round(newValue);
        gridDataClone.data.records[index].TotalCase = newCases;
        gridDataClone.data.records[index].TotalOrder = Math.round(newValue);
        if (!isForOrderingTab) {
            dispatch({ type: actions.REPLENISHMENT_GRID_DATA, replenishmentGridData: { data: gridDataClone.data, editProductRowId: data.RowNumber } });
        }
        dispatch({ type: actions.REPLENISHMENT_GRID_ORDERING_DATA, replenishmentGridOrderingData: { data: gridDataClone.data, editProductRowId: data.RowNumber } });
        if (isOrderScheduled) {
            updateOrderForStoreOwner(e, ORDER_STATUS.SCHEDULED, gridDataClone);
        }
        onCancel();
    }

    const onAddNew = async () => {
        const emptyRowExists = cloneProductList.some(product => product.ProductId === 0 || product.UnitsNeeded === 0);

        if (emptyRowExists) {
            swal({
                title: t("Please fill in the existing empty row first", tOpts),
                icon: "warning",
                button: t('Ok', tOpts),
            });
        } else {
            clearTimeout(scrollTimer);
            const id = utils.generateUUID();
            setCloneProductList((prev) => [...prev, ...[{
                "Id": 0,
                "UnitsNeeded": 1,
                "UnitPrice": 0,
                "StockLevel": 0,
                "ProductId": 0,
                "CaseSize": 0,
                "ProductName": "",
                isNew: true,
                customId: id,
                "SKU": "",
                "UnitLabel": "",
                "UnitLabelId": 0
            }]]);
            scrollTimer = setTimeout(() => {
                listRef.current.scrollTop = listRef.current.scrollHeight * 2;
            }, 100);
        }
    }

    const onItemAction = ({ action, data, value }) => {
        const items = utils.deepCloneObject(cloneProductList) || [];
        const index = items.findIndex((e) => e["customId"] === data["customId"]);
        switch (action) {
            case ITEM_ACTION.DELETE:
                items.splice(index, 1);
                break;

            case ITEM_ACTION.ON_CHANGE_PRODUCT_NAME:
                items[index].ProductName = value?.DisplayValue || '';
                items[index].ProductId = value?.LookupId || '';
                items[index].CaseSize = 0;
                items[index].UnitPrice = 0;
                items[index].SKU = value?.SKU;
                items[index].ProductCategoryName = value?.ProductCategoryName
                break;

            case ITEM_ACTION.ON_CHANGE_QTY:
                items[index].UnitsNeeded = value;
                break;

            case ITEM_ACTION.ON_CHANGE_CASE_TYPE:
                const { CaseSize = 0, Price = 0, UnitLabel, UnitLabelId } = value || {};
                items[index].CaseSize = CaseSize;
                items[index].UnitPrice = Price;
                items[index].UnitLabel = UnitLabel;
                items[index].UnitLabelId = UnitLabelId;
                break;

            default:
                break;
        }
        setCloneProductList(items);
    }
    const getClientConfig = async () => {
        let response = {};
        if(roles.storeOwner.includes(Number(RoleId))){
            response = await request({ url: apis.ScheduledOrder, params: { action: 'otherAction', otherAction: 'GetOrderColumns' }, dispatch });
        }
        if(response.success){
            setColumnConfig(JSON.parse(response.data.Columns));
        }
    }
    useEffect(()=> {
        getClientConfig();
    },[]);

    return (
        <DialogModal
            dialogTitle={`${t("Edit Product", tOpts)} ${LocationName ? ('- ' + LocationName) : ''}`}
            maxWidth="md"
            className={`outletCard-modal ${isMobile.mobile && !isMobile.tablet ? "edit-order-mobile-overrides" : ""}`}
            open={open}
            onClose={onCancel}
            dividers={true}
            hideFooter={true}
            hideCloseIcon={isOrderScheduled}
            disableBackdropClick={isOrderScheduled}>
            <div className='w-100'>
                <div className="pt-2 pb-2">
                    <Grid container spacing={1}>
                        {roles.storeOwner.includes(Number(RoleId)) ? 
                        
                        columnConfig.map((col) => {                    
                            return (
                              <Grid
                                key={col.key}
                                item
                                xs={col.xs}
                                sm={col.sm}
                                md={col.md}
                                lg={col.lg}
                                className={col.align === 'center' ? 'text-center' : ''}
                              >
                                <Typography
                                  variant={
                                    isMobile.mobile || isMobile.tablet
                                      ? col.variantMobile
                                      : col.variantDesktop
                                  }
                                  gutterBottom={false}
                                >
                                  {t(col.label, tOpts)}
                                </Typography>
                              </Grid>
                            );
                          }) :
                    <>
                        <Grid item xs={3} sm={6} md={7} lg={7}>
                            <Typography variant={`${isMobile.mobile || isMobile.tablet ? "subtitle2" : "h6"}`} gutterBottom={false}>{t('Product', tOpts)}</Typography>
                        </Grid>
                        <Grid className="text-center" item xs={2} sm={2} md={1} lg={1} >
                            <Typography variant={`${isMobile.mobile || isMobile.tablet ? "subtitle2" : "h6"}`} gutterBottom={false}>{t('Qty', tOpts)}</Typography>
                        </Grid>
                        <Grid className="text-center" item xs={2} sm={1} md={1} lg={1} >
                            <Typography variant={`${isMobile.mobile || isMobile.tablet ? "subtitle2" : "h6"}`} gutterBottom={false}>{t('Case Size', tOpts)}</Typography>
                        </Grid>
                        <Grid className="text-center" item xs={2} sm={1} md={1} lg={1} >
                            <Typography variant={`${isMobile.mobile || isMobile.tablet ? "subtitle2" : "h6"}`} gutterBottom={false}>{t('Unit', tOpts)}</Typography>
                        </Grid>
                        <Grid className="text-center" item xs={2} sm={1} md={1} lg={1} >
                            <Typography variant={`${isMobile.mobile || isMobile.tablet ? "subtitle2" : "h6"}`} gutterBottom={false}>{t('Price', tOpts)}</Typography>
                        </Grid>
                        <Grid className="text-center" item xs={1} sm={1} md={1} lg={1} >
                            <Typography variant={`${isMobile.mobile || isMobile.tablet ? "subtitle2" : "h6"}`} gutterBottom={false}>{t('Delete', tOpts)}</Typography>
                        </Grid>
                    </>
                        }
                    </Grid>
                </div>
                <div>
                    <List className="hide-scrollbar" innerRef={listRef} style={{ minHeight: 200, maxHeight: 400, overflowY: "scroll" }} dense={true} disablePadding={true}  >
                        <ListItem className='outletCard-list-container' alignItems="flex-start" dense={true}>
                        </ListItem>
                        {cloneProductList.map((row, idx) => <ProductItem key={idx} ClientId={ClientId} RoleId={RoleId} data={row} tRow={data} ddlData={productList} onItemAction={onItemAction} cloneProductList={cloneProductList} checkProductAndCaseTypeSelected={checkProductAndCaseTypeSelected} onRevertChanges={onRevertChanges} checkOrderStatus={checkOrderStatus} isOrderScheduled={isOrderScheduled} columnConfig={columnConfig} />)}
                    </List>
                </div>
                <div className="bt-g-1">
                    <Grid className="mt-2 pb-0" justifyContent="center" container spacing={1}>
                        <Grid item>
                            <Button onClick={onAddNew} size="small" variant="contained" color="secondary" className="background-theme-blue sub-header-button Location-Reorder-blue">
                                <PlusOneRoundedIcon /> {t('Add', tOpts)}
                            </Button>
                        </Grid>
                        <Grid item >
                            <Button onClick={onRevertChanges} size="small" className="background-theme-brown sub-header-button Location-Reorder-yellow" variant="contained" color='primary'>{t('Revert Changes', tOpts)}</Button>
                        </Grid>
                        {!isOrderScheduled && <Grid item>
                            <Button onClick={onCancel} size="small" variant="contained" color="secondary" className="background-theme-red sub-header-button Location-Reorder-red" >{t('Cancel', tOpts)}</Button>
                        </Grid>}
                        <Grid item>
                            <Button onClick={(e) => onConfirm(e)} size="small" className="background-theme-green sub-header-button Location-Reorder-green" variant="contained" color='primary'>{t('Confirm', tOpts)}</Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </DialogModal>
    )
};
export default EditOrder;